import React, { Component } from 'react';
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import { Button, Card, Col, Form, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from '../Base/CustomSelect';
import CustomFunnel from '../Base/CustomFunnel';
import { Doughnut } from 'react-chartjs-2';
import { FormatAmountNumber } from '../Base/CommonUIComponents';
import { getAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import { getSalesProcessTypeOfRevenue } from '../Base/ReferenceDataFunctions';

class SalesProcessExecutiveReportYearly extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            block: true,
            hotelList: [],
            totalStageMetrics: [],
            pieData: null,
            funnelData: null,
            modal: false,
            selectedHotel: null,
            selectedRevenue: 'totalValue',
            preSelectedRevenue: 'totalValue',
        };
    }

    componentDidMount(){
        const { yearlyReport } = this.props;
        if(!yearlyReport){
            this.getYearlyReport();
        }
        else{
            this.inicialCalc(yearlyReport);
        }
    }

    getYearlyReport = () => {
        const { hotelList } = this.state;
        const { year } = this.props;

        let qs = `?year=${year}`;

        if(hotelList?.length)
            hotelList.forEach(h => {
                qs += `&hotelIds=${h}`;
            });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if(data.data?.length){
                    this.inicialCalc(data.data[0]);
                    return;
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/executivereport/yearly${qs}`);
    }

    inicialCalc = (yearlyReport) => {
        const { intl, colors } = this.props;
        const { selectedRevenue } = this.state;
        const totalStageMetrics = [];
        let pieData, funnelData = null;

        if(yearlyReport.hotels){
            const numbOfDeals = yearlyReport.hotels.reduce((a,b) => a + b.stagesTypes
                .reduce((a, b) => a + b.sourceTypes
                    .reduce((a, b) => a + (b?.deals?.numberOfDeals??0), 0), 0
                ), 0
            );
            
            yearlyReport.hotels.forEach((hotel, key) => {
                hotel.color = colors.at(key);
                
                if(hotel.stagesTypes){
                    const hotelNumbOfDeals = hotel.stagesTypes.reduce((a, b) => a + b.sourceTypes
                        .reduce((a, b) => a + (b?.deals?.numberOfDeals??0), 0), 0
                    );
                    
                    hotel.numberOfDeals = hotelNumbOfDeals;
                    hotel.dealShare = Math.round((hotelNumbOfDeals / numbOfDeals * 100) * 100) / 100;

                    hotel.stagesTypes.forEach(stage => {
                        let current = totalStageMetrics.find(({stageTypeId}) => stageTypeId === stage.stageTypeId);

                        if(!current){
                            totalStageMetrics.push({
                                stageTypeId: stage.stageTypeId,
                                stageName: stage.stageName
                            });
                            current = totalStageMetrics.find(({stageTypeId}) => stageTypeId === stage.stageTypeId);
                        }

                        stage.numberOfDeals = stage.sourceTypes.reduce((a, b) => a + (b?.deals?.numberOfDeals??0), 0);
                        stage.numberOfWonDeals = stage.sourceTypes.reduce((a, b) => a + (b?.deals?.numberOfWonDeals??0), 0);
                        stage.numberOfLostDeals = stage.sourceTypes.reduce((a, b) => a + (b?.deals?.numberOfLostDeals??0), 0);

                        stage.fb = stage.sourceTypes.reduce((a, b) => a + (b?.revenueMetrics?.fb??0), 0);
                        stage.misc = stage.sourceTypes.reduce((a, b) => a + (b?.revenueMetrics?.misc??0), 0);
                        stage.lodging = stage.sourceTypes.reduce((a, b) => a + (b?.revenueMetrics?.lodging??0), 0);
                        stage.spaceRental = stage.sourceTypes.reduce((a, b) => a + (b?.revenueMetrics?.spaceRental??0), 0);
                        stage.totalValue = stage.sourceTypes.reduce((a, b) => a + (b?.revenueMetrics?.totalValue??0), 0);
                        stage.numberOfLodging = stage.sourceTypes.reduce((a, b) => a + (b?.revenueMetrics?.numberOfLodging??0), 0);

                        const numberOfDeals = (current.deals?.numberOfDeals??0) + stage.numberOfDeals;
                        
                        stage.conversionRate = Math.round(((stage.numberOfDeals / hotelNumbOfDeals) * 100) * 100) / 100;
                        
                        current.deals = {
                            numberOfDeals,
                            numberOfWonDeals: (current.deals?.numberOfWonDeals??0) + stage.numberOfWonDeals??0,
                            numberOfLostDeals: (current.deals?.numberOfLostDeals??0) + stage.numberOfLostDeals??0,
                            conversionRate: Math.round(((numberOfDeals / numbOfDeals) * 100) * 100) / 100
                        };

                        current.revenueMetrics = {
                            fb: (current.revenueMetrics?.fb??0) + stage.fb??0,
                            misc: (current.revenueMetrics?.misc??0) + stage.misc??0,
                            lodging: (current.revenueMetrics?.lodging??0) + stage.lodging??0,
                            spaceRental: (current.revenueMetrics?.spaceRental??0) + stage.spaceRental??0,
                            totalValue: (current.revenueMetrics?.totalValue??0) + stage.totalValue??0,
                            numberOfLodging: (current.revenueMetrics?.numberOfLodging??0) + stage.numberOfLodging??0
                        };
                    });

                    hotel.fb = hotel.stagesTypes.reduce((a, b) => a + b.fb, 0);
                    hotel.misc = hotel.stagesTypes.reduce((a, b) => a + b.misc, 0);
                    hotel.lodging = hotel.stagesTypes.reduce((a, b) => a + b.lodging, 0);
                    hotel.spaceRental = hotel.stagesTypes.reduce((a, b) => a + b.spaceRental, 0);
                    hotel.totalValue = hotel.stagesTypes.reduce((a, b) => a + b.totalValue, 0);
                }
            });

            pieData = {
                datasets: [
                    {   
                        label: intl.formatMessage({ id: "SalesProcess.RevenueDistribution" }),
                        data: yearlyReport.hotels.map((x) => x[selectedRevenue]??'0'),
                        backgroundColor: yearlyReport.hotels.map(({color}) => color)
                    },
                ],
                labels: yearlyReport.hotels.map(({hotelId}) => global.hotelList?.find(({value}) => value === hotelId)?.label??'-')
            };

            funnelData = totalStageMetrics.map(stage => ({
                id: stage.stageTypeId,
                value: stage.revenueMetrics[selectedRevenue],
                label: stage.stageName
            })).sort((a, b) => b.value - a.value)
        }

        this.setState({
            block: false,
            totalStageMetrics,
            pieData,
            funnelData
        }, () => this.props.updateYearlyReport(yearlyReport));
        return;
    }

    handleHotelListChange = (combo) => {
        this.setState({ hotelList: combo ? combo?.map(cmb => cmb.value) : [] });
    }

    handleSearch = (e) => {
        e.preventDefault();
        this.setState({ block: true, selectedRevenue: this.state.preSelectedRevenue }, () => this.getYearlyReport());
    }

    downloadData = () => {
        
    }

    handleTypeOfRevenueChange = (combo) => {
        this.setState({ preSelectedRevenue: combo.value });
    }

    render() {
        const { error, block, totalStageMetrics, pieData, hotelList, funnelData, selectedRevenue, preSelectedRevenue } = this.state;
        const { icon, intl, hotelOptions, yearlyReport, yearOptions, toggleTab, handleChangeYear, year } = this.props;

        const optionsPie = {
            responsive: true,
            maintainAspectRatio: false,
            cutout: '60%',
            plugins: {
                datalabels: {
                    display: true,
                    color: 'white',
                    formatter: (ctx, data) => {
                        return ctx ? ctx : '';
                    },
                    formatter: (val, context) => {
                        return val ? intl.formatNumber(val, { maximumFractionDigits: 2, style: "currency", currency: global.hotelCurrency }) : ''
                    }
                },
                legend: {
                    display: false
                }
            }
        };
        
        return (
            <div className="border-0 h-100 pb-2">
                <ErrorAlert error={error}/>
                <BlockUi tag="div" blocking={block}>
                    <div className='mb-4'>
                        <Row>
                            <Col>
                                <h4 className='m-0'>
                                    <i className={`${icon} mr-2`} />
                                    <FormattedMessage id="SalesProcess.ExecutiveReport" />
                                </h4>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Form onSubmit={this.handleSearch}>
                            <Row>
                                <Col className='col-3'>
                                    <div className='title-sm'>
                                        <i className="icon-icon-hotel mr-2"/>
                                        <FormattedMessage id="SalesProcess.Hotels"/>
                                    </div>
                                    <div>
                                        <CustomSelect
                                            isSearchable={true}
                                            isMulti={true}
                                            name='hotelIds'
                                            placeholder={'Hotel'}
                                            value={hotelOptions?.flatMap(({options}) => options).filter(({value}) => hotelList.includes(value))}
                                            options={hotelOptions}
                                            onChange={this.handleHotelListChange}
                                        />
                                    </div>
                                </Col>
                                <Col className='col-3'>
                                    <div className='title-sm'>
                                        <i className="far fa-calendar-alt mr-2"/>
                                        <FormattedMessage id="SalesProcess.Year"/>
                                    </div>
                                    <div>
                                        <CustomSelect
                                            options={yearOptions}
                                            value={yearOptions.find(({value}) => value === year)}
                                            isSearchable={true}
                                            onChange={e => handleChangeYear(e, null)}
                                            placeholder={intl.formatMessage({ id: "SalesProcess.Year" })}
                                        />
                                    </div>
                                </Col>
                                <Col className='col-3'>
                                    <div className='title-sm'>
                                        <i class="fas fa-dollar-sign mr-2"></i>
                                        <FormattedMessage id="SalesProcess.TypeOfRevenue"/>
                                    </div>
                                    <div>
                                        <CustomSelect
                                            options={getSalesProcessTypeOfRevenue(intl)}
                                            value={getSalesProcessTypeOfRevenue(intl).find(({ value }) => value === preSelectedRevenue)}
                                            isSearchable={true}
                                            onChange={e => this.handleTypeOfRevenueChange(e)}
                                            placeholder={intl.formatMessage({ id: "SalesProcess.TypeOfRevenue" })}
                                        />
                                    </div>
                                </Col>
                                <Col className='col-3 text-right'>
                                    <Button className="btn btn-host btn-sm" onClick={this.downloadData}>
                                        <i className="fas fa-file-download"/>
                                    </Button>
                                    <Button className="btn btn-host btn-sm ml-2" type="submit">
                                        <i className='fas fa-search'/>
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                        {yearlyReport && totalStageMetrics?.length ?
                            <Row className='mt-4' style={{ fontSize: '0.9em' }}>
                                <Col className='col-12'>
                                    <Row>
                                        <Col className='col-3'>
                                            <Card className='shadow bg-white border-0 p-3 pointer' style={{ borderRadius: '5px' }} onClick={_ => toggleTab("SalesDealList", hotelList)}>
                                                <div className='text-center text-muted' style={{ fontSize: '1.2em' }}>
                                                    <i className={`fas fa-briefcase mr-2`}/>
                                                    <FormattedMessage id='SalesProcess.TotalDeals'/>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between mt-2' style={{ fontSize: '1.4em' }}>
                                                    <div>
                                                        {yearlyReport?.deals?.numberOfDeals??'0'}
                                                    </div>
                                                    <div>
                                                        <FormatAmountNumber
                                                            value={yearlyReport?.deals?.proposalValueTotal??'0'}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                        <Col className='col-3'>
                                            <Card className='shadow bg-white border-0 p-3 pointer' style={{ borderRadius: '5px', color: '#0665ff' }} onClick={_ => toggleTab("SalesDealList", hotelList, null, null, null, null, ["WaitingHotel", "WaitingClient"])}>
                                                <div className='text-center text-muted' style={{ fontSize: '1.2em' }}>
                                                    <i className={`fas fa-people-arrows mr-2`}/>
                                                    <FormattedMessage id='SalesProcess.NegotiationDeals'/>
                                                </div>
                                                {(() => {
                                                    const number = (yearlyReport?.deals?.numberOfDeals??0) - ((yearlyReport?.deals?.numberOfLostDeals??0) + (yearlyReport?.deals?.numberOfWonDeals??0));

                                                    const lost = yearlyReport?.deals?.proposalValueLost??0;
                                                    const won = yearlyReport?.deals?.proposalValueWon??0;
                                                    const total = yearlyReport?.deals?.proposalValueTotal??0;
                                                    const wonAndLost = won + lost;
                                                    const value = total - wonAndLost;

                                                    return (
                                                        <div className='d-flex align-items-center justify-content-between mt-2' style={{ fontSize: '1.4em' }}>
                                                            <div>
                                                                {number}
                                                            </div>
                                                            <div>
                                                                <FormatAmountNumber
                                                                    value={value}
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                })()}
                                            </Card>
                                        </Col>
                                        <Col className='col-3'>
                                            <Card className='shadow bg-white border-0 p-3 pointer' style={{ borderRadius: '5px', color: '#F38375' }} onClick={_ => toggleTab("SalesDealList", hotelList, null, null, null, null, "Cancelled")}>
                                                <div className='text-center text-muted' style={{ fontSize: '1.2em' }}>
                                                    <i className={`far fa-thumbs-down mr-2`}/>
                                                    <FormattedMessage id='SalesProcess.LostDeals'/>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between mt-2' style={{ fontSize: '1.4em' }}>
                                                    <div>
                                                        {yearlyReport?.deals?.numberOfLostDeals??'0'}
                                                    </div>
                                                    <div>
                                                        <FormatAmountNumber
                                                            value={yearlyReport?.deals?.proposalValueLost??'0'}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                        <Col className='col-3'>
                                            <Card className='shadow bg-white border-0 p-3 pointer' style={{ borderRadius: '5px', color: '#36ad51' }} onClick={_ => toggleTab("SalesDealList", hotelList, null, null, null, null, "Finished")}>
                                                <div className='text-center text-muted' style={{ fontSize: '1.2em' }}>
                                                    <i className={`fas fa-trophy mr-2`}/>
                                                    <FormattedMessage id='SalesProcess.WonDeals'/>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between mt-2' style={{ fontSize: '1.4em' }}>
                                                    <div>
                                                        {yearlyReport?.deals?.numberOfWonDeals??'0'}
                                                    </div>
                                                    <div>
                                                        <FormatAmountNumber
                                                            value={yearlyReport?.deals?.proposalValueWon??'0'}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className='col-12 mt-3'>
                                    <Card className='shadow bg-white border-0 p-3 h-100 overflow-auto scrollableDiv' style={{ borderRadius: '5px' }}>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='text-muted'>
                                                <i className="icon-icon-hotel mr-2"/>
                                                <FormattedMessage id="SalesProcess.GroupMetrics"/>
                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            <div className='d-flex align-items-center justify-content-between px-3 py-1' style={{ borderRadius: '4px', fontWeight: '600' }}>
                                                <div className='w-100' style={{ minWidth: '300px' }}>
                                                    <div className='text-truncate'>
                                                        <FormattedMessage id="SalesProcess.Hotel"/>
                                                    </div>
                                                </div>
                                                {totalStageMetrics.map((stage, key) =>
                                                    <div key={key} className='w-100 text-truncate' style={{  borderLeft: '1px solid #a5a5a5', minWidth: '250px' }}>
                                                        <div className='text-truncate text-center'>
                                                            {stage.stageName??'-'}
                                                        </div>
                                                        <div className="mt-1 d-flex align-items-center justify-content-between text-muted" style={{ fontWeight: '400' }}>
                                                            <div className='text-center w-100'>#</div>
                                                            <div className='text-center w-100'>€</div>
                                                            <div className='text-center w-100'>Nr</div>
                                                            <div className='text-center w-100'>%</div>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className='w-100 text-truncate' style={{  borderLeft: '1px solid #a5a5a5', minWidth: '250px' }}>
                                                    <div className='text-truncate text-center'>
                                                        <FormattedMessage id="SalesProcess.Budget"/>
                                                    </div>
                                                    <div className="mt-1 d-flex align-items-center justify-content-between text-muted" style={{ fontWeight: '400' }}>
                                                        <div className='text-center w-100'>#</div>
                                                        <div className='text-center w-100'>€</div>
                                                        <div className='text-center w-100'>Nr</div>
                                                        <div className='text-center w-100'>%</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                {yearlyReport.hotels?.map((hotel, key) =>
                                                    <div style={{ borderTop: key ? '1px solid lightgrey' : '' }} key={key} className='py-2 px-3 my-1 table-row-exec-report'>
                                                        <div className='w-100 text-truncate d-flex align-items-center justify-content-between' style={{ minWidth: '300px' }}>
                                                            <div className='text-truncate d-flex align-items-center'>
                                                                <div className='mr-2'>
                                                                    <div style={{
                                                                        backgroundColor: hotel.color??'#0665ff',
                                                                        aspectRatio: '1/1',
                                                                        height: '10px'
                                                                    }}/>
                                                                </div>
                                                                <div className='text-truncate cursor-pointer hrefStyle' onClick={_ => toggleTab("SalesProcessExecutiveReportMonthly", hotel.hotelId)}>
                                                                    {global.hotelList.find(({value}) => value === hotel.hotelId)?.label??'-'}
                                                                </div>
                                                            </div>
                                                            <div className='text-muted d-flex align-items-center mr-4'>
                                                                <div className='mr-4'>{hotel.numberOfDeals}</div>
                                                                <div>{`${hotel.dealShare}%`}</div>
                                                            </div>
                                                        </div>
                                                        {totalStageMetrics.map((s, key) => {
                                                            const stage = hotel.stagesTypes.find(({ stageTypeId }) => s.stageTypeId === stageTypeId);
                                                            return (
                                                                <div key={key} className='w-100 text-truncate' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '250px' }}>
                                                                    <div className='d-flex align-items-center justify-content-between'>
                                                                        <div className='text-center text-truncate w-100'>
                                                                            <span>
                                                                                {stage?.numberOfLodging??'0'}
                                                                            </span>
                                                                        </div>
                                                                        <div className='text-center text-truncate w-100'>
                                                                            <FormatAmountNumber value={stage ? stage[selectedRevenue] ? stage[selectedRevenue] : '0' : '0'} />
                                                                        </div>
                                                                        <div className='text-center text-truncate w-100'>
                                                                            <span className='cursor-pointer hrefStyle' onClick={_ => toggleTab("SalesDealList", hotel.hotelId, s.stageTypeId)}>
                                                                                {stage?.numberOfDeals??'0'}
                                                                            </span>
                                                                        </div>
                                                                        <div className='text-center text-truncate w-100'>
                                                                            {stage?.conversionRate??'0'}%
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        )}
                                                        <div className='w-100 text-truncate' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '250px' }}>
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <div className='text-center text-truncate w-100'>
                                                                    -
                                                                </div>
                                                                <div className='text-center text-truncate w-100'>
                                                                    -
                                                                </div>
                                                                <div className='text-center text-truncate w-100'>
                                                                    -
                                                                </div>
                                                                <div className='text-center text-truncate w-100'>
                                                                    -
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div>
                                                <div style={{ borderTop: '1px solid lightgrey' }} className='py-2 px-3 my-1 table-row-exec-report'>
                                                    <div className='w-100 text-truncate d-flex align-items-center justify-content-between' style={{ minWidth: '300px' }}>
                                                        <div className='text-truncate d-flex align-items-center'>
                                                            <FormattedMessage id="SalesProcess.Total"/>
                                                        </div>
                                                    </div>
                                                    {totalStageMetrics.map((stage, key) =>
                                                        <div key={key} className='w-100 text-truncate' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '250px' }}>
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <div className='text-center text-truncate w-100'>
                                                                    <span>
                                                                        {stage.revenueMetrics.numberOfLodging??0}
                                                                    </span>
                                                                </div>
                                                                <div className='text-center text-truncate w-100'>
                                                                    <FormatAmountNumber value={stage.revenueMetrics[selectedRevenue]??0}/>
                                                                </div>
                                                                <div className='text-center text-truncate w-100'>
                                                                    <div className='cursor-pointer hrefStyle' onClick={_ => toggleTab("SalesDealList", hotelList, stage.stageTypeId)}>
                                                                        {stage.deals.numberOfDeals??0}
                                                                    </div>
                                                                </div>
                                                                <div className='text-center text-truncate w-100'>
                                                                    <div>{stage.deals.conversionRate??0}%</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className='w-100 text-truncate' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '250px' }}>
                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <div className='text-center text-truncate w-100'>
                                                                -
                                                            </div>
                                                            <div className='text-center text-truncate w-100'>
                                                                -
                                                            </div>
                                                            <div className='text-center text-truncate w-100'>
                                                                -
                                                            </div>
                                                            <div className='text-center text-truncate w-100'>
                                                                -
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col className='col-3 mt-4'>
                                    <Card className='shadow bg-white border-0 p-3 h-100' style={{ borderRadius: '5px' }}>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='text-muted'>
                                                <i className="fas fa-chart-line mr-2"/>
                                                <FormattedMessage id="SalesProcess.RevenueDistribution"/>
                                            </div>
                                        </div>
                                        <div className='mt-4'>
                                            <Doughnut
                                                data={pieData}
                                                height={300}
                                                options={optionsPie}
                                            />
                                        </div>
                                    </Card>
                                </Col>
                                <Col className='col-9 mt-4'>
                                    <Card className='shadow bg-white border-0 p-3 h-100' style={{ borderRadius: '5px' }}>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='text-muted'>
                                                <i className="fas fa-chart-line mr-2"/>
                                                <FormattedMessage id="SalesProcess.ConversionperStage"/>
                                            </div>
                                        </div>
                                        <div>
                                            <CustomFunnel
                                                data={funnelData}
                                            />
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        :''}
                    </div>
                </BlockUi>
            </div>
        );
    }
}

export default injectIntl(SalesProcessExecutiveReportYearly);