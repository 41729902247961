import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Collapse, Input, Label, Row } from 'reactstrap';
import CustomSelect from '../../../Base/CustomSelect';

class FormContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            headerContentCollapse: true,
            questionsCollapse: true,
            marketingConsentUrl: '',
            headerContent: [
                { name: 'header', value: null },
                { name: 'title', value: null },
                { name: 'description', value: null },
                { name: 'completeText', value: null },
                { name: 'completedHtml', value: null },
            ],
            questions: [
                {
                    collapse: false,
                    title: this.props.intl.formatMessage({ id: 'FormBuilder.FirstName' }),
                    placeholder: '',
                    isRequired: false,
                    type: "text",
                    name: "FirstName",
                    autocomplete: "given-name",
                    requiredErrorText: "Response required"
                },
                {
                    collapse: false,
                    title: this.props.intl.formatMessage({ id: 'FormBuilder.LastName' }),
                    placeholder: '',
                    isRequired: false,
                    type: "text",
                    name: "LastName",
                    autocomplete: "family-name",
                    requiredErrorText: "Response required"
                },
                {
                    collapse: false,
                    title: this.props.intl.formatMessage({ id: 'FormBuilder.Email' }),
                    placeholder: '',
                    isRequired: true,
                    mandatory: true,
                    type: "text",
                    name: "Email",
                    inputType: "email",
                    autocomplete: "email",
                    requiredErrorText: "Response required"
                },
                {
                    collapse: false,
                    title: this.props.intl.formatMessage({ id: 'FormBuilder.VAT' }),
                    placeholder: '',
                    isRequired: false,
                    selected: false,
                    type: "text",
                    name: "VAT",
                    requiredErrorText: "Response required"
                },
                {
                    collapse: false,
                    title: this.props.intl.formatMessage({ id: 'FormBuilder.Mobile' }),
                    placeholder: '',
                    isRequired: false,
                    selected: false,
                    type: "text",
                    name: "Mobile",
                    inputType: "tel",
                    autocomplete: "tel",
                    requiredErrorText: "Response required"
                },
                {
                    collapse: false,
                    title: this.props.intl.formatMessage({ id: 'FormBuilder.Mailings' }),
                    isRequired: false,
                    type: "boolean",
                    typeOptions: [{ value: "boolean", label: "Boolean" }, { value: "checkbox", label: "Checkbox" }],
                    name: "Mailings",
                    selected: false,
                    requiredErrorText: "Response required",
                    marketingConsentUrl: ''
                },
                {
                    collapse: false,
                    title: this.props.intl.formatMessage({ id: 'FormBuilder.GenericConsent' }),
                    isRequired: false,
                    type: "boolean",
                    typeOptions: [{ value: "boolean", label: "Boolean" }, { value: "checkbox", label: "Checkbox" }],
                    name: "GenericConsent",
                    selected: false,
                    requiredErrorText: "Response required",
                    marketingConsentUrl: ''
                },
                {
                    collapse: false,
                    title: this.props.intl.formatMessage({ id: 'FormBuilder.Comment' }),
                    isRequired: false,
                    type: "comment",
                    name: "Comment",
                    placeholder: '',
                    selected: false,
                    requiredErrorText: "Response required",
                    validators: [{
                        type: "text",
                        "maxLength": 500
                    }]
                }
            ],
        }
    }

    componentDidMount() {
        const { formQuestions, survey } = this.props;
        const { questions, headerContent } = this.state;
        var marketingConsentUrl = '';

        if (formQuestions) {
            const supportedQuestions = questions.map(q => q.name);
            formQuestions.forEach(q => {
                if(supportedQuestions.includes(q.name)){
                    const current = questions.find(({name}) => name === q.name);
                    
                    current.selected = true;
                    current.isRequired = q.isRequired;
                    current.title = q.title;
                    current.placeholder = q.placeholder;
                    current.description = q.description;
                    current.labelTrue = q.labelTrue;
                    current.labelFalse = q.labelFalse;
                    current.requiredErrorText = q.requiredErrorText;
                    current.type = q.type;
                    current.choices = q.choices;

                    if (current && (current.name === 'Mailings' || current.name === 'GenericConsent')){
                        current.marketingConsentUrl = q.marketingConsentUrl;
                        marketingConsentUrl = q.marketingConsentUrl;
                    }
                }
                else{
                    q.name = q.name;
                    questions.push(q);
                }
            });
        }

        if(survey){
            if(survey.title)
                headerContent[0].value = survey.title;
            if(survey.surveyTitle)
                headerContent[1].value = survey.surveyTitle;
            if(survey.description)
                headerContent[2].value = survey.description;
            if(survey.completeText)
                headerContent[3].value = survey.completeText;
            if(survey.completedHtml)
                headerContent[4].value = survey.completedHtml;        
        }

        this.setState({ questions, headerContent, marketingConsentUrl });
    }

    toggleCollapse = (index) => {
        const { questions } = this.state;
        questions[index].collapse = !questions[index].collapse;
        this.setState({ questions });
    }

    toggleHeaderContentCollapse = () => {
        const { headerContentCollapse } = this.state;
        this.setState({ headerContentCollapse: !headerContentCollapse });
    }
    
    toggleQuestionsCollapse = () => {
        const { questionsCollapse } = this.state;
        this.setState({ questionsCollapse: !questionsCollapse });
    }

    toggleBtnCollapse = () => {
        const { completeBtnCollapse } = this.state;
        this.setState({ completeBtnCollapse: !completeBtnCollapse });
    }
    
    handleHeaderContentChange = (e) => {
        const { headerContent } = this.state;
        const { activeCountryTab } = this.props;
        const { name, value } = e.target;
        const index = headerContent.findIndex(c => c.name === name);

        if(headerContent[index].name === 'header'){
            headerContent[index].value = value;
            this.setState({ headerContent }, () => this.props.updateRawTitleDescComplete(name, value));
        }else{

            if(headerContent[index].value === undefined || headerContent[index].value === null || typeof headerContent[index].value === 'string'){
                headerContent[index].value = {};
            }

            if (activeCountryTab === 'default') {
                if (index === 2) {
                    headerContent[index].value[activeCountryTab] = value === '' || value === undefined || value === null ? ' ' : value;
                }
                else {
                    headerContent[index].value[activeCountryTab] = value;
                }
            } else {
                if (index === 2 && (headerContent[index].value['default'] === null || headerContent[index].value['default'] === '' || headerContent[index].value['default'] === undefined)) {
                    headerContent[index].value['default'] = ' '
                } 
                headerContent[index].value[activeCountryTab] = value;
            }

            this.setState({ headerContent }, () => this.props.updateRawTitleDescComplete(name, headerContent[index].value));
        }
    }

    handleCheck = (e, k) => {
        const { questions } = this.state;
        questions[k].selected = e.target.checked;
        this.setState({ questions }, () => this.props.handleQuestionChange(questions[k], k));
    }

    handleQuestionChange = (e, k, choiceIndex) => {
        const { questions } = this.state;
        const { activeCountryTab } = this.props;
        let { name, value } = e.target;
        var isValidUrl = false;

        const isConsentField = questions[k].name === 'Mailings' || questions[k].name === 'GenericConsent';


        if (name === 'type') {
            questions[k][name] = value;

            if (value === 'checkbox') {
                questions[k].choices = [
                    {
                        value: isConsentField ? questions[k].name : 'Item 1', //Campo usado para o css dos consentimentos
                        text: questions[k].title || {}
                    }];

                delete questions[k].labelFalse;
                delete questions[k].labelTrue;
                delete questions[k].title;
            }
            else {
                questions[k].title = questions[k].choices[0].text;

                delete questions[k].choices;
            }
        }
        else {
            if (this.state.marketingConsentUrl && typeof this.state.marketingConsentUrl === 'string') {
                isValidUrl = this.props.isValidUrl(this.state.marketingConsentUrl);
            } else if (this.state.marketingConsentUrl && this.state.marketingConsentUrl[activeCountryTab]) {
                isValidUrl = this.props.isValidUrl(this.state.marketingConsentUrl[activeCountryTab]);
            }
            
            if (questions[k][name] === undefined || questions[k][name] === null || typeof questions[k][name] === 'string') {
                questions[k][name] = {};
            }

            if (questions && questions[k] && isConsentField && (name === 'title' || name === 'description' || name === 'choices') && value) {
                if (this.state.marketingConsentUrl && typeof this.state.marketingConsentUrl !== 'string') {
                    if (this.state.marketingConsentUrl[activeCountryTab] && this.state.marketingConsentUrl[activeCountryTab] !== '' && isValidUrl) {
                        value += ` <a href="${this.state.marketingConsentUrl[activeCountryTab] ?? this.state.marketingConsentUrl['default']}" target="_blank" ${name === 'description' ? 'class="descriptionURL"' : ''}><i class="fas fa-info-circle infoIcon"></i></a>`;
                    }
                } else if (this.state.marketingConsentUrl) {
                    if (this.state.marketingConsentUrl !== '' && isValidUrl) {
                        value += ` <a href="${this.state.marketingConsentUrl}" target="_blank" ${name === 'description' ? 'class="descriptionURL"' : ''}><i class="fas fa-info-circle infoIcon"></i></a>`;
                    }
                }
            }

            if ((name === 'labelTrue' || name === 'labelFalse') && value === '') {
                delete questions[k][name][activeCountryTab];
            } else
                if (name === 'choices') {
                    if(questions[k].choices[choiceIndex].text === undefined || questions[k].choices[choiceIndex].text === null || typeof questions[k].choices[choiceIndex].text === 'string'){
                        questions[k].choices[choiceIndex].text = {};
                    }
                    questions[k].choices[choiceIndex].text[activeCountryTab] = value;
                }
                else {
                    questions[k][name][activeCountryTab] = value;
            }
        }

        this.setState({ questions }, () => this.props.handleQuestionChange(questions[k], k));
    }

    handleQuestionCheck = (e, k) => {
        const { questions } = this.state;
        questions[k].isRequired = e.target.checked;
        this.setState({ questions }, () => this.props.handleQuestionChange(questions[k], k));
    }

    getHeaderContent = (obj) => {
        const { activeCountryTab } = this.props;
        let value = null;
        if(obj.name === 'header'){
            value = obj.value;
        }else if(obj.value){
            value = obj.value[activeCountryTab];
        }

        return value ?? '';
    }

    getQuestionsContent = (obj, type) => {
        const { activeCountryTab } = this.props;
        const isConsent = obj.name === 'Mailings' || obj.name === 'GenericConsent';

        let value = null;

        if(!obj[type] || typeof obj[type] === 'string'){
            value = obj[type];
        }else if(obj[type]){
            value = obj[type][activeCountryTab];
        }

        if ((type === 'title' || type === 'description' || type === 'text') && obj && isConsent && value){
            const parts = value.split(' <a href=');
            if(parts && parts.length > 0){
                return parts[0];
            }
        }

        return value ?? '';
    }

    handleMarketingConsentUrlChange = (e, k) => {
        const { questions } = this.state;
        const { activeCountryTab } = this.props;        

        const isUrlValid = this.props.isValidUrl(e.target.value);
        const marketingUrl = document.getElementById(`marketingConsentUrlField${k}`);

        if(marketingUrl){
            marketingUrl.setCustomValidity("");
            if(!isUrlValid && e.target.value !== "") {
                marketingUrl.setCustomValidity(this.props.intl.formatMessage({ id: "FormBuilder.MarketingConsentError" }));
                marketingUrl.reportValidity();
            }
        }


        if(questions[k]['marketingConsentUrl'] === undefined || questions[k]['marketingConsentUrl'] === null || typeof questions[k]['marketingConsentUrl'] === 'string'){
            questions[k]['marketingConsentUrl'] = {};
        }

        questions[k]['marketingConsentUrl'][activeCountryTab] = e.target.value;


        if (questions[k].type === 'checkbox') {
            const choice = questions[k].choices[0];

            if (choice.text === undefined || choice.text === null || typeof choice.text === 'string') {
                choice.text = { [activeCountryTab]: choice.text ?? '' };
            }
            
            if (choice.text[activeCountryTab] || choice.text[activeCountryTab] === '') {
                if (!choice.text[activeCountryTab].includes('<a href=') && isUrlValid) {
                    choice.text[activeCountryTab] += ` <a href="${e.target.value}" target="_blank"><i class="fas fa-info-circle infoIcon"></i></a>`;
                }
                else {
                    const parts = choice.text[activeCountryTab].split(' <a href=');

                    if (parts && parts.length > 1) {
                        if (!(e.target.value) || e.target.value === '') {
                            choice.text[activeCountryTab] = parts[0];
                        } else {
                            if (isUrlValid) {
                                choice.text[activeCountryTab] = parts[0] + ` <a href="${e.target.value}" target="_blank"><i class="fas fa-info-circle infoIcon"></i></a>`;
                            } else {
                                choice.text[activeCountryTab] = parts[0];
                            }
                        }
                    } else {
                        if (isUrlValid) {
                            choice.text[activeCountryTab] = ` <a href="${e.target.value}" target="_blank"><i class="fas fa-info-circle infoIcon"></i></a>`;
                        }
                    }
                }
            }

            questions[k].choices[0] = choice;
        }
        else {
            const title = questions[k]['title'];
            const description = questions[k]['description'];

            if (questions[k]['title'] === undefined || questions[k]['title'] === null || typeof questions[k]['title'] === 'string') {
                questions[k]['title'] = {};
            }
            if (questions[k]['description'] === undefined || questions[k]['description'] === null || typeof questions[k]['description'] === 'string') {
                questions[k]['description'] = {};
            }

            if (!title || typeof title === 'string') {
                questions[k]['title'][activeCountryTab] = title ?? '';
            } else {
                questions[k]['title'][activeCountryTab] = title[activeCountryTab];
            }
            if (!description || typeof description === 'string') {
                questions[k]['description'][activeCountryTab] = description ?? '';
            } else {
                questions[k]['description'][activeCountryTab] = description[activeCountryTab];
            }

            if (questions[k]['title'][activeCountryTab] || questions[k]['title'][activeCountryTab] === '') {
                if (!questions[k]['title'][activeCountryTab].includes('<a href=') && isUrlValid) {
                    questions[k]['title'][activeCountryTab] += ` <a href="${e.target.value}" target="_blank"><i class="fas fa-info-circle infoIcon"></i></a>`;
                } else {
                    const parts = questions[k]['title'][activeCountryTab].split(' <a href=');
                    if (parts && parts.length > 1) {
                        if (!(e.target.value) || e.target.value === '') {
                            questions[k]['title'][activeCountryTab] = parts[0];
                        } else {
                            if (isUrlValid) {
                                questions[k]['title'][activeCountryTab] = parts[0] + ` <a href="${e.target.value}" target="_blank"><i class="fas fa-info-circle infoIcon"></i></a>`;
                            } else {
                                questions[k]['title'][activeCountryTab] = parts[0];
                            }
                        }
                    } else {
                        if (isUrlValid) {
                            questions[k]['title'][activeCountryTab] = ` <a href="${e.target.value}" target="_blank"><i class="fas fa-info-circle infoIcon"></i></a>`;
                        }
                    }
                }
            }

            if (questions[k]['description'][activeCountryTab] || questions[k]['description'][activeCountryTab] === '') {
                if (!questions[k]['description'][activeCountryTab].includes('<a href=') && isUrlValid) {
                    questions[k]['description'][activeCountryTab] += ` <a href="${e.target.value}" target="_blank" class="descriptionURL"><i class="fas fa-info-circle infoIcon"></i></a>`;
                } else {
                    const parts = questions[k]['description'][activeCountryTab].split(' <a href=');
                    if (parts && parts.length > 1) {
                        if (!(e.target.value) || e.target.value === '') {
                            questions[k]['description'][activeCountryTab] = parts[0];
                        } else {
                            if (isUrlValid) {
                                questions[k]['description'][activeCountryTab] = parts[0] + ` <a href="${e.target.value}" target="_blank" class="descriptionURL"><i class="fas fa-info-circle infoIcon"></i></a>`;
                            } else {
                                questions[k]['description'][activeCountryTab] = parts[0];
                            }
                        }
                    } else {
                        if (isUrlValid) {
                            questions[k]['description'][activeCountryTab] = ` <a href="${e.target.value}" target="_blank" class="descriptionURL"><i class="fas fa-info-circle infoIcon"></i></a>`;
                        }
                    }
                }
            }
        }

        this.setState({ questions, marketingConsentUrl: questions[k]['marketingConsentUrl'] }, () => this.props.checkInvalidUrl(questions[k]));
    }

    render() {
        const { headerContentCollapse, headerContent, questions, questionsCollapse } = this.state;
        const { activeCountryTab, comboLanguagesOptions, intl } = this.props;

        return (
            <div>
                <div className='pb-2'>
                    <Row className="pointer align-items-center">
                        <Col className='col-8' onClick={this.toggleHeaderContentCollapse}>
                            <i className={"fas mr-3" + (headerContentCollapse ? " fa-angle-down" : " fa-angle-right")} />
                            <b><FormattedMessage id="FormBuilder.HeaderContent"/></b>
                        </Col>
                        { activeCountryTab !== 'default' &&
                            <Col className='col-4 mt-1 '>
                                <div className='d-flex flex-row justify-content-between align-items-center'>
                                    <CustomSelect
                                        className="w-100"
                                        isSearchable={false}
                                        options={comboLanguagesOptions}
                                        onChange={(combo) => this.props.changeSelectLanguages(combo)}
                                        value={comboLanguagesOptions.find(l => l.value === activeCountryTab) ?? null}
                                        placeholder={intl.formatMessage({ id: "FormBuilder.SelectLanguage" })}
                                        required
                                    />
                                </div>
                            </Col>
                        }
                    </Row>
                    <Collapse isOpen={headerContentCollapse}>
                        
                        {headerContent.map((c, k) =>
                            <div key={k} className='mt-3'>
                                <Label className='title-sm'><FormattedMessage id={`FormBuilder.${c.name}`}/></Label>
                                <Input 
                                    type="text"
                                    value={this.getHeaderContent(c)}
                                    name={c.name}
                                    onChange={this.handleHeaderContentChange}
                                    placeholder={''}
                                    disabled={c.name === 'header' && activeCountryTab !== 'default'}
                                />
                            </div>
                        )}
                    </Collapse>
                </div>
                <hr/>
                <div className='py-2'>
                    <div className="pointer" onClick={this.toggleQuestionsCollapse}>
                        <i className={"fas mr-3" + (questionsCollapse ? " fa-angle-down" : " fa-angle-right")}/>
                        <b><FormattedMessage id="FormBuilder.Questions"/></b>
                    </div>
                    <Collapse isOpen={questionsCollapse}>
                        <div className='mt-4 shadow'>
                            {questions.map((q, k) => {
                                const isConsentField = q.name === 'Mailings' || questions[k].name === 'GenericConsent';

                                return <div key={k}>
                                    {k ? <hr className='m-0' /> : ''}
                                    <div className='py-2 px-2'>
                                        <div className={"px-2 py-2 pointer"} onClick={_ => this.toggleCollapse(k)}>
                                            <span>
                                                <i className={"fas mr-3" + (q.collapse ? " fa-angle-down" : " fa-angle-right")} />
                                                <FormattedMessage id={"FormBuilder." + q.name} />
                                            </span>
                                            {q.name !== 'Email' ?
                                                <Input
                                                    onClick={e => e.stopPropagation()}
                                                    type="checkbox"
                                                    checked={q.selected}
                                                    id={k + "-Field-" + q.name}
                                                    className='ml-3'
                                                    onChange={e => this.handleCheck(e, k)}
                                                    disabled={activeCountryTab !== 'default'}
                                                />
                                                : ''}
                                        </div>
                                        <Collapse isOpen={q.collapse}>
                                            <div className='px-4'>
                                                {q.typeOptions?.length > 0 ?
                                                    <div className='mb-3'>
                                                        <Label className='title-sm'><FormattedMessage id={`FormBuilder.Type`} /></Label>
                                                        <CustomSelect
                                                            className="w-100"
                                                            isSearchable={false}
                                                            options={q.typeOptions}
                                                            onChange={(combo) => this.handleQuestionChange({ target: { name: 'type', value: combo ? combo.value : null } }, k)}
                                                            value={q.typeOptions.find(l => l.value === q.type) ?? null}
                                                            placeholder=""
                                                            required
                                                        />
                                                    </div>
                                                    : ''}

                                                {q.type === 'checkbox' ?
                                                    <>
                                                        {q.choices.map((choice, key) =>
                                                            <div key={key}>
                                                                <Label className='title-sm'><FormattedMessage id={`FormBuilder.ChoiceLabel`} /></Label>
                                                                <Input
                                                                    className="test"
                                                                    type="text"
                                                                    value={this.getQuestionsContent({ ...choice, name: q.name }, 'text')}
                                                                    name={'choices'}
                                                                    onChange={e => this.handleQuestionChange(e, k, key)}
                                                                    placeholder={''}
                                                                />
                                                            </div>
                                                        )}
                                                    </>
                                                    :
                                                    <>
                                                        <div>
                                                            <Label className='title-sm'><FormattedMessage id={`FormBuilder.Label`} /></Label>
                                                            <Input
                                                                type="text"
                                                                value={this.getQuestionsContent(q, 'title')}
                                                                name={'title'}
                                                                onChange={e => this.handleQuestionChange(e, k)}
                                                                placeholder={''}
                                                            />
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label className='title-sm'><FormattedMessage id={`FormBuilder.Description`} /></Label>
                                                            <Input
                                                                type="text"
                                                                value={this.getQuestionsContent(q, 'description')}
                                                                name={'description'}
                                                                onChange={e => this.handleQuestionChange(e, k)}
                                                                placeholder={''}
                                                                maxLength="500"
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {q.type ?
                                                    q.type === "boolean" ?
                                                        <>
                                                            <div className='mt-3'>
                                                                <Label className='title-sm'><FormattedMessage id={`FormBuilder.labelTrue`} /></Label>
                                                                <Input
                                                                    type="text"
                                                                    value={this.getQuestionsContent(q, 'labelTrue')}
                                                                    name={'labelTrue'}
                                                                    onChange={e => this.handleQuestionChange(e, k)}
                                                                    placeholder={''}
                                                                />
                                                            </div>
                                                            <div className='mt-3'>
                                                                <Label className='title-sm'><FormattedMessage id={`FormBuilder.labelFalse`} /></Label>
                                                                <Input
                                                                    type="text"
                                                                    value={this.getQuestionsContent(q, 'labelFalse')}
                                                                    name={'labelFalse'}
                                                                    onChange={e => this.handleQuestionChange(e, k)}
                                                                    placeholder={''}
                                                                />
                                                            </div>
                                                        </>
                                                        : q.type !== "checkbox" &&
                                                        <div className='mt-3'>
                                                            <Label className='title-sm'><FormattedMessage id={`FormBuilder.Placeholder`} /></Label>
                                                            <Input
                                                                type="text"
                                                                value={this.getQuestionsContent(q, 'placeholder')}
                                                                name={'placeholder'}
                                                                onChange={e => this.handleQuestionChange(e, k)}
                                                                placeholder={''}
                                                            />
                                                        </div>
                                                    : ''}

                                                <div className='mt-3'>
                                                    <Label className='title-sm'><FormattedMessage id={`FormBuilder.requiredErrorText`} /></Label>
                                                    <Input
                                                        type="text"
                                                        value={this.getQuestionsContent(q, 'requiredErrorText')}
                                                        name={'requiredErrorText'}
                                                        onChange={e => this.handleQuestionChange(e, k)}
                                                        placeholder={''}
                                                    />
                                                </div>
                                                {isConsentField ?
                                                    <div className='mt-3'>
                                                        <Label className='title-sm'><FormattedMessage id={`FormBuilder.MarketingConsentLink`} /></Label>
                                                        <Input
                                                            type="text"
                                                            value={this.getQuestionsContent(q, 'marketingConsentUrl')}
                                                            name={'MarketingLink'}
                                                            onChange={e => this.handleMarketingConsentUrlChange(e, k)}
                                                            placeholder={''}
                                                            disabled={!this.getQuestionsContent(q, 'title') && !(q.choices && this.getQuestionsContent({ ...q.choices[0], name: q.name }, 'text'))}
                                                            id={`marketingConsentUrlField${k}`}
                                                        />
                                                    </div>
                                                    : ''}
                                                <div className='mt-3'>
                                                    <Label className='title-sm'><FormattedMessage id={`FormBuilder.Required`} /></Label>
                                                    <Input
                                                        type="checkbox"
                                                        checked={q.isRequired}
                                                        className='ml-3'
                                                        onChange={e => this.handleQuestionCheck(e, k)}
                                                        disabled={q.mandatory || activeCountryTab !== 'default'}
                                                    />
                                                </div>
                                            </div>
                                        </Collapse>
                                    </div>
                                </div>
                            }
                            )}
                        </div>
                    </Collapse>
                </div>
            </div>
        )
    }
}

export default injectIntl(FormContent);