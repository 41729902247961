import React, { Component } from 'react';
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import { Col, Collapse, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from '../Base/CustomSelect';
import { CoolTooltip, FormatAmountNumber, SelectHotel } from '../Base/CommonUIComponents';
import moment from 'moment-timezone';
import { handleNotification } from '../Base/Notification';
import { getAPI } from '../Base/API';
import { getHolidays } from '../Marketing/CommunicationJourney/CommonFunctions';
import { SelectCustomType } from './SalesProcessFunctions';

class SalesProcessResources extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            block: true,
            hotels: this.props.selectedHotel ? [this.props.selectedHotel] : [],
            statusList: [],
            currentDate: moment().set('year', (this.props.year ?? moment().format('YYYY'))).set('month', (this.props.selectedMonth ? (parseInt(this.props.selectedMonth) - 1) : (parseInt(moment().format('MM')) - 1))),
            months: [
                { month: 1 },
                { month: 2 },
                { month: 3 },
                { month: 4 },
                { month: 5 },
                { month: 6 },
                { month: 7 },
                { month: 8 },
                { month: 9 },
                { month: 10 },
                { month: 11 },
                { month: 12 }
            ],
            weekDays: moment.weekdaysShort(),
            totalCalendarRows: 0,
            holidaysOfMonth: [],
            calendarRows: [],
            hotelList: [],
            viewMode: this.props.isFromMonthly ? this.props.isSpaces ? "spaces" : "rooms" : "hotels",
            viewModeOptions: [
                { mode: "hotels", icon: "icon-icon-hotel"},
                { mode: "deals", icon: "fas fa-briefcase"},
                { mode: "rooms", icon: "fas fa-bed"},
                // { mode: "events", icon: "fas fa-theater-masks"},
                { mode: "spaces", icon: "fas fa-store-alt"},
            ],
            colors: [
                '#6610f2', '#fd7e14', '#17a2b8', '#e83e8c', '#dc3545', '#ffc107', '#28a745', '#20c997', '#6c757d', '#343a40',
                '#17a2b8', '#6f42c1', '#dce2c8', '#f96900', '#a8dcd1', '#65def1', '#4F5D2F', '#423629', '#7D7E75', '#B0B2B8',
                '#CFD6EA', '#99621E', '#D38B5D', '#739E82', '#2C5530', '#8C1C13', '#BF4342', '#BF4342', '#A78A7F', '#735751',
                '#EAD2AC', '#EABA6B', '#221D23', '#4F3824', '#D1603D', '#DDB967', '#D0E37F', '#B48B7D', '#094D92', '#875053'
            ],
            dealColor: [],
            openedDealDetail: [],
            yearOptions: [
                {label:'2023', value:'2023'},
                {label:'2024', value:'2024'},
                {label:'2025', value:'2025'},
                {label:'2026', value:'2026'},
                {label:'2027', value:'2027'},
                {label:'2028', value:'2028'},
                {label:'2029', value:'2029'}
            ],
            year: this.props.year ?? moment().format('YYYY'),
            selectedStages: [],
            monthlyOcc: [
                { rooms: 60, spaces: 30 }
            ],
            openedHotelDetail: []
        };
    }

    componentDidMount() {
        this.getResources();
    }

    getResources = () => {
        const { selectedStageTypeId } = this.props;
        const { hotels, statusList, currentDate, year, selectedStages } = this.state;
        
        const startDate = currentDate.clone().startOf('month').set('year', (year??moment().format('YYYY')));
        const endDate = currentDate.clone().endOf('month').set('year', (year??moment().format('YYYY')));

        let q = `?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`;

        if(hotels)
            hotels.forEach(h => 
                q += `&hotelIds=${h}&`
            );
            
        if(selectedStages)
            selectedStages.forEach(s => 
                q += `&stageTypeList=${s}&`
            );
            
        if(statusList?.length)
            statusList.forEach(s => 
                q += `&statusList=${s}`
            );
        if(selectedStageTypeId)
            q += `&stageTypeId=${selectedStageTypeId}&`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({
                    block: false,
                    hotelList: data.data
                }, () => this.renderCalendar());

                return;
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/resourcecalendar${q}`);
    }

    //#region Calendar

    renderCalendar = () => {
        let { currentDate, hotelList, colors, dealColor } = this.state;

        const months = this.addHolidays();

        const holidaysOfMonth = months ? months.find(m => m.month == currentDate.format('M')).holidays : [];

        let blank = this.getBlankCells();
        let daysInMonth = this.getDaysInMonth();
        let totalSlots = [...blank, ...daysInMonth];
        let cells = [], calendarRows = [];

        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                cells.push(row); // if index not equal 7 that means not go to next week
            } else {
                calendarRows.push(cells); // when reach next week we contain all td in last week to rows 
                cells = []; // empty container 
                cells.push(row); // in current loop we still push current row to new container
            }
            if (i === totalSlots.length - 1) { // when end loop we add remain date
                while (cells.length < 7) {
                    cells.push({ day: "" });
                }
                calendarRows.push(cells);
            }
        });
        
        const totalCalendarRows = (calendarRows && calendarRows.filter(cr => cr.length > 0).length) ?? 0;
        let dealList = [];
        
        hotelList.forEach(hotel => {
            if(hotel.salesDeals){

                hotel.numberOfRooms = 1;
                hotel.numberOfSpaces = 1;

                hotel.salesDeals.forEach(deal => {
                    deal.startDate = moment.min(deal.proposals.map(({ startDate }) => moment(startDate)));
                    deal.endDate = moment.max(deal.proposals.map(({ endDate }) => moment(endDate)));

                    const days = calendarRows.flatMap(cells => cells).filter(d => d.date && (d.date.isSameOrAfter(deal.startDate, 'day') && d.date.isSameOrBefore(deal.endDate, 'day')));
                    
                    if (days && days.length > 0) {
                        days.forEach(day => {
                            day.deals.push({
                                startDate: deal.startDate,
                                endDate: deal.endDate,
                                isStartDate: deal.startDate.isSame(day.date, 'day'),
                                isEndDate: deal.endDate.isSame(day.date, 'day'),
                                dealName: deal.dealName,
                                expectedValue: deal.expectedValue,
                                status: deal.status,
                                dealId: deal.dealId,
                                hotelId: hotel.hotelId,
                                numberOfRooms: deal.proposals?.reduce((a, b) => a + b.numberOfRooms, 0)??0,
                                numberOfSpaces: deal.proposals?.reduce((a, b) => a + b.numberOfSpaces, 0)??0,
                            });
                        });
                    }

                    if(deal.proposals){
                        deal.proposals.forEach(proposal => {
                            if(proposal.proposalJSON){
                                proposal.proposalJSON = typeof proposal.proposalJSON === "string" ? JSON.parse(proposal.proposalJSON) : proposal.proposalJSON;
                                const groupReservation = proposal.proposalJSON.data[0];
                                const externalId = groupReservation.GroupReservationNumber;
            
                                if (groupReservation.DetailsRooms && groupReservation.DetailsRooms.length > 0) {
                                    groupReservation.DetailsRooms.forEach(room => {
                                        const start = moment(room.Checkin);
                                        const end = moment(room.Checkout);
            
                                        const days = calendarRows.flatMap(cells => cells).filter(d => d.date && (d.date.isSameOrAfter(start, 'day') && d.date.isSameOrBefore(end, 'day')));
            
                                        if (days && days.length > 0) {
                                            days.forEach(day => {
                                                day.rooms.push({
                                                    startDate: start,
                                                    endDate: end,
                                                    isStartDate: start.isSame(day.date, 'day'),
                                                    isEndDate: end.isSame(day.date, 'day'),
                                                    categoryId: room.CategoryId,
                                                    category: room.CategoryCode,
                                                    priceList: room.PricelistCode,
                                                    package: room.PackageCode,
                                                    numberOfRooms: room.Rooms,
                                                    priceTotal: room.PriceTotal,
                                                    externalId: externalId,
                                                    dealId: deal.dealId,
                                                    hotelId: hotel.hotelId
                                                });
                                            });
                                        }
                                    });
                                }
                                
                                if (groupReservation.DetailsEvents && groupReservation.DetailsEvents.length > 0) {
                                    groupReservation.DetailsEvents.forEach(event => {
                                        const eventStart = moment.min(event.Spaces.map(({ FromDate }) => moment(FromDate)));
                                        const eventEnd = moment.max(event.Spaces.map(({ ToDate }) => moment(ToDate)));
            
                                        const days = calendarRows.flatMap(cells => cells).filter(d => d.date && (d.date.isSameOrAfter(eventStart, 'day') && d.date.isSameOrBefore(eventEnd, 'day')));
            
                                        if (days && days.length > 0) {
                                            days.forEach(day => {
                                                const daySpaces = event.Spaces.filter(({ FromDate, ToDate }) =>
                                                    day.date.isSameOrAfter(moment(FromDate), 'day') &&
                                                    day.date.isSameOrBefore(moment(ToDate), 'day')
                                                ).map((space) => ({
                                                    name: space.SpaceName,
                                                    desc: space.SpaceDescription,
                                                    type: space.SpaceTypeDescription,
                                                    startDate: moment(space.FromDate),
                                                    numberOfSpaces: 1,
                                                    endDate: moment(space.ToDate)
                                                }));
            
                                                day.events.push({
                                                    startDate: eventStart,
                                                    endDate: eventEnd,
                                                    isStartDate: eventStart.isSame(day.date, 'day'),
                                                    isEndDate: eventEnd.isSame(day.date, 'day'),
                                                    name: event.EventName,
                                                    type: event.EventType,
                                                    manager: event.EventManager,
                                                    priceTotal: event.PriceTotal,
                                                    spaces: daySpaces,
                                                    externalId: externalId,
                                                    dealId: deal.dealId,
                                                    hotelId: hotel.hotelId
                                                });
                                            });
            
                                            if(event.Spaces && event.Spaces.length > 0){
                                                event.Spaces.forEach(space => {
                                                    const spaceStart = moment(space.FromDate);
                                                    const spaceEnd = moment(space.ToDate);
                
                                                    const days = calendarRows.flatMap(cells => cells).filter(d => d.date && (d.date.isSameOrAfter(spaceStart, 'day') && d.date.isSameOrBefore(spaceEnd, 'day')));
        
                                                    if (days && days.length > 0) {
                                                        days.forEach(day => {
                                                            day.spaces.push({
                                                                numberOfSpaces: 1,
                                                                name: space.SpaceName,
                                                                desc: space.SpaceDescription,
                                                                type: space.SpaceTypeDescription,
                                                                startDate: moment(space.FromDate),
                                                                endDate: moment(space.ToDate),
                                                                externalId: externalId,
                                                                dealId: deal.dealId,
                                                                hotelId: hotel.hotelId
                                                            });
                                                        })
                                                    }
                                                });
                                            }
                                        }
                                    });
                                }
                            }
                        });
                    }
                });

                const days = calendarRows.flatMap(cells => cells).filter(d => d.deals?.find(({hotelId}) => hotelId === hotel.hotelId));

                hotel.startDate = moment.min(days.map(({ date }) => date));
                hotel.endDate = moment.max(days.map(({ date }) => date));
    
                if (days && days.length > 0) {
                    days.forEach(day => {
                        const dealsInDay = day.deals.filter(({hotelId}) => hotelId === hotel.hotelId);
                        day.hotels.push({
                            startDate: hotel.startDate,
                            endDate: hotel.endDate,
                            isStartDate: hotel.startDate.isSame(day.date, 'day'),
                            isEndDate: hotel.endDate.isSame(day.date, 'day'),
                            hotelId: hotel.hotelId,
                            hotelName: global.hotelList?.find(({value}) => value === hotel.hotelId)?.label??'',
                            numberOfDeals: dealsInDay.length,
                            numberOfRooms: dealsInDay.reduce((a, b) => a + b.numberOfRooms, 0),
                            numberOfSpaces: dealsInDay.reduce((a, b) => a + b.numberOfSpaces, 0)
                        });
                    });
                }
                dealList = dealList.concat(hotel.salesDeals);
            }
        });

        calendarRows.flatMap(cells => cells).forEach(d=> {
            if(d.rooms && d.rooms.length){
                const newRooms = [];
                d.rooms.forEach(r => {
                    const idx = newRooms.findIndex(nr => nr.categoryId === r.categoryId && nr.package === r.package && nr.priceList === r.priceList && nr.dealId == r.dealId);
                    if(idx >= 0){
                        const c = newRooms[idx]
                        newRooms[idx] = {
                            ...c,
                            numberOfRooms: c.numberOfRooms + r.numberOfRooms,
                            priceTotal: c.priceTotal + r.priceTotal,
                            startDate: r.startDate.isBefore(c.startDate, 'day'),
                            endDate: r.startDate.isAfter(c.endDate, 'day'),
                        }
                    }
                    else{
                        newRooms.push(r);
                    }
                });
                d.rooms = newRooms;
            }
            if(d.spaces && d.spaces.length){
                const newSpaces = [];
                d.spaces.forEach(r => {
                    const idx = newSpaces.findIndex(nr => nr.name === r.name && nr.dealId === r.dealId);
                    if(idx >= 0){
                        const c = newSpaces[idx]
                        newSpaces[idx] = {
                            ...c,
                            numberOfSpaces: c.numberOfSpaces + r.numberOfSpaces,
                            startDate: r.startDate.isBefore(c.startDate, 'day'),
                            endDate: r.startDate.isAfter(c.endDate, 'day'),
                        }
                    }
                    else{
                        newSpaces.push(r);
                    }
                });
                d.spaces = newSpaces;
            }
        });

        const used = [];
        dealList.forEach(({dealId}) => {
            if(!dealColor.some(c => c.dealId === dealId)){
                let idx = Math.floor(Math.random() * colors.length + 1);
                let color = colors[idx];
    
                while(used.includes(color)){
                    idx = Math.floor(Math.random() * colors.length + 1);
                    color = colors[idx];
                }
    
                dealColor.push({
                    dealId,
                    color
                });
    
                used.push(color);
            }
        });
        hotelList.forEach(({hotelId}) => {
            if(!dealColor.some(c => c.dealId === hotelId)){
                let idx = Math.floor(Math.random() * colors.length + 1);
                let color = colors[idx];
    
                while(used.includes(color)){
                    idx = Math.floor(Math.random() * colors.length + 1);
                    color = colors[idx];
                }
    
                dealColor.push({
                    dealId: hotelId,
                    color
                });
    
                used.push(color);
            }
        });
        

        this.setState({
            months,
            calendarRows,
            totalCalendarRows,
            holidaysOfMonth,
            hotelList,
            dealColor
        });
    }

    addHolidays = () => {
        let { months, currentDate } = this.state;
        const holidays = global.hotelCountryCode ? getHolidays(currentDate.format("YYYY")).find(el => el.country.toLowerCase() === global.hotelCountryCode.toLowerCase()) : getHolidays(currentDate.format("YYYY"))[0];

        if (holidays) {
            holidays.months && holidays.months.forEach(dates => {
                const index = months.findIndex(m => m.month === dates.month);

                if (index > -1) {
                    months[index].holidays = dates.celebrations;
                }
            });
        }

        return months;
    }

    getFirstDayOfMonth = () => {
        let dateObject = this.state.currentDate;
        let firstDay = moment(dateObject)
            .startOf("month")
            .format("d");
        return firstDay;
    }

    getDaysInMonth = () => {
        let daysInMonth = [];

        for (let d = 1; d <= moment(this.state.currentDate).daysInMonth(); d++) {
            daysInMonth.push({
                day: d,
                date: moment(this.state.currentDate).startOf('month').add((d - 1), 'day'),
                hotels: [],
                deals: [],
                rooms: [],
                events: [],
                spaces: [],
            });
        }

        return daysInMonth;
    }

    getBlankCells = () => {
        let blanks = [];

        for (let i = 0; i < this.getFirstDayOfMonth(); i++) {
            blanks.push({ day: "" });
        }

        return blanks;
    }

    //#endregion

    handleYear = (e) => {
        const { currentDate } = this.state;
        const year = (e?.value??0);
        const newC = moment(currentDate).clone().set('year', year);

        this.setState({ currentDate: newC, year, block: true }, () => this.getResources());
    }

    handleHotels = (n, combo) => {
        this.setState({ hotels: combo ? combo?.map(cmb => cmb.value) : [], block: true }, () => this.getResources());
    }

    changeMonth = (trans) => {
        const date = this.state.currentDate.add(trans, 'month');

        this.setState({
            currentDate: date,
            block: true
        }, () => this.getResources())
    }

    toggleOpenedDealDetail = (key, hotelKey) => {
        const { openedDealDetail } = this.state;

        if(!openedDealDetail[hotelKey])
            openedDealDetail[hotelKey] = [];

        openedDealDetail[hotelKey][key] = !openedDealDetail[hotelKey][key];
        this.setState({ openedDealDetail })
    }

    toggleOpenedHotelDetail = (key) => {
        const { openedHotelDetail } = this.state;

        openedHotelDetail[key] = !openedHotelDetail[key];
        this.setState({ openedHotelDetail });
    }

    handleStatus = (status) => {
        let { statusList } = this.state;
        if(statusList.includes(status)){
            statusList = statusList.filter(s => s !== status);
        }
        else{
            statusList.push(status);
        }
        this.setState({ statusList, block: true }, () => this.getResources());
    }

    handleStage = (combo) => {
        this.setState({ selectedStages: combo ? combo?.map(cmb => cmb.value) : [], block: true }, () => this.getResources());
    }

    render() {
        const { error, block, hotels, statusList, currentDate, weekDays, totalCalendarRows, holidaysOfMonth, calendarRows, hotelList, viewMode, yearOptions,
            viewModeOptions, dealColor, openedDealDetail, year, selectedStages, monthlyOcc, openedHotelDetail } = this.state;
        const { icon, intl, isChild, toggleTab, isFromMonthly, selectedHotel } = this.props;
        
        return (
            <div className="border-0 h-100 pb-2">
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <div className='mb-4 d-flex align-items-center justify-content-state'>
                        {isChild ?
                            <div onClick={_ => toggleTab(isFromMonthly ? "SalesProcessExecutiveReportMonthly" : "SalesProcessExecutiveReportYearly", selectedHotel)} className='p-3 cursor-pointer mr-2'>
                                <i className='fas fa-chevron-left'/>
                            </div>
                        :''}
                        <h4 className='m-0'>
                            <i className={`${icon} mr-2`} />
                            <FormattedMessage id="SalesProcess.Resources" />
                        </h4>
                    </div>
                    <div>
                        <Row className='mt-4'>
                            <Col className='col-9'>
                                <div>
                                    <Row>
                                        <Col className='col-4 d-flex align-items-center'>
                                            <div className='d-flex align-items-center justify-content-start' style={{ fontSize: '0.7em' }}>
                                                {viewModeOptions.map((v, key) =>
                                                    <div key={key}onClick={() => this.setState({ viewMode: v.mode })} className={`deal-action-badge-${v.mode === "deals" ? 'OnGoing' : v.mode} ${viewMode === v.mode ? 'selected-deal-action-badge' : ''} mr-2 p-2`}>
                                                        <i className={`mr-1 ${v.icon}`}/>
                                                        <FormattedMessage id={`SalesProcess.${v.mode}`}/>
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                        <Col className='col-4 d-flex align-items-center justify-content-center'>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <div>
                                                    <div className='cursor-pointer' onClick={_ => this.changeMonth(-1)}>
                                                        <b><i className="fas fa-chevron-left"/></b>
                                                    </div>
                                                </div>
                                                <div className='mx-4'>
                                                    <b style={{ fontSize: '1.1em' }}>
                                                        {currentDate.format('MMMM')}
                                                    </b>
                                                </div>
                                                <div>
                                                    <div className='cursor-pointer' onClick={_ => this.changeMonth(1)}>
                                                        <b><i className="fas fa-chevron-right"/></b>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className='col-4 d-flex align-items-center justify-content-end'>
                                            <div style={{ minWidth: '100px', width: '20%' }}>
                                                <CustomSelect
                                                    options={yearOptions}
                                                    value={yearOptions.find(({value}) => value === year)}
                                                    isSearchable={true}
                                                    onChange={this.handleYear}
                                                    placeholder={intl.formatMessage({ id: "SalesProcess.Year" })}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className='mt-4'>
                                        <Row className="mb-3 pl-4 align-items-center px-3">
                                            {weekDays && weekDays.map(day =>
                                                <Col key={day} className="week-day py-0 px-1 text-center">
                                                    {day}
                                                </Col>
                                            )}
                                        </Row>
                                        <div className='px-3'>
                                            {calendarRows && calendarRows.map((week, i) => week.length > 0 ?
                                                <Row key={i} style={{ height: `${(100 / totalCalendarRows - 1)}%`, minHeight: '120px', overflow: 'hidden' }}>

                                                    {week.map((day, key) => {
                                                        const holiday = holidaysOfMonth && holidaysOfMonth.find(hom => hom.day === day.day);

                                                        const isCurrentDay = day.date && currentDate.isSame(day.date, 'date') ? " bg-host-selected circle-md" : '';
                                                        const isHoliday = isCurrentDay === '' && holiday ? " bg-dark-blue circle-md" : '';
                                                        const calendarDay = isCurrentDay === '' && isHoliday === '' ? ' py-1 px-2' : '';

                                                        const bg = (id) => dealColor.find(({dealId}) => dealId === id)?.color??'#0665ff';
                                                        
                                                        return (
                                                            <Col key={key} className={`calendar-border p-3 overflow-hidden` + (key === 0 || key === 6 ? ' bg-weekend' : '')}>
                                                                <div className="d-flex align-items-center">
                                                                    <div className={` ${isCurrentDay} ${isHoliday} ${calendarDay}`}>
                                                                        <b> {day.day} </b>
                                                                    </div>
                                                                    <b className={"ml-2 overflow-hidden text-nowrap text-truncate w-100 text-dark-blue"} title={holiday?.label}>
                                                                        {holiday ?
                                                                            holiday.label
                                                                        :''}
                                                                    </b>
                                                                </div>
                                                                <div className='veryCoolScrollBar pt-1' style={{ fontSize: '0.9em', maxHeight: '100px', overflow: 'auto' }}>
                                                                    {viewMode === "hotels" && day.hotels && day.hotels.length > 0 ?
                                                                        day.hotels.map((hotel, hkey) => {
                                                                            const roomsInv = monthlyOcc[0].rooms;
                                                                            const spacesInv = monthlyOcc[0].spaces;

                                                                            const roomsOcc = Math.round(((hotel.numberOfRooms / roomsInv) * 100) * 100) / 100;
                                                                            const spacesOcc = Math.round(((hotel.numberOfSpaces / spacesInv) * 100) * 100) / 100;

                                                                            const roomsColor = roomsOcc > 66 ? '#d9534f' : roomsOcc > 33 ? '#f0ad4e' : '#5cb85c';
                                                                            const spacesColor = spacesOcc > 66 ? '#d9534f' : spacesOcc > 33 ? '#f0ad4e' : '#5cb85c';

                                                                            return (
                                                                                <React.Fragment key={hkey}>
                                                                                    <div style={{ border: `2px solid ${bg(hotel.hotelId)}`, color: 'unset' }} id={`hotels-${i}-${key}-${hkey}`} className='sales-deal-calendar-item text-truncate mb-1'>
                                                                                        <div className='d-flex align-items-center w-100' id={`hotels-rooms-${i}-${key}-${hkey}`}>
                                                                                            <div className='mr-2'>
                                                                                                <i className="fas fa-bed"/>
                                                                                            </div>
                                                                                            <div>
                                                                                                <span>
                                                                                                    {hotel.numberOfRooms}
                                                                                                </span>
                                                                                                <span>/</span>
                                                                                                <b style={{ color: roomsColor }}>{roomsInv}</b>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='d-flex align-items-center justify-content-end w-100' id={`hotels-spaces-${i}-${key}-${hkey}`}>
                                                                                            <div className='mr-2'>
                                                                                                <span>{hotel.numberOfSpaces}</span>
                                                                                                <span>/</span>
                                                                                                <b style={{ color: spacesColor }}>{spacesInv}</b>
                                                                                            </div>
                                                                                            <div>
                                                                                                <i className="fas fa-store-alt"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <>
                                                                                        <CoolTooltip placement="right" target={`hotels-rooms-${i}-${key}-${hkey}`}>
                                                                                            <div>{roomsOcc}%</div>
                                                                                        </CoolTooltip>
                                                                                        <CoolTooltip placement="left" target={`hotels-spaces-${i}-${key}-${hkey}`}>
                                                                                            <div>{spacesOcc}%</div>
                                                                                        </CoolTooltip>
                                                                                    </>
                                                                                </React.Fragment>
                                                                            )}
                                                                        )
                                                                    :''}
                                                                    {viewMode === "deals" && day.deals && day.deals.length > 0 ?
                                                                        day.deals.map((deal, dkey) =>
                                                                            <div style={{ backgroundColor: bg(deal.dealId) }} key={dkey} id={`deals-${i}-${key}-${dkey}`} className='sales-deal-calendar-item text-truncate mb-1'>
                                                                                <div className='d-flex'>
                                                                                    <div className='mr-2'>
                                                                                        <i className="fas fa-bed"/>
                                                                                    </div>
                                                                                    <div>
                                                                                        {deal.numberOfRooms}
                                                                                    </div>
                                                                                </div>
                                                                                <div className='d-flex'>
                                                                                    <div className='mr-2'>
                                                                                        {deal.numberOfSpaces}
                                                                                    </div>
                                                                                    <div>
                                                                                        <i className="fas fa-store-alt"/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    :''}
                                                                    {viewMode === "rooms" && day.rooms && day.rooms.length > 0 ?
                                                                        day.rooms.map((room, rkey) =>
                                                                            <div style={{ backgroundColor: bg(room.dealId) }} key={rkey} id={`rooms-${i}-${key}-${rkey}`} className='sales-deal-calendar-item text-truncate mb-1'>
                                                                                <div className='text-truncate'>{room.category}</div>
                                                                                <div>
                                                                                    {room.numberOfRooms}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    :''}
                                                                    {viewMode === "events" && day.events && day.events.length > 0 ?
                                                                        day.events.map((event, ekey) =>
                                                                            <div style={{ backgroundColor: bg(event.dealId) }} key={ekey} id={`rooms-${i}-${key}-${ekey}`} className='sales-deal-calendar-item text-truncate mb-1'>
                                                                                <div className='text-truncate'>{event.name}</div>
                                                                            </div>
                                                                        )
                                                                    :''}
                                                                    {viewMode === "spaces" && day.spaces && day.spaces.length > 0 ?
                                                                        day.spaces.map((space, skey) =>
                                                                            <div style={{ backgroundColor: bg(space.dealId) }} key={skey} id={`spaces-${i}-${key}-${skey}`} className='sales-deal-calendar-item text-truncate mb-1'>
                                                                                <div className='text-truncate'>{space.name}</div>
                                                                                <div>
                                                                                    {space.numberOfSpaces}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    :''}
                                                                </div>
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            :'')}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col className='col-3'>
                                <div style={{ top: '0', position: 'sticky' }}>
                                    <div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div>
                                                <h5 className='m-0 text-truncate'>
                                                    <FormattedMessage id="SalesProcess.Deals"/>
                                                </h5>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-end" style={{ fontSize: '0.7em' }}>
                                                <div onClick={_ => this.handleStatus("WaitingHotel")} className={`deal-action-badge-OnGoing ${statusList.includes("WaitingHotel") ? 'selected-deal-action-badge' : ''} ml-2 p-2`}>
                                                    <i className="icon-icon-hotel mr-1"/>
                                                    <FormattedMessage id={`SalesProcess.Hotel`}/>
                                                </div>
                                                <div onClick={_ => this.handleStatus("WaitingClient")} className={`deal-action-badge-Scheduled ${statusList.includes("WaitingClient") ? 'selected-deal-action-badge' : ''} ml-2 p-2`}>
                                                    <i className="far fa-building mr-1"/>
                                                    <FormattedMessage id={`SalesProcess.Client`}/>
                                                </div>
                                                <div onClick={_ => this.handleStatus("Cancelled")} className={`deal-action-badge-Cancelled ${statusList.includes("Cancelled") ? 'selected-deal-action-badge' : ''} ml-2 p-2`}>
                                                    <i className="far fa-thumbs-down mr-1"/>
                                                    <FormattedMessage id={`SalesProcess.Lost`}/>
                                                </div>
                                                <div onClick={_ => this.handleStatus("Finished")} className={`deal-action-badge-Finished ${statusList.includes("Finished") ? 'selected-deal-action-badge' : ''} ml-2 p-2`}>
                                                    <i className="fas fa-trophy mr-1"/>
                                                    <FormattedMessage id={`SalesProcess.Won`}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 mt-3'>
                                            <SelectHotel
                                                isSearchable
                                                name='hotels'
                                                isMulti={true}
                                                onChangeFunc={this.handleHotels}
                                                placeholder={'Hotel'}
                                                value={hotels || ''}
                                                params='?onlyFromHotelGroup=true'
                                            />
                                        </div>
                                        <div className='w-100 mt-3'>
                                            <SelectCustomType
                                                isMulti={true}
                                                onChangeFunc={this.handleStage}
                                                value={selectedStages}
                                                placeholder={intl.formatMessage({ id: "SalesProcess.StageType" })}
                                                isDisabled={false}
                                                isClearable={true}
                                                type={'PipelineStageType'}
                                            />
                                        </div>
                                    </div>
                                    <div className='shadow w-100 bg-white mt-4 p-3'>
                                        {hotelList?.length ?
                                            hotelList.map((hotel, hotelKey) =>
                                                <div key={hotelKey} className={hotelKey ? `mt-3 pt-3` : ''} style={{ borderTop: hotelKey ? '1px solid #bfbfbf' : '' }}>
                                                    <div style={{ fontSize: '1.1em' }} className='text-muted d-flex align-items-center justify-content-between'>
                                                        <div className='text-truncate d-flex align-items-center'>
                                                            <div style={{
                                                                backgroundColor: dealColor.find(({dealId}) => dealId === hotel.hotelId)?.color??'#0665ff',
                                                                aspectRatio: '1/1',
                                                                height: '10px'
                                                            }}/>
                                                            <div className='ml-1 text-truncate'>
                                                                {global.hotelList?.find(({value}) => value === hotel.hotelId)?.label??''}
                                                            </div>
                                                        </div>
                                                        <div className='d-flex aling-items-center justify-content-end'>
                                                            <b>
                                                                <FormatAmountNumber value={hotel.expectedValue}/>
                                                            </b>
                                                            <div className='cursor-pointer ml-2' onClick={_ => this.toggleOpenedHotelDetail(hotelKey)}>
                                                                {openedHotelDetail[hotelKey] ?
                                                                    <i className="fas fa-chevron-up"/>
                                                                :
                                                                    <i className="fas fa-chevron-down"/>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Collapse isOpen={openedHotelDetail[hotelKey]}>
                                                        <div>
                                                            {hotel.salesDeals.map((deal, key) =>
                                                                <div key={key} className={`mt-2 pt-2`} style={{ borderTop: key ? '1px solid lightgrey' : '' }}>
                                                                    <Row>
                                                                        <Col className='col-8'>
                                                                            <div className='text-truncate d-flex align-items-center' style={{ fontSize: '0.9em' }}>
                                                                                <div style={{
                                                                                    backgroundColor: dealColor.find(({dealId}) => dealId === deal.dealId)?.color??'#0665ff',
                                                                                    aspectRatio: '1/1',
                                                                                    height: '10px'
                                                                                }}/>
                                                                                <div className='ml-1 text-truncate' style={{ marginTop: '1px' }}>
                                                                                    {deal.dealName}
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col className='col-4 d-flex align-items-center justify-content-between'>
                                                                            <div className='text-truncate' style={{ fontSize: '0.9em' }}>
                                                                                <FormattedMessage id={`SalesProcess.${deal.status}`}/>
                                                                            </div>
                                                                            <div className='cursor-pointer' onClick={_ => this.toggleOpenedDealDetail(key, hotelKey)}>
                                                                                {openedDealDetail[hotelKey] && openedDealDetail[hotelKey][key] ?
                                                                                    <i className="fas fa-chevron-up"/>
                                                                                :
                                                                                    <i className="fas fa-chevron-down"/>
                                                                                }
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Collapse isOpen={openedDealDetail[hotelKey] && openedDealDetail[hotelKey][key]}>
                                                                        <div className='text-muted px-2' style={{ fontSize: '0.9em' }}>
                                                                            <div className='d-flex align-items-center justify-content-between mt-2'>
                                                                                <div>
                                                                                    <FormattedMessage id="SalesProcess.Rooms"/>
                                                                                </div>
                                                                                <div>
                                                                                    <b>{deal.proposals?.reduce((a, b) => a + b.numberOfRooms, 0)??0}</b>
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex align-items-center justify-content-between mt-2'>
                                                                                <div>
                                                                                    <FormattedMessage id="SalesProcess.Spaces"/>
                                                                                </div>
                                                                                <div>
                                                                                    <b>{deal.proposals?.reduce((a, b) => a + b.numberOfSpaces, 0)??0}</b>
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex align-items-center justify-content-between mt-2'>
                                                                                <div>
                                                                                    <FormattedMessage id="SalesProcess.Equipment"/>
                                                                                </div>
                                                                                <div>
                                                                                    <b>{deal.proposals?.reduce((a, b) => a + b.numberOfEquipment, 0)??0}</b>
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex align-items-center justify-content-between mt-2'>
                                                                                <div>
                                                                                    <FormattedMessage id="SalesProcess.Pax"/>
                                                                                </div>
                                                                                <div>
                                                                                    <b>{deal.proposals?.reduce((a, b) => a + (b.adults + b.children), 0)??0}</b>
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex align-items-center justify-content-between mt-2'>
                                                                                <div>
                                                                                    <FormattedMessage id="SalesProcess.ExpectedValue"/>
                                                                                </div>
                                                                                <div>
                                                                                    <b>
                                                                                        <FormatAmountNumber value={deal.expectedValue}/>
                                                                                    </b>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Collapse>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            )
                                        :
                                            <div className='text-center text-muted' style={{ fontSize: '0.9em' }}>
                                                <FormattedMessage id="SalesProcess.NoDeals"/>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </BlockUi>
            </div>
        );
    }
}

export default injectIntl(SalesProcessResources);