import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Label, FormGroup, Input, CustomInput } from 'reactstrap';
import { getAPI } from "../../Base/API";
import CustomSelect from "../../Base/CustomSelect";
import BlockUi from 'react-block-ui';
import { FormsWithTitleSelect, FrequencyText, ReviewProFormsSelect } from '../Common/MarketingFunctions';
import { getFrequency, getLicenseModules } from '../../Base/ReferenceDataFunctions';
import { RenderTemplateSelection } from '../Common/CommunicationFunctions';

class CampaignStep2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            surveyProviderOptions: []
        };
    }

    componentDidMount() {
        const { campaign, hasProfileNowModule, hasReviewProModule, hasTypeFormModule, intl } = this.props;
        const { surveyProviderOptions } = this.state;
        
        if (hasReviewProModule) {
            surveyProviderOptions.push(getLicenseModules(intl)[3].options[0]);
        }
        if (hasProfileNowModule) {
            surveyProviderOptions.push(getLicenseModules(intl)[7].options[0]);
        }
        if (hasTypeFormModule) {
            surveyProviderOptions.push(getLicenseModules(intl)[7].options[1]);
        }

        this.handleFillColor();

        this.setState({ surveyProviderOptions });
    }

    
    handleTemplates = (templateId, countries, data, tab) => {
        const { campaign } = this.props;
        campaign.templates = campaign.templates ? campaign.templates : [];
        const idx = campaign.templates.findIndex(el => el === data);

        if (idx !== -1) {
            if (templateId) {
                campaign.templates[idx].templateId = templateId;
            }
            if (countries !== undefined) {
                campaign.templates[idx].countries = countries;
            }
        }

        const e = { target: { name: 'templates', value: campaign.templates } };
        this.props.handleChange(e);
    }

    addTab = () => {
        const { campaign } = this.props;
        campaign.templates = campaign.templates ? campaign.templates : [];
        campaign.templates.push({ isDefault: false });

        const e = { target: { name: 'templates', value: campaign.templates } };
        this.props.handleChange(e);
    }

    removeTab = (data) => {
        const { campaign } = this.props;

        campaign.templates = campaign.templates.filter(el => el !== data);

        const e = { target: { name: 'templates', value: campaign.templates } };
        this.props.handleChange(e);
    }

    handleDefaultTemplate = (templateId) => {
        const { campaign } = this.props;
        campaign.templates = campaign.templates ? campaign.templates : [];

        const index = campaign.templates.findIndex(el => el.isDefault === true);

        if (index !== -1) {
            campaign.templates[index].templateId = templateId;
        }
        else {
            campaign.templates.push({ templateId: templateId, isDefault: true, countries: [] });
        }

        const e = { target: { name: 'templates', value: campaign.templates } };
        this.props.handleChange(e);
    }

    handleSlideOne = (e) => {
        const { filters, handleChangeWithCallback } = this.props;

        if (e && e.target) {
            const { value } = e.target;

            if (parseInt(value) < parseInt(filters.frequencyAdjustFinal)) {
                handleChangeWithCallback(e, this.fillColor);
            }
        }
    }

    handleSlideTwo = (e) => {
        const { campaign, handleChangeOnInputFilterWithCallback } = this.props;

        if (e && e.target) {
            const { value } = e.target;

            if (parseInt(value) > parseInt(campaign.frequencyAdjust)) {
                handleChangeOnInputFilterWithCallback(e, this.fillColor);
            }
        }
    }

    handleFillColor = () => {
        const { campaign } = this.props;
        if (campaign.frequency === getFrequency()[6].value) {
            this.fillColor();
        }
    }

    fillColor = () => {
        const { campaign, filters } = this.props;
        const sliderTrack = document.querySelector(".slider-track");

        const percent1 = campaign.frequencyAdjust < 0 ? ((parseInt(campaign.frequencyAdjust) + 7) / 14) * 100 : (((parseInt(campaign.frequencyAdjust) + 7) / 14) * 100);
        const percent2 = filters.frequencyAdjustFinal < 0 ? ((parseInt(filters.frequencyAdjustFinal) + 7) / 14) * 100 : (((parseInt(filters.frequencyAdjustFinal) + 7) / 14) * 100);
        
        sliderTrack.style.background = `linear-gradient(to right, #dadae5 ${percent1}% , #3264fe ${percent1}% , #3264fe ${percent2}%, #dadae5 ${percent2}%)`;
    }

    render() {
        const { frequency, filters, campaign, handleCustomInput, handleSelect, handleSurveySelect, handleChange, handleSelectCampaignFrequency } = this.props;
        const { surveyProviderOptions } = this.state;

        const showEventCombo = campaign.marketingType !== 'Birthday' && campaign.marketingType !== 'CreationDay';
        const showReviewProForm = campaign.marketingType === 'CampaignInquiry' || campaign.marketingType === 'CreationDay';

        return (
            <BlockUi tag="div" blocking={this.state.block}>

                {campaign.marketingType !== 'LinkedCampaign' ?
                    <>
                        <Row className="mb-2">
                            <Col>
                                <h5><FormattedMessage id="Campaigns.Frequency" /></h5>
                            </Col>
                        </Row>
                        <Row>
                            {
                                showEventCombo ?
                                    <Col>
                                        <FormGroup row>
                                            <Label sm={4}> <FormattedMessage id="Campaigns.Event" /></Label>
                                            <Col sm={8}>
                                                <CustomSelect
                                                    placeholder=""
                                                    options={frequency}
                                                    value={campaign.frequency ? frequency.find(f => f.value === campaign.frequency) : ''}
                                                    onChange={handleSelectCampaignFrequency.bind(this, 'frequency', this.handleFillColor)}
                                                    isClearable
                                                    isSearchable
                                                    required
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col sm={4}/>
                                            <Col sm={8} style={{ zIndex: '0' }}>
                                                <CustomInput type="checkbox" id="excludeProfilesIfHaveReservationsAfterEvent" name="excludeProfilesIfHaveReservationsAfterEvent" checked={filters.excludeProfilesIfHaveReservationsAfterEvent} onChange={handleCustomInput}>
                                                    <FormattedMessage id="CampaignDetails.ExcludeProfilesIfHaveReservationsAfterEvent" />
                                                </CustomInput>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col sm={4}/>
                                            <Col sm={8} style={{ zIndex: '0' }}>
                                                <CustomInput type="checkbox" id="considerMainGuestOnly" name="considerMainGuestOnly" checked={filters.considerMainGuestOnly} onChange={handleCustomInput}>
                                                    <FormattedMessage id="CampaignDetails.ConsiderMainGuestOnly" />
                                                </CustomInput>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    :
                                    ''
                            }

                            <Col className="col-6">
                                <FormGroup row>
                                    <Label sm={4}> <FormattedMessage id="Campaigns.FrequencyAdjust" /></Label>
                                    <Col sm={8}>
                                        {
                                            campaign.frequency === getFrequency()[6].value ?
                                                <div>
                                                    <div>
                                                        <div className="slider-track" />
                                                        <input className="slider-track-input" type="range" min="-7" max="7" value={campaign.frequencyAdjust} id="frequencyAdjust" name="frequencyAdjust" onChange={this.handleSlideOne} />
                                                        <input className="slider-track-input" type="range" min="-7" max="7" value={filters.frequencyAdjustFinal} id="frequencyAdjustFinal" name="frequencyAdjustFinal" onChange={this.handleSlideTwo} />
                                                    </div>
                                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '3rem', position: 'absolute', paddingRight: '3rem', textAlign: 'center' }}>
                                                        <b>
                                                            {
                                                                `${this.props.intl.formatMessage({ id: "Campaigns.GuestsAtHotelBetween" })} ${Math.abs(campaign.frequencyAdjust)} 
                                                                ${campaign.frequencyAdjust < 0 && filters.frequencyAdjustFinal < 0 ?
                                                                    `${this.props.intl.formatMessage({ id: "Campaigns.to" })} ${Math.abs(filters.frequencyAdjustFinal)} ${this.props.intl.formatMessage({ id: "Campaigns.beforeOnly" })} ${this.props.intl.formatMessage({ id: "Campaigns.Days" })} ${this.props.intl.formatMessage({ id: "Campaigns.SendDate" })}`
                                                                    :
                                                                    `${campaign.frequencyAdjust < 0 && filters.frequencyAdjustFinal > 0 ?
                                                                        `${this.props.intl.formatMessage({ id: "Campaigns.Days" })} ${this.props.intl.formatMessage({ id: "Campaigns.beforeOnly" })} ${this.props.intl.formatMessage({ id: "Campaigns.and" })} ${Math.abs(filters.frequencyAdjustFinal)} ${this.props.intl.formatMessage({ id: "Campaigns.Days" })} ${this.props.intl.formatMessage({ id: "Campaigns.afterOnly" })} ${this.props.intl.formatMessage({ id: "Campaigns.SendDate" })}`
                                                                        :
                                                                        `${this.props.intl.formatMessage({ id: "Campaigns.to" })} ${Math.abs(filters.frequencyAdjustFinal)} ${this.props.intl.formatMessage({ id: "Campaigns.Days" })} ${this.props.intl.formatMessage({ id: "Campaigns.afterOnly" })} ${this.props.intl.formatMessage({ id: "Campaigns.SendDate" })}`
                                                                    }`
                                                                }`
                                                            }
                                                        </b>
                                                    </div>
                                                </div>
                                                :
                                                <div>
                                                    <CustomInput
                                                        id="frequencyAdjust"
                                                        type='range'
                                                        name="frequencyAdjust"
                                                        onChange={(e) => handleChange(e)}
                                                        value={campaign.frequencyAdjust}
                                                        required
                                                        min={campaign.frequency !== "ReservationDate" && campaign.frequency !== "CancelationDate" && campaign.marketingType !== "CreationDay" ? '-365' : '0'}
                                                        max={'365'}
                                                    />

                                                    <div style={{ width: '100%', display: 'flex', justifyContent: (campaign.frequency === "ReservationDate" || campaign.frequency === "CancelationDate" || campaign.marketingType === "CreationDay" ? 'start' : 'center'), paddingLeft: '1rem', fontSize: '0.6em', marginTop: '-10px' }}>|</div>

                                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', paddingLeft: '1rem' }}>
                                                        <b>
                                                            <FrequencyText
                                                                marketingType={campaign.marketingType}
                                                                frequency={campaign.frequency}
                                                                frequencyAdjust={campaign.frequencyAdjust}
                                                                frequencyAdjustFinal={filters.frequencyAdjustFinal}
                                                                pastSimple={false}
                                                                intl={this.props.intl}
                                                            />
                                                        </b>
                                                    </div>
                                                </div>
                                        }
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </>
                :
                    <>
                        <Row className="mb-2">
                            <Col>
                                <h5><FormattedMessage id="Campaigns.ScheduleSending" /></h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={5}>
                                <FormGroup row>
                                    <Col>
                                        <CustomInput
                                            id="frequencyAdjust"
                                            type='range'
                                            name="frequencyAdjust"
                                            onChange={(e) => handleChange(e)}
                                            value={campaign.frequencyAdjust}
                                            required
                                            min={'1'}
                                            max={'365'}
                                        />
                                    </Col>
                                </FormGroup>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingLeft: '1rem', fontSize: '0.6em', marginTop: '-10px' }}>|</div>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', paddingLeft: '1rem' }}>
                                    <b>  <FormattedMessage id="Campaigns.SendAfterXDays" values={{ frequencyAdjust: campaign.frequencyAdjust }} /></b>
                                </div>
                            </Col>
                        </Row>
                    </>
                }


                {showReviewProForm ?
                    campaign.surveyProvider === "ReviewPro" ?
                        <div>
                            <Row className="mt-2">
                                <Col sm={2}>
                                    <h5>
                                        <FormattedMessage id="Campaigns.Provider" />
                                    </h5>
                                </Col>
                                <Col sm={4}>
                                    <h5>
                                        <FormattedMessage id="Campaigns.Form" />
                                    </h5>
                                </Col>
                                <Col sm={6}>
                                    <h5>
                                        <FormattedMessage id="Campaigns.PmsId" />
                                    </h5>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col sm={2}>
                                    <CustomSelect options={surveyProviderOptions} onChange={handleSurveySelect.bind(this, 'surveyProvider')} value={surveyProviderOptions.find(opt => opt.value === campaign.surveyProvider)} />
                                </Col>
                                <Col sm={4}>
                                    <ReviewProFormsSelect
                                        id="ReviewProFormsSelectReviewPro"
                                        key="ReviewProFormsSelectReviewPro"
                                        onChangeFunc={handleSelect.bind(this, 'surveyId')}
                                        value={campaign.surveyId}
                                        url={`/api/gms/ReviewPro/v1/Surveys`}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <Input type="text" id="propertyId" name="propertyId" value={campaign.propertyId} onChange={handleChange} />
                                </Col>
                            </Row>
                        </div>
                        :
                        campaign.surveyProvider === "ProfileNowForms" ?
                            <div>
                                <Row className="mt-2">
                                    <Col sm={2}>
                                        <h5>
                                            <FormattedMessage id="Campaigns.Provider" />
                                        </h5>
                                    </Col>
                                    <Col sm={10}>
                                        <h5>
                                            <FormattedMessage id="Campaigns.Form" />
                                        </h5>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col sm={2}>
                                        <CustomSelect options={surveyProviderOptions} onChange={handleSurveySelect.bind(this, 'surveyProvider')} value={surveyProviderOptions.find(opt => opt.value === campaign.surveyProvider)} />
                                    </Col>
                                    <Col sm={10}>
                                        <ReviewProFormsSelect
                                            id="ReviewProFormsSelectProfileNow"
                                            key="ReviewProFormsSelectProfileNow"
                                            onChangeFunc={handleSelect.bind(this, 'surveyId')}
                                            value={campaign.surveyId}
                                            url={`/api/gms/Survey/v1/survey?hotelGroup=${global.hotelGroupId}&all=true`}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            :
                            campaign.surveyProvider === "Typeform" ?
                                <div>
                                    <Row className="mt-2">
                                        <Col sm={2}>
                                            <h5>
                                                <FormattedMessage id="Campaigns.Provider" />
                                            </h5>
                                        </Col>
                                        <Col sm={10}>
                                            <h5>
                                                <FormattedMessage id="Campaigns.Form" />
                                            </h5>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col sm={2}>
                                            <CustomSelect options={surveyProviderOptions} onChange={handleSurveySelect.bind(this, 'surveyProvider')} value={surveyProviderOptions.find(opt => opt.value === campaign.surveyProvider)} />
                                        </Col>
                                        <Col sm={10}>
                                            <FormsWithTitleSelect
                                                id="FormsWithTitleSelectTypeForm"
                                                key="FormsWithTitleSelectTypeForm"
                                                onChangeFunc={handleSelect.bind(this, 'surveyId')}
                                                value={campaign.surveyId}
                                                url={`/api/gms/TypeForm/Forms?page=1&pageSize=200`}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                :
                                <div>
                                    <Row className="mt-2">
                                        <Col sm={2}>
                                            <h5>
                                                <FormattedMessage id="Campaigns.Provider" />
                                            </h5>
                                        </Col>
                                        <Col sm={10}>
                                            <h5>
                                                <FormattedMessage id="Campaigns.Form" />
                                            </h5>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col sm={2}>
                                            <CustomSelect options={surveyProviderOptions} onChange={handleSurveySelect.bind(this, 'surveyProvider')} value={surveyProviderOptions.find(opt => opt.value === campaign.surveyProvider)} />
                                        </Col>
                                        <Col sm={10}>
                                            <CustomSelect options={[]} />
                                        </Col>
                                    </Row>
                                </div>
                    :
                    ''
                }

                {this.props.hasEgoi || this.props.hasSendGrid || this.props.hasTwillio ?
                    <RenderTemplateSelection
                        hasEgoi={this.props.hasEgoi}
                        hasSendGrid={this.props.hasSendGrid}
                        hasTwillio={this.props.hasTwillio}
                        campaignTemplates={campaign?.templates}
                        tabs={this.state.tabs}
                        handleTemplates={this.handleTemplates}
                        addTab={this.addTab}
                        removeTab={this.removeTab}
                        handleDefaultTemplate={this.handleDefaultTemplate}
                        senderChannel={this.props.campaign.senderChannel}
                    />
                : ''}

            </BlockUi>
        );
    }
}
export default injectIntl(CampaignStep2)