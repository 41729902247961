import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from '../../Base/ErrorAlert';
import { Card, Col, Row } from 'reactstrap';
import { getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { Pagination, ResultsPerPage, TotalResults } from '../../Base/PaginationComponents';

export class ProfileSalesProcessInteractions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            tasks: [],
            pageSize: 10,
            pageIndex: 0,
            totalItems: 0,
        };
    }

    componentDidMount() {
        this.getTasks();
    }

    getTasks = () => {
        const { profileId } = this.props;
        const { pageSize, pageIndex } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                this.setState({ error: errorMessage, block: false, tasks: data.data ? data.data : [], totalItems: data.total ? data.total : 0 });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/SalesProcess/v1/deals/profile/${profileId}/interactions?pageSize=${pageSize}&pageIndex=${pageIndex}`);
    }

    changePageSize = (evt) => {
        const { value } = evt.target;

        if (this.state.pageSize !== value) {
            this.setState({ pageSize: value, pageIndex: 0 }, this.getTasks);
        }
    }

    handlePagination = (value) => {
        this.setState({ block: true, pageIndex: this.state.pageIndex + value }, this.getTasks);
    }

    goToDealDetail = (id) => {
        this.props.history.push({ pathname: `/SalesProcess`, search: `?dealId=${id}` });
    }

    render() {
        const { block, error, pageIndex, pageSize, tasks, totalItems } = this.state;

        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />
                <Card className="ml-3 my-0 mr-0 border-0 bg-light h-100">
                    <Row className="mb-3">
                        <Col>
                            <h5>
                                <i className="fas fa-clipboard-list mr-1" />
                                <FormattedMessage id="ProfileDetails.SalesProcessInteractions" />
                            </h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='mt-3 text-muted' style={{ fontSize: '0.9em' }}>
                                <div className='d-flex align-items-center justify-content-between shadow p-3 mb-2 bg-white' style={{ borderRadius: '4px', gap: '1%' }}>
                                    <div style={{ width: '20%' }}>
                                        <div className='text-truncate'>
                                            <FormattedMessage id={`SalesTasks.Title`} />
                                        </div>
                                    </div>
                                    <div style={{ width: '20%' }}>
                                        <div className='text-truncate'>
                                            <FormattedMessage id={`SalesTasks.DealName`} />
                                        </div>
                                    </div>
                                    <div style={{ width: '15%' }}>
                                        <div className='text-truncate'>
                                            <FormattedMessage id={`generic.Type`} />
                                        </div>
                                    </div>
                                    <div style={{ width: '15%' }}>
                                        <div className='text-truncate'>
                                            <FormattedMessage id={`SalesTasks.Status`} />
                                        </div>
                                    </div>
                                    <div style={{ width: '15%' }}>
                                        <div className='text-truncate'>
                                            <FormattedMessage id={`SalesTasks.StartDate`} />
                                        </div>
                                    </div>
                                    <div style={{ width: '15%' }}>
                                        <div className='text-truncate'>
                                            <FormattedMessage id={`SalesTasks.EndDate`} />
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    {
                                        tasks.map((task, key) =>
                                            <div key={key} className='d-flex align-items-center justify-content-between cursor-pointer shadow p-3 mb-2 bg-white' style={{ borderRadius: '4px', gap: '1%' }}>
                                                <div style={{ width: '20%' }}>
                                                    <div className='text-truncate'>
                                                        {task.title ?? '-'}
                                                    </div>
                                                </div>
                                                <div style={{ width: '20%' }}>
                                                    <div>
                                                        {
                                                            task.dealName ?
                                                                <span className='text-truncate hover-underline hrefStyle' onClick={_ => this.goToDealDetail(task.salesDealId)}>
                                                                    {task.dealName}
                                                                </span>
                                                                :
                                                                '-'
                                                        }
                                                    </div>
                                                </div>
                                                <div style={{ width: '15%' }}>
                                                    <div className='text-truncate'>
                                                        {task.type ? <FormattedMessage id={`SalesProcess.${task.type}`} />  : '-'}
                                                    </div>
                                                </div>
                                                <div style={{ width: '15%' }}>
                                                    <div className='text-truncate'>
                                                        {task.status ?
                                                            <div className='d-flex align-items-center'>
                                                                {task.status === 'Scheduled' ?
                                                                    <i style={{ color: 'lightgrey' }} className="mr-2 fas fa-minus-circle" />
                                                                    : task.status === 'Cancelled' ?
                                                                        <i className="mr-2 fas fa-times-circle color-light-red" />
                                                                        : task.status === 'OnGoing' ?
                                                                            <i className="mr-2 fas fa-arrows-alt-h" style={{ color: "#0665ff" }} />
                                                                            : task.status === 'Finished' ?
                                                                                <i className="mr-2 fas fa-check-circle text-success" />
                                                                                : ''}
                                                                <div style={{ fontSize: '0.9em', marginTop: '1px' }} className={`deal-action-status-${task.status}`}>
                                                                    <FormattedMessage id={`SalesProcess.${task.status}`} />
                                                                </div>
                                                            </div>
                                                            : '-'}
                                                    </div>
                                                </div>
                                                <div style={{ width: '15%' }}>
                                                    <div className='text-truncate'>
                                                        {task.startDate ?
                                                            moment(task.startDate).format('YYYY-MM-DD HH:mm')
                                                            : '-'}
                                                    </div>
                                                </div>
                                                <div style={{ width: '15%' }}>
                                                    <div className='text-truncate'>
                                                        {task.endDate ?
                                                            moment(task.endDate).format('YYYY-MM-DD HH:mm')
                                                            : '-'}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </div>
                                <Row className="mt-4">
                                    <Col>
                                        <ResultsPerPage
                                            changeSizePerPage={this.changePageSize}
                                            pageSize={pageSize}
                                        />
                                        <span className="ml-2">
                                            <TotalResults
                                                end={pageIndex * pageSize + tasks.length}
                                                start={pageIndex * pageSize + 1}
                                                total={totalItems}
                                            />
                                        </span>
                                    </Col>
                                    <Col className="text-right">
                                        <Pagination
                                            isPrevDisabled={pageIndex === 0}
                                            isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                            currentPage={pageIndex + 1}
                                            handlePrevButton={() => this.handlePagination(-1)}
                                            handleNextButton={() => this.handlePagination(1)}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </BlockUi>
        );
    }
}