import React, { Component } from 'react';
import { Row, Col, Card, Badge, Button, UncontrolledTooltip } from 'reactstrap';
import { handleNotification } from '../Base/Notification';
import { FormattedMessage, injectIntl } from 'react-intl';
import { putAPI, postAPI, deleteAPI } from '../Base/API';
import AssociationModal from './AssociationModal';
import { ErrorAlert } from "../Base/ErrorAlert";
import { Link } from "react-router-dom";
import BlockUi from 'react-block-ui';

import Authorization from '../Base/Authorization';
import { ShowGuestNumber } from './ProfileCommonFunctions';
import ConfirmActionModal from '../Base/ConfirmActionModal';



export const ProfileCard = ({ bgColor, icon, source, profileId, curProfile, removeRelationFunc, history, canEditProfile, rowIdx, colIdx }) => {
    return (
        <div className="text-white h-100 p-3" style={{ backgroundColor: bgColor, borderRadius: '5px' }}>
            <Authorization
                perform="associatedProfiles:delete"
                yes={() => canEditProfile && (
                    <i className="fas fa-trash-alt mr-1 float-right small" style={{ cursor: 'pointer', position: 'relative', top: '-8px', right: '-12px' }} onClick={removeRelationFunc} />
                )}
                no={() => <div></div>}
            />

            <div id={`relationDescription${rowIdx}${colIdx}`} onClick={() => history.push({ pathname: `/ProfileDetails/${(profileId === curProfile.profileOrigId ? curProfile.profileDestId : curProfile.profileOrigId)}` })} className="pointer">
                <span style={{ "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis", display: 'block' }}>
                    {curProfile.profileFirstName} {curProfile.profileLastName}
                </span>
                {curProfile.profileIsMaster ? <Badge color="primary"> Master </Badge> : ''}

                <div className="pl-3 small py-1"><i className={icon} />{curProfile.inverseRelationType}</div>

                <div className="pl-3 small">
                    <ShowGuestNumber
                        searchableGuestNumber={curProfile.searchableGuestNumber}
                        pmsId={curProfile.pmsId}
                        idColor="color-white"
                    />
                </div>

                <div className="pl-3 small">
                    {source ?
                        <span> {source.icon} {source.label} </span>
                    : curProfile.source ?
                        <span> <i className="fas fa-globe mr-1 " /> {curProfile.source} </span>
                    : ''}
                </div>
            </div>
            {
                curProfile.relationDescription ?
                    <UncontrolledTooltip placement="bottom" target={`relationDescription${rowIdx}${colIdx}`}>
                        {curProfile.relationDescription}
                    </UncontrolledTooltip>
                    :
                    <div />
            }
        </div>
    )
}


class AssociatedProfiles extends Component {
    ProfileReservations = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            associatedProfiles: null,
            familyRelations: null,
            otherRelations: null,
            modal: false,
            deleteModal: false,
            relationTypes: [
                {
                    type: "Family",
                    label: "AssociatedProfiles.Family",
                    arrayName: "familyRelations",
                    bgColor: "#0867FA",
                    icon: "icon-icon-associated-profiles",
                    cardIcon: "icon-icon-associated-profiles",
                    emptyMsg: "AssociatedProfiles.NotLinkedToAnyPerson"
                },
                {
                    type: "Other",
                    label: "AssociatedProfiles.Company/Employee",
                    arrayName: "otherRelations",
                    bgColor: "#8B75EA",
                    icon: "far fa-building",
                    cardIcon: "fas fa-briefcase",
                    emptyMsg: "AssociatedProfiles.NotLinkedToAnyCompany"
                },
                {
                    type: "Linked",
                    label: "AssociatedProfiles.Linked",
                    arrayName: "linkedRelations",
                    bgColor: "#59B2C2",
                    icon: "fas fa-random",
                    cardIcon: "fas fa-random",
                    emptyMsg: "AssociatedProfiles.NotLinkedToAnyPerson"
                }
            ]
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps && nextProps.relationsList) {
            var familyRelations = nextProps.relationsList.filter(rel => rel.originalRelationType === 'Family');
            var linkedRelations = nextProps.relationsList.filter(rel => rel.originalRelationType === 'Linked');
            var otherRelations = nextProps.relationsList.filter(rel => rel.originalRelationType !== 'Family' && rel.originalRelationType !== 'Linked');

            this.setState({ familyRelations, otherRelations, linkedRelations });
        }
    }

    componentDidMount() {
        this._isMounted = true;

        var familyRelations = this.props.relationsList?.filter(rel => rel.originalRelationType === 'Family');
        var linkedRelations = this.props.relationsList.filter(rel => rel.originalRelationType === 'Linked');
        var otherRelations = this.props.relationsList?.filter(rel => rel.originalRelationType !== 'Family' && rel.originalRelationType !== 'Linked');

        this.setState({ associatedProfiles: this.props.associatedProfiles, familyRelations, otherRelations, linkedRelations });
    }

    addRelation = (profile, originalType, inverseType, relationDescription, event) => {
        if (event) {
            event.preventDefault();
        }
        this.setState({ block: true });

        var body = {
            profileOrigId: this.props.profileId,
            profileDestId: profile.id,
            originalRelationType: originalType,
            inverseRelationType: inverseType,
            relationDescription: relationDescription
        }

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="ProfileDetails.ProfileAssociated" />, <FormattedMessage id="generic.success" />);
                    this.props.updateRelationsList(data.response[0].id, true, data.response[0]);
                }

                this.setState({ block: false });
            }
            else this.setState({ block: false });
        }, `/api/gms/Profile/v1/profile/relation`, JSON.stringify({request: body}))
    }

    removeRelation = (event, relationId) => {
        event.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="AssociatedProfiles.RelationRemoved" />, <FormattedMessage id="generic.success" />);
                    this.props.updateRelationsList(relationId, false);
                    this.toggleDeleteModal();
                }

                this.setState({ block: false });
            }
            else this.setState({ block: false });
        }, `/api/gms/Profile/v1/profile/${relationId}/relation`)
    }

    disassociateProfile = (evt, id) => {
        evt.preventDefault();
        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="ProfileDetails.ProfileDisassociated" />, <FormattedMessage id="generic.success" />);
                    this.updateAssociatedProfilesList(data.response[0], false)
                }

                this.setState({ block: false });
            }
            else this.setState({ block: false });
        }, `/api/gms/Profile/v1/profile/dissociate?profileId=${id}`)
    }

    associateProfile = (event, profile) => {
        event.preventDefault();
        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="ProfileDetails.ProfileAssociated" />, <FormattedMessage id="generic.success" />);
                    this.updateAssociatedProfilesList(data.response[0], true)
                }

                this.setState({ block: false });
            }
            else this.setState({ block: false });
        }, `/api/gms/Profile/v1/profile/associate?profileId=${profile.id}&masterId=${this.props.profileId}`)
    }

    updateAssociatedProfilesList = (data, associate) => {
        this.setState({ block: true });

        var associatedProfiles = [...this.state.associatedProfiles];
        var array = associatedProfiles && associatedProfiles.length > 0 ? associatedProfiles : [];
        const index = array && array.indexOf(array.find(el => el.id === data.id));

        if (associate && index === -1) {
            array.push(data);
        }
        else {
            array = array.filter(el => el.id !== data.id);
        }

        this.setState({ associatedProfiles: array, block: false });
    }
    
    toggleModal = (modalType) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            modalType
        }))
    }

    toggleDeleteModal = (e, selectedProfile, removeFunction) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
            selectedProfile,
            removeFunction
        }));
    }

    render() {
        const { hotelId, sources, profileId, canEditProfile } = this.props;
        const { selectedProfile, deleteModal, removeFunction } = this.state;

        return (

            <BlockUi tag="div" blocking={this.state.block} className="h-100">
                <ErrorAlert error={this.state.error} />
                <Card className="ml-4 my-0 mr-0 border-0 bg-light h-100">
                    <Row>
                        <Col className="text-center">
                            <h5> <b><FormattedMessage id="ProfileDetails.AssociatedProfiles" /></b></h5>
                        </Col>
                    </Row>

                    <Row className="my-2">
                        {this.state.relationTypes.map((relation, key) => 
                            <Col key={key}>
                                <Row className="mb-2 ">
                                    <Col className="col-7 pr-0 d-flex align-items-center justify-content-between">
                                        <div>
                                            <i className={relation.icon + " mr-2"}></i>
                                            <FormattedMessage id={relation.label} />
                                        </div>

                                        <Authorization
                                            perform="associatedProfiles:add"
                                            yes={() => canEditProfile && (
                                                <Button className="btn-sm bt-host float-right" onClick={() => this.toggleModal(relation.type)} style={{ border: '0', backgroundColor: relation.bgColor }}>
                                                    <i className="fas fa-plus" />
                                                </Button>
                                            )}
                                            no={() => <div></div>}
                                        />
                                    </Col>
                                    <Col></Col>
                                </Row>
                                <Row>
                                    {this.state[relation.arrayName] && this.state[relation.arrayName].length > 0 ? this.state[relation.arrayName].map((el, k) => {
                                        const source = sources.find(s => s.value === el.source);

                                        return <Col key={k} className="p-2 col-6 text-white" >
                                            <ProfileCard
                                                bgColor={relation.bgColor}
                                                source={source}
                                                profileId={profileId}
                                                curProfile={el}
                                                icon={relation.cardIcon + " mr-1"}
                                                removeRelationFunc={(e) => this.toggleDeleteModal(e, el, 'removeRelation')}
                                                history={this.props.history}
                                                canEditProfile={canEditProfile}
                                                rowIdx={key}
                                                colIdx={k}
                                            />
                                        </Col>
                                    }) :
                                        <h6 className="ml-4 mt-2"><FormattedMessage id={relation.emptyMsg} /></h6>
                                    }

                                </Row>

                            </Col>
                        )}
                    </Row>
                    <Row className="mt-5">
                        <Col>
                            <Row className="mb-2">
                                <Col className="col-2 pr-0">
                                        <i className="far fa-clone mr-2" />
                                        <FormattedMessage id="AssociatedProfiles.Associated" />
                                </Col>
                                <Col className="pl-2">
                                    <Authorization
                                        perform="associatedProfiles:add"
                                        yes={() => canEditProfile && (
                                            <Button className="btn-sm" onClick={() => this.toggleModal('Duplicated')} style={{ backgroundColor: '#07294C', borderRadius: '5px', border: '0' }}>
                                                <i className="fas fa-plus" />
                                            </Button>
                                        )}
                                        no={() => <div></div>}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                {this.state.associatedProfiles && this.state.associatedProfiles.length > 0 ? this.state.associatedProfiles
                                    .sort((a, b) => {
                                        if (a.isMaster) {
                                            return -1;
                                        }
                                        if (b.isMaster) {
                                            return 0;
                                        }
                                        return a.id - b.id;
                                    })
                                    .map((el, k) => {
                                        const source = sources.find(s => s.value === el.source);
                                        const blurProfile = this.props.fixedHotelFilter && !el.isMaster && this.props.fixedHotelFilter !== el.hotelId;

                                        return <div key={k} className="p-2 text-white" style={{ width: '16.5%' }}>
                                            <div className="h-100 p-3 text-white" style={{ color: "white", backgroundColor: '#07294C', borderRadius: '5px' }}>

                                                {canEditProfile && !blurProfile ?
                                                    <Authorization
                                                        perform="associatedProfiles:delete"
                                                        yes={() => (
                                                            <i className="fas fa-trash-alt mr-1 float-right small" style={{ cursor: 'pointer', position: 'relative', top: '-8px', right: '-12px' }} onClick={(e) => this.toggleDeleteModal(e, el)} />
                                                        )}
                                                        no={() => <div></div>}
                                                    />
                                                    : ''}

                                                <div onClick={() => { if (!blurProfile) this.props.history.push({ pathname: `/ProfileDetails/${el.id}` }) }} className={blurProfile ? "blur" : "pointer"} style={blurProfile ? { userSelect: 'none' } : {}}>
                                                    <span style={{ "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis", display: 'block' }} >
                                                        {el.firstName} {el.lastName}
                                                    </span>


                                                    {el.isMaster ?
                                                        <div className="pl-3 py-1 small">
                                                            <div> <i className="icon-icon-profile mr-1" /> Master</div>
                                                            <div className="pl-3 small">
                                                                {source ? <span> {source.icon} {source.label} </span> : el.source ? <span> <i className="fas fa-globe mr-1 " /> {el.source} </span> : ''}
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="pl-3 py-1 small">
                                                            <div>
                                                                <ShowGuestNumber
                                                                    searchableGuestNumber={el.searchableGuestNumber}
                                                                    pmsId={el.pmsId}
                                                                />
                                                            </div>
                                                            <div>
                                                                {el.hotelName ? <span> <i className="icon-icon-hotel mr-1 " /> {el.hotelName} </span> : ''}
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    })
                                    :
                                    <><Col>
                                        <h6 className="ml-4 mt-2"> <FormattedMessage id="AssociatedProfiles.NoDuplicatedProfilesDetected" /></h6>
                                    </Col></>
                                }
                            </Row>
                        </Col>
                    </Row>
                </Card>

                    {this.state.modal ?
                        <AssociationModal
                            modal={this.state.modal}
                            toggle={this.toggleModal}
                            addRelation={this.addRelation}
                            associateProfile={this.associateProfile}
                            modalType={this.state.modalType}
                            hotelId={hotelId}
                        />
                    : ''}

                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={this.toggleDeleteModal}
                        actionFunction={(e) => { if (removeFunction === 'removeRelation') this.removeRelation(e, selectedProfile?.id); else this.disassociateProfile(e, selectedProfile?.id) }}
                        text={<FormattedMessage id="AssociatedProfiles.ConfirmDeleteText" values={{ profile: `${selectedProfile?.firstName || selectedProfile?.profileFirstName || ''} ${selectedProfile?.lastName || selectedProfile?.profileLastName || ''}` }} />}
                    />
                : ''}
            </BlockUi>
        );
    }
}
export default injectIntl(AssociatedProfiles)