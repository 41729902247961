import React, { Component } from 'react';
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import { Badge, Button, Col, Form, Input, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { KebabMenu } from '../Base/CommonUIComponents';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import { deleteAPI, getAPI, postAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';

class SalesProcessCustomization extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            block: true,
            supportedTypes: [
                {
                    area: 'Deal',
                    type: 'DealCancelReasonType',
                    values: []
                },
                {
                    area: 'Deal',
                    type: 'DealProfileRole',
                    values: []
                },
                {
                    area: 'Pipeline',
                    type: 'PipelineStageType',
                    values: []
                },
                {
                    area: 'Action',
                    type: 'FileActionType',
                    values: []
                },
                {
                    area: 'Action',
                    type: 'TaskActionType',
                    values: []
                },
                {
                    area: 'Pipeline',
                    type: 'DealRelationType',
                    values: []
                }
            ],
            confirmActionModal: false,
            selectedTypeKey: null,
            selectedValueKey: null
        };
    }

    componentDidMount(){
        this.getTypesList();
    }
    
    getTypesList = () => {
        const { supportedTypes } = this.state;
        
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.data?.length) {
                    const updatedTypes = supportedTypes.map(type => ({
                        ...type,
                        values: data.data.filter(v => v.type === type.type)
                    }));
                  
                    this.setState({
                      block: false,
                      supportedTypes: updatedTypes
                    });
                    
                    return;
                  }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/customtypes`);
    }

    saveChanges = (e, typeKey, valueKey) => {
        e.preventDefault();
        const { supportedTypes } = this.state;
        
        if(!supportedTypes)
            return;

        const type = supportedTypes[typeKey];

        if(!type)
            return;
        
        const value = type.values[valueKey];

        this.setState({ block: true }, () => {
            const payload = {...value, type: type.type};

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
    
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    } 
                    
                    handleNotification(data, <FormattedMessage id="SalesProcess.CustomDataRemoved"/>, <FormattedMessage id="generic.success" />);
                    this.getTypesList();
                    return;
                } else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/SalesProcess/v1/customtypes`, payload);
        });
    }

    deleteValue = () => {
        const { supportedTypes, selectedTypeKey, selectedValueKey } = this.state;
        const value = this.getCustomValue(supportedTypes, selectedTypeKey, selectedValueKey);
        const id = value?.id;

        if(!id)
            return;

        this.setState({ block: true }, () => {
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockPipelines: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        this.setState({ confirmActionModal: false, block: false }, () =>
                            handleNotification(data)
                        );
                        return;
                    }
                    else {
                        this.setState({ confirmActionModal: false }, () => {
                            handleNotification(data, <FormattedMessage id="SalesProcess.CustomDataRemoved"/>, <FormattedMessage id="generic.success" />);
                            this.getTypesList();
                        });
                        return;
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/customtypes/${id}`)
        });
    }

    addNewValue = (typeKey) => {
        const { supportedTypes } = this.state;
        const type = supportedTypes[typeKey];

        if(!type)
            return;
        
        if(type.values?.some(({isEditable}) => isEditable)){
            type.values = type.values.filter(({id}) => id !== null && id !== undefined);

            const editingValue = type.values.find(({isEditable}) => isEditable);

            if(editingValue){
                editingValue.isEditable = false;
            }

            this.setState({ supportedTypes });
            return;
        }   

        type.values.unshift({
            isEditable: true,
            name: ''
        });
        
        this.setState({ supportedTypes });
    }

    toggleEdit = (typeKey, valueKey) => {
        const { supportedTypes } = this.state;
        const value = this.getCustomValue(supportedTypes, typeKey, valueKey);

        if(!value)
            return;

        value.isEditable = !value.isEditable;

        this.setState({ supportedTypes });
    }

    toggleConfirmActionModal = (typeKey, valueKey) => {
        this.setState({ confirmActionModal: !this.state.confirmActionModal, selectedTypeKey: typeKey, selectedValueKey: valueKey });
    }

    getCustomValue = (supportedTypes, typeKey, valueKey) => {
        if(!supportedTypes)
            return;

        const type = supportedTypes[typeKey];

        if(!type)
            return;
        
        const value = type.values[valueKey];

        return value;
    }

    handleChange = (e, typeKey, valueKey) => {
        const { supportedTypes } = this.state;
        const value = this.getCustomValue(supportedTypes, typeKey, valueKey);

        if(!value) 
            return;
        
        value.name = e?.target?.value ?? '';

        this.setState({ supportedTypes });
    }

    render() {
        const { error, block, supportedTypes, confirmActionModal } = this.state;
        const { icon } = this.props;

        return (
            <div className="border-0 h-100 pb-2">
                <ErrorAlert error={error}/>
                <BlockUi tag="div" blocking={block}>
                    <div className='mb-4'>
                        <Row>
                            <Col>
                                <h4 className='m-0'>
                                    <i className={`${icon} mr-2`} />
                                    <FormattedMessage id="SalesProcess.Customization" />
                                </h4>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            {supportedTypes.map((type, key) =>
                                <Col key={key} className='col-6 mt-3'>
                                    <div className='shadow pt-3 pb-2 bg-white h-100'>
                                        <div className='px-3'>
                                            <Row style={{ borderBottom: '1px solid lightgrey' }} className={'pb-3 mb-2'}>
                                                <Col className='col-8 d-flex align-items-center'>
                                                    <div className='text-truncate' style={{ fontSize: '1.1em' }}>
                                                        <FormattedMessage id={`SalesProcess.${type.type}`}/>
                                                    </div>
                                                </Col>
                                                <Col className='col-4 d-flex align-items-center justify-content-between'>
                                                    <Badge color='primary'>
                                                        <FormattedMessage id={`SalesProcess.${type.area}`}/>
                                                    </Badge>
                                                    <div className='pl-1'>
                                                        <Button className="btn btn-host btn-sm" onClick={_ => this.addNewValue(key)}>
                                                            {type?.values?.some(t => t.isEditable) ? 
                                                                <i className="fas fa-times"></i>
                                                            :
                                                                <i className='fas fa-plus'/>
                                                            }
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div style={{ maxHeight: '200px', minHeight: '150px' }} className='overflow-auto veryCoolScrollBar'>
                                            <div className='px-3'>
                                                {type.values.map((value, k) =>
                                                    <React.Fragment key={k}>
                                                        {k ?
                                                            <hr class="px-2 my-0"/>
                                                        :''}
                                                        <Form className='py-2' onSubmit={e => this.saveChanges(e, key, k)}>
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <div style={{ width: '90%' }}>
                                                                    {value.isEditable ?
                                                                        <Input
                                                                            type="text"
                                                                            value={value.name}
                                                                            onChange={e => this.handleChange(e, key, k)}
                                                                            required={true}
                                                                        />
                                                                    :
                                                                        <div className='text-muted'>
                                                                            {value.name}
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div style={{ width: '10%' }} className='d-flex align-items-center justify-content-end'>
                                                                    {value.isEditable ?
                                                                        <Button className="btn btn-host btn-sm" type='submit'>
                                                                            <i className='fas fa-save'/>
                                                                        </Button>
                                                                    :
                                                                        <KebabMenu
                                                                            direction={'left'}
                                                                            editFunction={_ => this.toggleEdit(key, k)}
                                                                            deleteFunction={_ => this.toggleConfirmActionModal(key, k)}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </div>
                    {confirmActionModal ?
                        <ConfirmActionModal
                            modal={confirmActionModal}
                            toggleModal={() => this.toggleConfirmActionModal()}
                            actionFunction={this.deleteValue}
                            text={<FormattedMessage id="SalesProcess.ConfirmDeleteCustomValue"/>}
                            block={block}
                        />
                    :''}
                </BlockUi>
            </div>
        );
    }
}

export default injectIntl(SalesProcessCustomization);
