import { Funnel } from '@nivo/funnel';
import React, { Component } from 'react';

class CustomFunnel extends Component {

    constructor(props) {
        super(props);

        this.funnelParent = React.createRef();

        this.state = {
            funnelWidth: null
        };
    }

    componentDidMount() {
        this.setState({
            funnelWidth: this.funnelParent?.current?.offsetWidth ?? 600
        });
    }

    render() {
        const { data, colorScheme = 'blues', height = 300 } = this.props;
        const { funnelWidth } = this.state;

        return (
            <div className='w-100 h-100' ref={this.funnelParent}>
                <Funnel
                    height={height}
                    width={funnelWidth}
                    data={data}
                    margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                    direction="horizontal"
                    valueFormat=">-.4s"
                    colors={{ scheme: colorScheme }}
                    borderWidth={20}
                    labelColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                3
                            ]
                        ]
                    }}
                    beforeSeparatorOffset={20}
                    afterSeparatorOffset={20}
                />
            </div>
        );
    }
}

export default CustomFunnel;
