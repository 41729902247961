import React, { useEffect, useState } from 'react';
import { Row, Col, Badge, Input } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { getAPI } from '../Base/API';
import CustomSelect from '../Base/CustomSelect';


export function getSalesSourceTypes(intl) {
    return [
        { value: 'Event', label: intl.formatMessage({ id: "ReferenceDataFunctions.Event" }) },
        { value: 'Form', label: intl.formatMessage({ id: "ReferenceDataFunctions.Form" }) },
        { value: 'Partner', label: intl.formatMessage({ id: "ReferenceDataFunctions.Partner" }) },
        { value: 'Direct', label: intl.formatMessage({ id: "ReferenceDataFunctions.Direct" }) },
        { value: 'Renegotiation', label: intl.formatMessage({ id: "ReferenceDataFunctions.Renegotiation" }) },
    ]
}

export function getSalesConfigTypes(intl) {
    return [
        { value: 'HotelGroup', label: intl.formatMessage({ id: "ReferenceDataFunctions.HotelGroup" }) },
        { value: 'Brand', label: intl.formatMessage({ id: "ReferenceDataFunctions.Brand" }) },
        { value: 'Hotel', label: intl.formatMessage({ id: "ReferenceDataFunctions.Hotel" }) },
    ]
}

export function getSalesPipelineTypes(intl) {
    return [
        { value: 'Event', label: intl.formatMessage({ id: "ReferenceDataFunctions.Event" }) },
        { value: 'GroupReservation', label: intl.formatMessage({ id: "ReferenceDataFunctions.GroupReservation" }) },
        { value: 'Generic', label: intl.formatMessage({ id: "ReferenceDataFunctions.Generic" }) }
    ]
}

export function getSalesPipelineStageTypes(intl) {
    return [
        { value: 'Lead', label: intl.formatMessage({ id: "ReferenceDataFunctions.Lead" }) },
        { value: 'DealProcess', label: intl.formatMessage({ id: "ReferenceDataFunctions.DealProcess" }) },
        { value: 'Effective', label: intl.formatMessage({ id: "ReferenceDataFunctions.Effective" }) }
    ]
} //remover aqui

export function getDealStatus(intl) {
    return [
        { value: 'WaitingClient', label: intl.formatMessage({ id: "ReferenceDataFunctions.WaitingClient" }) },
        { value: 'WaitingHotel', label: intl.formatMessage({ id: "ReferenceDataFunctions.WaitingHotel" }) },
        { value: 'Cancelled', label: intl.formatMessage({ id: "ReferenceDataFunctions.Cancelled" }) },
        { value: 'Finished', label: intl.formatMessage({ id: "ReferenceDataFunctions.Finished" }) }
        // { value: 'Effective', label: intl.formatMessage({ id: "ReferenceDataFunctions.Effective" }) }
    ]
}

export function getLeadStatus(intl) {
    return [
        { value: 'WaitingClient', label: intl.formatMessage({ id: "SalesProcess.InNegotiation" }) },
        { value: 'Cancelled', label: intl.formatMessage({ id: "SalesProcess.Cancelled" }) },
        { value: 'Finished', label: intl.formatMessage({ id: "SalesProcess.Finished" }) }
    ]
}

export function getUsersRoles(intl) {
    return [
        { value: 'Responsible', label: intl.formatMessage({ id: "ReferenceDataFunctions.Responsible" }) },
        { value: 'Assistant', label: intl.formatMessage({ id: "ReferenceDataFunctions.Assistant" }) },
        { value: 'Related', label: intl.formatMessage({ id: "ReferenceDataFunctions.Related" }) }
    ]
}

export function getUsersRoleType(intl){ 
    return [
        { value: 'Manager', label: intl.formatMessage({ id: "ReferenceDataFunctions.Manager" }) },
        { value: 'SalesRep', label: intl.formatMessage({ id:"ReferenceDataFunctions.SalesRep" }) },
    ]
}

export const SelectCustomType = ({ icon, name, onChangeFunc, isMulti = false, required, value, placeholder, isDisabled, isClearable = true, isSearchable=true, type, bigCol }) => {

    const [data, setData] = useState([]);
    const [block, setLoad] = useState(true);
    const [error, setError] = useState('');
    
    useEffect(() => {
        window.setTimeout(
            () => {
                getAPI(result => {
                    const { data, error } = result;
                    if (error) {
                        var errorMessage = [];
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        setError(errorMessage);
                        setLoad(false)
                        return;
                    }
                    if (data && data.data && data.data.length > 0) {
                        var combo = data.data.map((el) => {
                            return {
                                label: el.name,
                                value: el.id
                            }
                        });

                        setData(combo);
                    }
                    setLoad(false);
                }, `/api/gms/SalesProcess/v1/customtypes?type=${type}`);
            },
            global.modules ? 0 : 2500
        );
    }, [type]);

    if(name === 'cancelReasonType'){
        if(!data || data.length === 0){
            return null;
        }else{
            return (
                <Col className={`${bigCol ? '' : 'col-3'} big-combo`}>
                    <div className="title-sm required"> <FormattedMessage id="SalesDeal.CancellationReasonType" /></div>
                    <CustomSelect 
                        icon={icon}
                        name={name}
                        options={data || []}
                        required={required}
                        isClearable={isClearable}
                        isMulti={isMulti}
                        value={data ? data.find(r => r.value === value) : ''}
                        placeholder={placeholder}
                        onChange={onChangeFunc.bind(this)}
                        isDisabled={isDisabled}
                        isLoading={block}
                        isSearchable={isSearchable}
                    />
                </Col>
            )
        }
    }else{
        return (
            <CustomSelect
                icon={icon}
                name={name}
                options={data || []}
                required={required}
                isClearable={isClearable}
                isMulti={isMulti}
                value={data ? data.find(r => r.value === value) : ''}
                placeholder={placeholder}
                onChange={onChangeFunc.bind(this)}
                isDisabled={isDisabled}
                isLoading={block}
                isSearchable={isSearchable}
            /> 
        )
    }

    
};

export const ProfileCard = ({ name, roleId, notes, handleListElement, removeElement }) => {
    const nameDivided = name?.split(' ');

    return <div>
        <Row className="align-items-center">
            <Col className="d-flex align-items-center">
                {nameDivided ?
                    <Badge className="profileBadge mr-2">
                        {nameDivided[0].substr(0, 1).toUpperCase()}{nameDivided?.length > 1 ? nameDivided[nameDivided.length - 1].substr(0, 1).toUpperCase() : ''}
                    </Badge>
                    : ''}

                <span style={{ "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis", display: 'block' }}>
                    {name}
                </span>
            </Col>

            <Col>
                <SelectCustomType 
                    name="roleId"
                    onChangeFunc={(combo) => handleListElement('roleId', combo ? combo.value : null)}
                    required={true}
                    value={roleId}
                    placeholder=""
                    isDisabled={false}
                    isClearable={true}
                    type={'DealProfileRole'}
                />
            </Col>
            <Col className="pr-0">
                <Input
                    required
                    type="text"
                    name="notes"
                    placeholder=""
                    value={notes || ''}
                    onChange={(event) => handleListElement('notes', event.target.value)}
                    maxLength={500}
                />
            </Col>
            <Col sm={1} className=" text-right pr-4">
                <i className="fas fa-trash-alt text-danger pointer" onClick={removeElement} />
            </Col>
        </Row>
    </div>
};