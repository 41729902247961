import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Row, Badge, Form } from 'reactstrap';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { CoolTooltip, FormatAmountNumber, KebabMenu } from '../Base/CommonUIComponents';
import countryList from 'react-select-country-list';
import FocusActionCard from './FocusActionCard';
import CustomToolTip from '../Base/CustomToolTip';

class SalesDealDetailOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    render() {
        const { source, selectedPipeline, deal, intl, toggleActionModal, toggleDealAssociationModal, toggleEdit, downloadAction, toggleConfirmActionModal, 
            changeActionPin, customers, salesUsers, focusActions, toggleTabandModal, roles, associatedDeals, toggleDeleteDealAssociationModal, toggleChangeAssociationTypeModal, 
            isLead, selectDealDetail } = this.props;

        return (
            <Row className='mt-2 h-100'>
                <Col className='pt-2 col-9'>
                    <div className='d-flex aling-items-center justify-content-between'>
                        <div>
                            <h5 className='m-0'>
                                {isLead ?
                                    <FormattedMessage id="SalesProcess.LeadResume"/>
                                :
                                    <FormattedMessage id="SalesProcess.DealDetails"/>
                                }
                            </h5>
                        </div>
                        <div>
                            <Button onClick={toggleEdit} className="btn btn-host btn-sm">
                                <i className="fas fa-edit"/>
                            </Button>
                        </div>
                    </div>
                    {!isLead ?
                        <Row className='text-left mt-3'>
                            <Col className='col-6'>
                                <div className="text-muted pb-3" style={{ fontSize: '1.1em', height: '38px' }}>
                                    <i className="fas fa-briefcase mr-2"/>
                                    <FormattedMessage id="SalesProcess.DealResume"/>
                                </div>
                                <div className='shadow w-100 bg-white p-3'>
                                    <div className="text-muted d-flex align-items-center justify-content-between">
                                        <div>
                                            <i className="mr-2 fas fa-suitcase-rolling"/>
                                            <FormattedMessage id="SalesProcess.DealDetails"/>
                                        </div>
                                        <i className="fas fa-edit cursor-pointer editFieldBtn" onClick={toggleEdit} />
                                    </div>
                                    <div>
                                        <Row className='w-100'>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.Source"/>
                                                </div>
                                                {source?.label ?
                                                    <div>
                                                        {source.label}
                                                    </div>
                                                :
                                                    <div>-</div>
                                                }
                                            </Col>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    {selectedPipeline?.label}
                                                </div>
                                                <div className='mt-1 h-100 d-flex w-100 px-1'>
                                                    {selectedPipeline?.stages ?
                                                        selectedPipeline?.stages.map((stage, key) =>{
                                                            const selectedStage = stage.value === deal.salesPipelineStage?.id;
                                                            const stageName = deal?.salesPipelineStage?.name;
                                                            
                                                            return (
                                                                <React.Fragment key={key}>
                                                                    <div id={`stage-${key}`} className={`h-100 ${selectedStage ? 'cursor-pointer' : ''}`} style={{ width: '100%' }}>
                                                                        <div className='text-center pipeline smallPipeline'>
                                                                            <div className={`arrow top smallPipeline noShadow ${selectedStage ? 'selectedStage' : ''}`}></div>
                                                                            <div className={`arrow bottom noShadow ${selectedStage ? 'selectedStage' : ''}`}></div>
                                                                        </div>
                                                                    </div>
                                                                    {selectedStage && stageName ?
                                                                        <CoolTooltip placement="bottom" target={`stage-${key}`}>
                                                                            {stageName}
                                                                        </CoolTooltip>
                                                                    :''}
                                                                </React.Fragment>
                                                            )}
                                                        )
                                                    :''}
                                                </div>
                                            </Col>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.BusinessStartDate"/>
                                                </div>
                                                {deal.businessStartDate ?
                                                    <div>
                                                        {moment(deal.businessStartDate).format('YYYY-MM-DD')}
                                                    </div>
                                                :
                                                    <div>-</div>
                                                }
                                            </Col>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.BusinessEndDate"/>
                                                </div>
                                                {deal.businessEndDate ?
                                                    <div>
                                                        {moment(deal.businessEndDate).format('YYYY-MM-DD')}
                                                    </div>
                                                :
                                                    <div>-</div>
                                                }
                                            </Col>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.EffectiveValue"/>
                                                </div>
                                                {deal.effectiveValue ?
                                                    <div>
                                                        {<FormatAmountNumber value={deal.effectiveValue}/>}
                                                    </div>
                                                :
                                                    <div>-</div>
                                                }
                                            </Col>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.TotalRevenue"/>
                                                </div>
                                                {deal.totalRevenue ?
                                                    <div>
                                                        <FormatAmountNumber value={deal.totalRevenue}/>
                                                    </div>
                                                :
                                                    <div>-</div>
                                                }
                                            </Col>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.RelatedHotels"/>
                                                </div>
                                                {deal.hotelIds ?
                                                    <div style={{ maxHeight: '25px' }} className='overflow-auto veryCoolScrollBar'>
                                                        {deal.hotelIds?.map((hotelId, key) => {
                                                            const hotel = global.hotelList?.find(({value}) => value === hotelId);
                                                            return (
                                                                <React.Fragment key={key}>
                                                                    {key ?
                                                                        <span>,</span>
                                                                    :''}
                                                                    <span className={key ? 'ml-1' : ''}>
                                                                        {hotel?.label || hotelId}
                                                                    </span>
                                                                </React.Fragment>
                                                            )
                                                        })}
                                                    </div>
                                                :
                                                    <div>-</div>
                                                }
                                            </Col>
                                            <Col className='col-3 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.Adults"/>
                                                </div>
                                                {deal.adults ?
                                                    <div style={{ maxHeight: '25px' }}>
                                                        {deal.adults}
                                                    </div>
                                                :
                                                    <div>-</div>
                                                }
                                            </Col>
                                            <Col className='col-3 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.Children"/>
                                                </div>
                                                {deal.children ?
                                                    <div style={{ maxHeight: '25px' }}>
                                                        {deal.children}
                                                    </div>
                                                :
                                                    <div>-</div>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col className='col-6' style={{ paddingTop: '38px' }}>
                                <div className='shadow w-100 bg-white p-3 h-100'>
                                    <div className="text-muted d-flex align-items-center justify-content-between">
                                        <div>
                                            <i className="mr-2 far fa-handshake"/>
                                            <FormattedMessage id="SalesProcess.Negotiation"/>
                                        </div>
                                        <i className="fas fa-edit cursor-pointer editFieldBtn" onClick={() => toggleEdit(2)} />
                                    </div>
                                    <div>
                                        <Row className='w-100'>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.NegotiationStartDate"/>
                                                </div>
                                                {deal.negotiationStartDate ?
                                                    <div>
                                                        {moment(deal.negotiationStartDate).format('YYYY-MM-DD')}
                                                    </div>
                                                :
                                                    <div>-</div>
                                                }
                                            </Col>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.NegotiationEndDate"/>
                                                </div>
                                                {deal.negotiationEndDate ?
                                                    <div>
                                                        {moment(deal.negotiationEndDate).format('YYYY-MM-DD')}
                                                    </div>
                                                :
                                                    <div>-</div>
                                                }
                                            </Col>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.ExpectedValue"/>
                                                </div>
                                                {deal.expectedValue ?
                                                    <div>
                                                        {<FormatAmountNumber value={deal.expectedValue}/>}
                                                    </div>
                                                :
                                                    <div>-</div>
                                                }
                                            </Col>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.Budget"/>
                                                </div>
                                                {deal.budget ?
                                                    <div>
                                                        <FormatAmountNumber value={deal.budget}/>
                                                    </div>
                                                :
                                                    <div>-</div>
                                                }
                                            </Col>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.ExpectedEndDate"/>
                                                </div>
                                                {deal.expectedEndDate ?
                                                    <div>
                                                        {moment(deal.expectedEndDate).format('YYYY-MM-DD')}
                                                    </div>
                                                :
                                                    <div>-</div>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col className='col-12 mt-3'>
                                <div className='shadow w-100 bg-white p-3'>
                                    <div className="text-muted">
                                        <i className="mr-2 fas fa-list"/>
                                        <FormattedMessage id="SalesProcess.Summary"/>
                                    </div>
                                    <div className='mt-2'>
                                        {deal.summary ?
                                            <div dangerouslySetInnerHTML={{ __html: deal.summary }} />
                                        :
                                            <div>-</div>
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col className='mt-4 col-6'>
                                <div className="text-muted pb-3" style={{ fontSize: '1.1em', height: '38px' }}>
                                    <i className="fas fa-users mr-2"/>
                                    <FormattedMessage id="SalesProcess.Clients"/>
                                </div>
                                <div className='shadow w-100 bg-white p-3'>
                                    <div>
                                        <div className='text-muted d-flex align-items-center justify-content-between'>
                                            <div>
                                                <i className="far fa-building mr-2"/>
                                                <FormattedMessage id="SalesProcess.Client"/>
                                            </div>
                                            <i className="fas fa-edit cursor-pointer editFieldBtn" onClick={() => toggleEdit(3)} />
                                        </div>
                                        <Row className='w-100'>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.Name"/>
                                                </div>
                                                <div>
                                                    <span className='text-muted hover-underline'>
                                                        <Link to={{ pathname: "/ProfileDetails/" + deal.customer.id }}>
                                                            {deal.customer.name}
                                                        </Link>
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.Type"/>
                                                </div>
                                                <div>
                                                    {deal.customer.type ?? '-'}
                                                </div>
                                            </Col>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.Nationality"/>
                                                </div>
                                                <div>
                                                    {countryList().getData().find(country => country.value === deal.customer.nationality)?.label ?? '-'}
                                                </div>
                                            </Col>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.FiscalNumber"/>
                                                </div>
                                                <div>
                                                    {deal.customer.fiscalNumber ?? '-'}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    {deal.mainContact?.name ?
                                        <div className='mt-3 pt-3' style={{ borderTop: '1px solid lightgrey' }}>
                                            <div className='text-muted'>
                                                <i className="fas fa-user-tie mr-2"/>
                                                <FormattedMessage id="SalesProcess.MainContact"/>
                                            </div>
                                            <div>
                                                <Row className='w-100'>
                                                    <Col className='col-6 mt-2'>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="SalesProcess.Name"/>
                                                        </div>
                                                        <div>
                                                            <span className='hover-underline'>
                                                                <Link to={{ pathname: "/ProfileDetails/" + deal.mainContact.id }}>
                                                                    {deal.mainContact.name}
                                                                </Link>
                                                            </span>
                                                        </div>
                                                    </Col>
                                                    <Col className='col-6 mt-2'>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="SalesProcess.Nationality"/>
                                                        </div>
                                                        <div>
                                                            {countryList().getData().find(country => country.value === deal.mainContact.nationality)?.label ?? '-'}
                                                        </div>
                                                    </Col>
                                                    <Col className='col-6 mt-2'>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="SalesProcess.Email"/>
                                                        </div>
                                                        <div>
                                                            {deal.mainContact.email ?? '-'}
                                                        </div>
                                                    </Col>
                                                    <Col className='col-6 mt-2'>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="SalesProcess.Phone"/>
                                                        </div>
                                                        <div>
                                                            {deal.mainContact.phone ?? '-'}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    :''}
                                </div>
                            </Col>
                            {deal.salesDealProfile?.length ?
                                <Col className='mt-4 col-6' style={{ paddingTop: '38px' }}>
                                    <div className='veryCoolScrollBar shadow w-100 bg-white p-3 h-100' style={{ maxHeight: '350px', overflow: 'auto' }}>
                                        {deal.salesDealProfile?.map((u, k) => {
                                            const user = customers.find(({id}) => id === u.id);
                                            return (
                                                <div key={k} className={k ? 'mt-3 pt-3' : ''} style={{ borderTop: k ? '1px solid lightgrey' : '' }}>
                                                    <div className={`text-muted ${k === 0 ? 'd-flex align-items-center justify-content-between' : ''}`}>
                                                        <div>
                                                            <i className="fas fa-user-tie mr-2"/>
                                                            {roles && roles.find(r => r.id === u.roleId) ?
                                                                roles.find(r => r.id === u.roleId)?.name
                                                            : 
                                                                '-'
                                                            }
                                                        </div>
                                                        {k === 0 ?
                                                            <i className="fas fa-edit cursor-pointer editFieldBtn" onClick={() => toggleEdit(3)} />
                                                            : ''}
                                                    </div>
                                                    <div>
                                                        <Row className='w-100'>
                                                            <Col className='col-6 mt-2'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="SalesProcess.Name"/>
                                                                </div>
                                                                <div>
                                                                    <span className='hover-underline'>
                                                                        <Link to={{ pathname: "/ProfileDetails/" + user.id }}>
                                                                            {user.name}
                                                                        </Link>
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                            <Col className='col-6 mt-2'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="SalesProcess.Nationality"/>
                                                                </div>
                                                                <div>
                                                                    {countryList().getData().find(country => country.value === u.nationality)?.label ?? '-'}
                                                                </div>
                                                            </Col>
                                                            <Col className='col-6 mt-2'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="SalesProcess.Email"/>
                                                                </div>
                                                                <div>
                                                                    {u.email ?? '-'}
                                                                </div>
                                                            </Col>
                                                            <Col className='col-6 mt-2'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="SalesProcess.Phone"/>
                                                                </div>
                                                                <div>
                                                                    {u.phone ?? '-'}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </Col>
                            :''}
                            <Col className='mt-4 col-6'>
                                <div className="text-muted pb-3" style={{ fontSize: '1.1em', height: '38px' }}>
                                    <i className="fas fa-cog mr-2"></i>
                                    <FormattedMessage id="SalesProcess.Internal"/>
                                </div>
                                <div className='shadow w-100 bg-white p-3'>
                                    <div className="text-muted d-flex align-items-center justify-content-between">
                                        <div>
                                            <i className="fas fa-user-cog mr-2"></i>
                                            <FormattedMessage id="SalesProcess.Staff"/>
                                        </div>
                                        <i className="fas fa-edit cursor-pointer editFieldBtn" onClick={() => toggleEdit(4)} />
                                    </div>
                                    <Row className='w-100'>
                                        {deal.salesDealUser?.map((u, k) => {
                                            const user = salesUsers.find(({id}) => id === u.userId);
                                            return (
                                                <Col key={k} className={`col-6 mt-2`}>
                                                    <div className='title-sm'>{u.role}</div>
                                                    {user?.firstName && user.lastName ?
                                                        <div>{`${user.firstName} ${user.lastName}`}</div>
                                                    :'-'}
                                                </Col>
                                            )}
                                        )}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    :
                        <Row className='text-left mt-3'>
                            <Col className='col-12'>
                                <div className="text-muted pb-3" style={{ fontSize: '1.1em', height: '38px' }}>
                                    <i className="fas fa-briefcase mr-2"/>
                                    <FormattedMessage id="SalesProcess.LeadResume"/>
                                </div>
                                <div className='shadow w-100 bg-white p-3'>
                                    <div className="text-muted d-flex align-items-center justify-content-between">
                                        <div>
                                            <i className="mr-2 fas fa-suitcase-rolling"/>
                                            <FormattedMessage id="SalesProcess.LeadDetails"/>
                                        </div>
                                        <i className="fas fa-edit cursor-pointer editFieldBtn" onClick={toggleEdit} />
                                    </div>
                                    <div>
                                        <Row className='w-100'>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.Source"/>
                                                </div>
                                                {source?.label ?
                                                    <div>
                                                        {source.label}
                                                    </div>
                                                :
                                                    <div>-</div>
                                                }
                                            </Col>
                                            <Col className='col-3 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.NegotiationStartDate"/>
                                                </div>
                                                {deal.negotiationStartDate ?
                                                    <div>
                                                        {moment(deal.negotiationStartDate).format('YYYY-MM-DD')}
                                                    </div>
                                                :
                                                    <div>-</div>
                                                }
                                            </Col>
                                            <Col className='col-3 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.NegotiationEndDate"/>
                                                </div>
                                                {deal.negotiationEndDate ?
                                                    <div>
                                                        {moment(deal.negotiationEndDate).format('YYYY-MM-DD')}
                                                    </div>
                                                :
                                                    <div>-</div>
                                                }
                                            </Col>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.RelatedHotels"/>
                                                </div>
                                                {deal.hotelIds ?
                                                    <div style={{ maxHeight: '25px' }} className='overflow-auto veryCoolScrollBar'>
                                                        {deal.hotelIds?.map((hotelId, key) => {
                                                            const hotel = global.hotelList?.find(({value}) => value === hotelId);
                                                            return (
                                                                <React.Fragment key={key}>
                                                                    {key ?
                                                                        <span>,</span>
                                                                    :''}
                                                                    <span className={key ? 'ml-1' : ''}>
                                                                        {hotel?.label || hotelId}
                                                                    </span>
                                                                </React.Fragment>
                                                            )
                                                        })}
                                                    </div>
                                                :
                                                    <div>-</div>
                                                }
                                            </Col>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.Summary"/>
                                                </div>
                                                <div className='mt-2'>
                                                    {deal.summary ?
                                                        <div dangerouslySetInnerHTML={{ __html: deal.summary }} />
                                                    :
                                                        <div>-</div>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col className='mt-4 col-6'>
                                <div className="text-muted pb-3" style={{ fontSize: '1.1em', height: '38px' }}>
                                    <i className="fas fa-users mr-2"/>
                                    <FormattedMessage id="SalesProcess.Clients"/>
                                </div>
                                <div className='veryCoolScrollBar shadow w-100 bg-white p-3 h-100' style={{ maxHeight: '350px', overflow: 'auto' }}>
                                    {deal.mainContact?.name ?
                                        <div>
                                            <div className='text-muted d-flex align-items-center justify-content-between'>
                                                <div className='text-muted'>
                                                    <i className="fas fa-user-tie mr-2"/>
                                                    <FormattedMessage id="SalesProcess.MainContact"/>
                                                </div>
                                                <div>
                                                    <i className="fas fa-edit cursor-pointer editFieldBtn" onClick={() => toggleEdit(3)} />
                                                </div>
                                            </div>
                                            <div>
                                                <Row className='w-100'>
                                                    <Col className='col-6 mt-2'>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="SalesProcess.Name"/>
                                                        </div>
                                                        <div>
                                                            <span className='hover-underline'>
                                                                <Link to={{ pathname: "/ProfileDetails/" + deal.mainContact.id }}>
                                                                    {deal.mainContact.name}
                                                                </Link>
                                                            </span>
                                                        </div>
                                                    </Col>
                                                    <Col className='col-6 mt-2'>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="SalesProcess.Nationality"/>
                                                        </div>
                                                        <div>
                                                            {countryList().getData().find(country => country.value === deal.mainContact.nationality)?.label ?? '-'}
                                                        </div>
                                                    </Col>
                                                    <Col className='col-6 mt-2'>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="SalesProcess.Email"/>
                                                        </div>
                                                        <div>
                                                            {deal.mainContact.email ?? '-'}
                                                        </div>
                                                    </Col>
                                                    <Col className='col-6 mt-2'>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="SalesProcess.Phone"/>
                                                        </div>
                                                        <div>
                                                            {deal.mainContact.phone ?? '-'}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    :''}
                                    {deal.salesDealProfile?.map((u, k) => {
                                        const user = customers.find(({ id }) => id === u.id);
                                        return (
                                            <div key={k} className={'mt-3 pt-3'} style={{ borderTop: '1px solid lightgrey' }}>
                                                <div className={`text-muted ${k === 0 ? 'd-flex align-items-center justify-content-between' : ''}`}>
                                                    <div>
                                                        <i className="fas fa-user-tie mr-2"/>
                                                        {roles && roles.find(r => r.id === u.roleId) ?
                                                            roles.find(r => r.id === u.roleId)?.name
                                                        : 
                                                            '-'
                                                        }
                                                    </div>
                                                    {k === 0 ?
                                                        <i className="fas fa-edit cursor-pointer editFieldBtn" onClick={() => toggleEdit(3)} />
                                                        : ''}
                                                </div>
                                                <div>
                                                    <Row className='w-100'>
                                                        <Col className='col-6 mt-2'>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id="SalesProcess.Name"/>
                                                            </div>
                                                            <div>
                                                                <span className='hover-underline'>
                                                                    <Link to={{ pathname: "/ProfileDetails/" + user.profileId }}>
                                                                        {user.name}
                                                                    </Link>
                                                                </span>
                                                            </div>
                                                        </Col>
                                                        <Col className='col-6 mt-2'>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id="SalesProcess.Nationality"/>
                                                            </div>
                                                            <div>
                                                                {countryList().getData().find(country => country.value === u.nationality)?.label ?? '-'}
                                                            </div>
                                                        </Col>
                                                        <Col className='col-6 mt-2'>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id="SalesProcess.Email"/>
                                                            </div>
                                                            <div>
                                                                {u.email ?? '-'}
                                                            </div>
                                                        </Col>
                                                        <Col className='col-6 mt-2'>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id="SalesProcess.Phone"/>
                                                            </div>
                                                            <div>
                                                                {u.phone ?? '-'}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </Col>
                            <Col className='mt-4 col-6'>
                                <div className="text-muted pb-3" style={{ fontSize: '1.1em', height: '38px' }}>
                                    <i className="fas fa-cog mr-2"></i>
                                    <FormattedMessage id="SalesProcess.Internal"/>
                                </div>
                                <div className='shadow w-100 bg-white p-3 h-100' style={{ maxHeight : '350px'}}>
                                    <div className="text-muted d-flex align-items-center justify-content-between">
                                        <div>
                                            <i className="fas fa-user-cog mr-2"></i>
                                            <FormattedMessage id="SalesProcess.Staff"/>
                                        </div>
                                        <i className="fas fa-edit cursor-pointer editFieldBtn" onClick={() => toggleEdit(4)} />
                                    </div>
                                    <Row className='w-100'>
                                        {deal.salesDealUser?.map((u, k) => {
                                            const user = salesUsers.find(({id}) => id === u.userId);
                                            return (
                                                <Col key={k} className={`col-6 mt-2`}>
                                                    <div className='title-sm'>{u.role}</div>
                                                    {user?.firstName && user.lastName ?
                                                        <div>{`${user.firstName} ${user.lastName}`}</div>
                                                    :'-'}
                                                </Col>
                                            )}
                                        )}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    }
                </Col>
                <Col className='pt-2 col-3 d-flex justify-content-between h-100 flex-column' style={{ textAlign: 'center', top: '0', position: 'sticky' }}>
                    {!isLead ?
                        <div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <h5 className="m-0">
                                        <FormattedMessage id="SalesDeal.Proposal"/>
                                    </h5>
                                </div>
                                <div>
                                    <Button onClick={toggleTabandModal} className="btn btn-host btn-sm btn-secondary">
                                        <FormattedMessage id="SalesDeal.AddNewProposal"/>
                                        <i className="ml-2 fas fa-receipt"/>
                                    </Button>
                                </div>
                            </div>
                            <div className="mt-4 shadow h-100 w-100 bg-white p-3">
                                {deal.dealProposalData && deal.dealProposalData.totalValue ?
                                    <div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="text-muted">
                                                <i className="fas fa-dollar-sign mr-2"/>
                                                <FormattedMessage id="SalesDeal.TotalValue"/>
                                            </div>
                                            <div className="text-right" style={{ fontSize: '1.2em' }}>
                                                <b>
                                                    <FormatAmountNumber
                                                        currency={global.hotelCurrency}
                                                        value={deal.dealProposalData.totalValue??0}
                                                    />
                                                </b>
                                            </div>
                                        </div>
                                        <Row className="px-3 mt-3 text-left">
                                            <Col className="col-3 pr-1 pl-0">
                                                <div className="title-sm text-truncate">
                                                    <i className="fas fa-calendar-day mr-2"/>
                                                    <FormattedMessage id="SalesDeal.Nights"/>
                                                </div>
                                                <div>
                                                    {deal.dealProposalData.numberOfNights ?? 0}
                                                </div>
                                            </Col>
                                            <Col className="col-3 px-1">
                                                <div className="title-sm text-truncate">
                                                    <i className="fas fa-bed mr-2"/>
                                                    <FormattedMessage id="SalesDeal.Rooms"/>
                                                </div>
                                                <div>
                                                    {deal.dealProposalData.numberOfRooms??0}
                                                </div>
                                            </Col>
                                            <Col className="col-3 px-1">
                                                <div className="title-sm text-truncate">
                                                    <i className="fas fa-store-alt mr-2"/>
                                                    <FormattedMessage id="SalesDeal.Spaces"/>
                                                </div>
                                                <div>
                                                    {deal.dealProposalData.numberOfSpaces??0}
                                                </div>
                                            </Col>
                                            <Col className="col-3 pl-1 pr-0">
                                                <div className="title-sm text-truncate">
                                                    <i className="fas fa-microphone mr-2"/>
                                                    <FormattedMessage id="SalesDeal.Equipment"/>
                                                </div>
                                                <div>
                                                    {deal.dealProposalData.numberOfEquipment??0}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                :
                                    <div className='d-flex align-items-center justify-content-center text-muted' style={{ minHeight: '50px' }}>
                                        <FormattedMessage id="SalesDeal.NoProposalsYet"/>
                                    </div>
                                }
                            </div>
                        </div>
                    :''}
                    <div className={isLead ? '' : 'mt-4'}>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div>
                                <h5 className='m-0'>
                                    <FormattedMessage id="SalesProcess.Focus"/>
                                </h5>
                            </div>
                            <div>
                                <Button onClick={_ => toggleActionModal()} className="btn btn-host btn-sm">
                                    <FormattedMessage id="SalesProcess.NewAction"/>
                                    <i className="fas fa-play ml-2"/>
                                </Button>
                            </div>
                        </div>
                        <div className='mt-4'>
                            {focusActions && focusActions.length > 0 ?
                                focusActions.map((action, key, arr) =>
                                    <div key={key}>
                                        <FocusActionCard
                                            action={action}
                                            intl={intl}
                                            focusActions={arr}
                                            editFunction={_ => toggleActionModal(action)}
                                            deleteFunction={_ => toggleConfirmActionModal(action, 'Cancelled')}
                                            finishedFunction={_ => toggleConfirmActionModal(action, 'Finished')}
                                            onGoingFunction={_ => toggleConfirmActionModal(action, 'OnGoing')}
                                            pinAction={(e) => changeActionPin(e, action)}
                                            downloadFunction={(e) => downloadAction(e, action)}
                                        />
                                    </div>
                                )
                            :
                                <div className='text-muted text-center mt-4'>
                                    <FormattedMessage id="SalesProcess.NothingToFocus"/>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div>
                                <h5 className='m-0'>
                                    <FormattedMessage id="SalesProcess.AssociatedDeals" />
                                </h5>
                            </div>
                            <div>
                                <Button onClick={_ => toggleDealAssociationModal()} className="btn btn-host btn-sm">
                                    <FormattedMessage id="SalesProcess.AssociatedNewDeal"/>
                                    <i className="fas fa-random ml-2"/>
                                </Button>
                            </div>
                        </div>
                        <div className='mt-4'>
                            {associatedDeals?.length > 0 ?
                                associatedDeals.map((association, key) =>
                                    association.deals ?
                                        <div key={key}>
                                            <h6 className={`m-0 text-muted text-left ${key === 0 ? 'pb-3' : 'py-3'}`}><i className="fas fa-random mr-1" /> {association.relationName}</h6>
                                            {association.deals.map((relatedDeal, dealKey) =>
                                                <div key={dealKey} className='p-3 mb-2 bg-white shadow border-0'>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div className='d-flex align-items-center justify-content-between pr-2' style={{ width: '98%' }}>
                                                            <div style={{ width: '70%' }} className='d-flex align-items-center'>
                                                                <div id={`typeOfDeal-${key}-${dealKey}`}>
                                                                    {!isLead ?
                                                                        <i class="fas fa-briefcase text-muted mr-2" />
                                                                    :
                                                                        <i class="fas fa-bullseye text-muted mr-2" />
                                                                    }
                                                                </div>
                                                                <CustomToolTip placementPrefix="coolTooltip bs-tooltip" placement="top" target={`typeOfDeal-${key}-${dealKey}`}>
                                                                {!isLead ?
                                                                    <FormattedMessage id="SalesProcess.Deal" />
                                                                :
                                                                    <FormattedMessage id="SalesProcess.Lead" />
                                                                }
                                                                </CustomToolTip>
                                                                <div className='text-truncate' style={{ maxWidth: '80%' }}>
                                                                    {relatedDeal.relatedDealName}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                {relatedDeal.relatedDealStatus ?
                                                                    relatedDeal.relatedDealStatus == 'WaitingHotel' ?
                                                                        <div 
                                                                            style={{ 
                                                                                fontSize: '0.7em', 
                                                                                backgroundColor: '#0665ff', 
                                                                                border: '1px solid #0665ff',
                                                                                borderRadius: '4px',
                                                                                minWidth: '5.5vw'
                                                                            }} 
                                                                            className={`d-flex align-items-center p-1 color-white`}
                                                                        >
                                                                            <i class="icon-icon-hotel mr-1" />
                                                                            <FormattedMessage id={`SalesProcess.WaitingForHotel`} />
                                                                        </div>
                                                                    : relatedDeal.relatedDealStatus == "WaitingClient" ?
                                                                        <div 
                                                                            style={{ 
                                                                                fontSize: '0.7em', 
                                                                                backgroundColor: '#d3d3d3', 
                                                                                border: '1px solid #d3d3d3',
                                                                                borderRadius: '4px',
                                                                                minWidth: '5.6vw'
                                                                            }} 
                                                                            className={`d-flex align-items-center p-1 color-white`}
                                                                        >
                                                                            <i class="far fa-building mr-1" />
                                                                            <FormattedMessage id={`SalesProcess.WaitingForClient`} />
                                                                        </div>
                                                                    : relatedDeal.relatedDealStatus == "Cancelled" ?
                                                                        <div 
                                                                            style={{ 
                                                                                fontSize: '0.7em', 
                                                                                backgroundColor: '#F38375', 
                                                                                border: '1px solid #F38375',
                                                                                borderRadius: '4px' 
                                                                            }} 
                                                                            className={`d-flex align-items-center p-1 color-white`}
                                                                        >
                                                                            <i class="far fa-thumbs-down mr-1" />
                                                                            <FormattedMessage id={`SalesProcess.Lost`} />
                                                                        </div>
                                                                    : relatedDeal.relatedDealStatus == "Finished" ?
                                                                        <div 
                                                                            style={{ 
                                                                                fontSize: '0.7em', 
                                                                                backgroundColor: '#36ad51', 
                                                                                border: '1px solid #36ad51',
                                                                                borderRadius: '4px' 
                                                                            }} 
                                                                            className={` d-flex align-items-center p-1 color-white`}
                                                                        >
                                                                            <i class="fas fa-trophy mr-1" />
                                                                            <FormattedMessage id={`SalesProcess.Won`} />
                                                                        </div>
                                                                    : ''
                                                                : ''}
                                                            </div>
                                                        </div>
                                                        <div className='text-right'>
                                                            <KebabMenu
                                                                deleteFunction={() => toggleDeleteDealAssociationModal(relatedDeal.salesDealRelationId)}
                                                                deleteText={"SalesProcess.DeleteRelation"}
                                                                extraFields={[
                                                                    {
                                                                        text: <span><FormattedMessage id="SalesProcess.ChangeDealAssociationType" /></span>,
                                                                        function: _ => toggleChangeAssociationTypeModal(relatedDeal.relationId, relatedDeal.salesDealRelationId, relatedDeal.relatedDealId),
                                                                        icon: "far fa-edit mr-2",
                                                                    },
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    :''
                                )
                            :
                                <div className='text-muted text-center mt-4'>
                                    <FormattedMessage id="SalesProcess.NoDealsAssociated" />
                                </div>
                            }
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default injectIntl(SalesDealDetailOverview);