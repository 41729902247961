import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Row, Col, Button, Form, PopoverBody } from 'reactstrap';
import { CommonHelper, FormatAmountNumber, SelectHotel } from '../Base/CommonUIComponents';
import CustomSelect from '../Base/CustomSelect';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { DateRangePicker } from 'react-dates';
import moment from 'moment-timezone';
import SalesDealModal from './SalesDealModal/SalesDealModal';
import { handleNotification } from '../Base/Notification';
import { putAPI } from '../Base/API';
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import SalesActionsPopOver from './SalesActionsPopOver';
import SalesProcessDefaultPage from './SalesProcessDefaultPage';
import CustomToolTip from '../Base/CustomToolTip';

class SalesPipelineStage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedStaff: null,
            salesSourceId: null,
            startDate: null,
            pipeline: null,
            endDate: null,
            hotel: null,
            error: null,
            block: false
        };
    }

    componentDidMount() {
        if(this.props.selectedPipeline){
            this.props.getDeals(this.props.selectedPipeline, this.state);
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.selectedPipeline?.value !== this.props.selectedPipeline?.value)
            this.setState({ pipeline: nextProps.selectedPipeline });
    }

    changeDealStage = (e) => {
        e.preventDefault();

        this.setState({ block: true }, () => {
            putAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    else if (data.data?.length > 0) {
                        handleNotification(data, <FormattedMessage id="SalesDeal.DealMoved" />, <FormattedMessage id="generic.success" />);
                        
                        this.props.updateDealStage(data.data[0]);
                        this.setState({ error: errorMessage, block: false });

                        return;
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `api/gms/SalesProcess/v1/deals/${this.state.grabbingDealId}/pipelinestage/${this.state.dragToStageId}`);
        });
    }

    handlePipeline = (combo) => {
        this.setState({ pipeline: combo });
    }

    handleHotel = (e, combo) => {
        this.setState({ hotel: combo?.value });
    }

    handleSelectedStaff = (el) => {
        this.setState({ selectedStaff: el?.value });
    }
    handleSelectedSource = (el) => {
        this.setState({ salesSourceId: el?.value });
    }
    

    handleDateChanges = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate });
    }

    toggleModal = (modal) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal]
        }));
    }

    onDragStart = (e, grabbingDealId, excludedStageId) => {
        e.dataTransfer.setData("deal", JSON.stringify(grabbingDealId));

        this.setState(prevState => ({ ...prevState, showDragArea: true, grabbingDealId, excludedStageId }))
    }

    onDragEnd = () => {
        this.setState({ showDragArea: false, dragToStageId: null, grabbingDealId: null, excludedStageId: null })
    }

    onDragOver = (e, stageId) => {
        e.preventDefault();

        if (this.state.dragToStageId !== stageId) {
            this.setState({ dragToStageId: stageId });
        }
    }
    
    applySearch = (e) => {
        e.preventDefault();
        this.props.getDeals(this.state.pipeline ?? this.props.selectedPipeline, this.state);
    }

    renderHelperMsg = () => {
        return (
            <div style={{ width: '200px' }}>
                <div className='d-flex align-items-center justify-content-between p-2'>
                    <div className='actionStatusIcon' style={{ background: "#dc3545" }}>
                        <i className="fas fa-angle-right" />
                    </div>
                    <FormattedMessage id="SalesProcess.MissedAction" />
                </div>
                <div className='d-flex align-items-center justify-content-between p-2'>
                    <div className='actionStatusIcon' style={{ background: "#0665ff" }}>
                        <i className="fas fa-angle-right" />
                    </div>
                    <FormattedMessage id="SalesProcess.OnGoingAction" />
                </div>
                <div className='d-flex align-items-center justify-content-between p-2'>
                    <div className='actionStatusIcon' style={{ background: "#28a745" }}>
                        <i className="fas fa-angle-right" />
                    </div>
                    <FormattedMessage id="SalesProcess.ScheduledAction" />
                </div>
                <div className='d-flex align-items-center justify-content-between p-2'>
                    <div className='actionStatusIcon' style={{ background: "#ffc107" }}>
                        <i className="fas fa-angle-right" />
                    </div>
                    <FormattedMessage id="SalesProcess.NoActions" />
                </div>
                <hr />
                <div className='d-flex align-items-center justify-content-between p-2'>
                    <div className='p-3 w-100 bg-white shadow' style={{ borderRadius: '5px', borderLeft: '5px solid #0665ff', maxWidth: '50px' }} />
                    <p className='m-0'><FormattedMessage id="SalesProcess.WaitingHotel" /></p>
                </div>
                <div className='d-flex align-items-center justify-content-between p-2'>
                    <div className=' p-3 w-100 bg-white shadow' style={{ borderRadius: '5px', borderLeft: '5px solid #d3d3d3', maxWidth: '50px' }} />
                    <p className='m-0'><FormattedMessage id="SalesProcess.WaitingClient" /></p>
                </div>
            </div>
        );
    }

    render() {
        const { selectedStaff, salesSourceId, startDate, endDate, excludedStageId, showDragArea, dragToStageId, pipeline, createDeal, error, block, hotel } = this.state;
        const { salesPipelines, salesSources, deals, intl, selectDealDetail, selectedPipeline, blockPipelines, salesUsers, getDeals } = this.props;
        
        return (
            <div className='h-100 pb-2'>
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    {createDeal ?
                        <SalesDealModal
                            modal={createDeal}
                            salesUsers={salesUsers}
                            toggleModal={_ => this.toggleModal('createDeal')}
                            salesStages={selectedPipeline.stages}
                            salesSources={salesSources}
                            getDeals={_ => this.props.getDeals(selectedPipeline)}
                            salesPipelineId={selectedPipeline.id}
                            salesPipelines={salesPipelines}
                        />
                    :''}

                    <Row className="mb-4">
                        <Col className='d-flex align-items-center justify-content-between'>
                            <h4 className='m-0'>
                                <i className={`${this.props.icon} mr-2`} />
                                <FormattedMessage id="SalesProcess.SalesPipelineStage" />
                            </h4>
                            <CommonHelper help={this.renderHelperMsg()} id={'salesPipelineStageHelp'} />
                        </Col>
                    </Row>

                    <Form onSubmit={this.applySearch}>
                        <div className='d-flex align-items-start justify-content-between' style={{ gap: '2%' }}>
                            <div style={{ width: '16%' }}>
                                <div className='title-sm'>
                                    <i className={`fas fa-sliders-h mr-2`} />
                                    <FormattedMessage id="SalesProcess.SalesPipelines"/>
                                </div>
                                <div>
                                    <CustomSelect
                                        options={salesPipelines}
                                        required={true}
                                        isClearable={false}
                                        isMulti={false}
                                        value={salesPipelines?.filter(({ value }) => value === (pipeline?.value ?? selectedPipeline?.value)) || ''}
                                        isSearchable={true}
                                        onChange={this.handlePipeline}
                                        placeholder={intl.formatMessage({ id: "SalesProcess.SalesPipelines" })}
                                        isLoading={blockPipelines}
                                    />
                                </div>
                            </div>
                            <div style={{ width: '16%' }}>
                                <div className='title-sm'>
                                    <i className="fas fa-users mr-2"/>
                                    <FormattedMessage id="SalesProcess.Staff"/>
                                </div>
                                <div>
                                    <CustomSelect
                                        options={salesUsers}
                                        isClearable={true}
                                        isMulti={false}
                                        value={salesUsers?.find(({value}) => value === selectedStaff)}
                                        isSearchable={true}
                                        onChange={this.handleSelectedStaff}
                                        placeholder={intl.formatMessage({ id: "SalesProcess.Staff" })}
                                    />
                                </div>
                            </div>
                            <div style={{ width: '16%' }}>
                                <div className='title-sm'>
                                    <i className="icon-icon-hotel mr-2"/>
                                    <FormattedMessage id="SalesProcess.Hotel"/>
                                </div>
                                <div>
                                    <SelectHotel
                                        isSearchable
                                        name='hotelIds'
                                        onChangeFunc={this.handleHotel}
                                        placeholder={'Hotel'}
                                        value={hotel || ''}
                                        params='?onlyFromHotelGroup=true'
                                    />
                                </div>
                            </div>
                            <div style={{ width: '16%' }}>
                                <div className='title-sm'>
                                    <i className={`fas fa-code-branch mr-2`} />
                                    <FormattedMessage id="SalesProcess.SalesSources" />
                                </div>
                                <div>
                                    <CustomSelect
                                        options={salesSources}
                                        required={false}
                                        isClearable={true}
                                        isMulti={false}
                                        value={salesSources?.find(({ value }) => value === salesSourceId)}
                                        isSearchable={true}
                                        onChange={ this.handleSelectedSource}
                                        placeholder={intl.formatMessage({ id: "SalesProcess.SalesSources" })}
                                    />
                                </div>
                            </div>
                            <div style={{ width: '16%', whiteSpace: 'nowrap' }}>
                                <div className='title-sm'>
                                    <i className="far fa-calendar-alt mr-2" />
                                    <FormattedMessage id="SalesProcess.BusinessDates" />
                                </div>
                                <div>
                                    <DateRangePicker
                                        startDate={startDate}
                                        startDateId="startDateId"
                                        endDate={endDate}
                                        endDateId="endDateId"
                                        isOutsideRange={_ => false}
                                        onDatesChange={this.handleDateChanges}
                                        focusedInput={this.state.focusedInput}
                                        showClearDates={true}
                                        onFocusChange={focusedInput => this.setState({ focusedInput })}
                                        small={true}
                                        showDefaultInputIcon={true}
                                        renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                    />
                                </div>
                            </div>
                            <div style={{ width: '17%' }} className='d-flex align-items-end justify-content-end'>
                                {deals && deals.length > 0 ?
                                    <div className='d-flex aling-items-center justify-content-end pr-4'>
                                        <div>
                                            <FormatAmountNumber value={deals?.reduce((a, d) => a + (d.expectedValue??0), 0) ?? 0} />
                                        </div>
                                        <span className='mx-2'>-</span>
                                        <div>
                                            <b className='mr-1'>{deals?.length ?? '0'}</b>
                                            <FormattedMessage id="SalesProcess.Deals" />
                                        </div>
                                    </div>
                                : ''}
                                <Button className="btn-sm btn-host" onClick={_ => selectedPipeline ? this.toggleModal('createDeal') : {}}>
                                    <i className="mr-1 fas fa-plus" />
                                    <FormattedMessage id="SalesProcess.Deal" />
                                </Button>
                                <Button className="btn-sm btn-host ml-2" type='submit'>
                                    <i className="fas fa-search" />
                                </Button>
                            </div>
                        </div>
                    </Form>
                    <SalesProcessDefaultPage
                        showSelect={!selectedPipeline}
                        mainValue={salesPipelines?.length}
                        noValueText={"SalesProcess.NoPipelines"}
                        text={"SalesProcess.SelectPipeline"}
                        icon={this.props.icon}
                    >
                        <div className='mt-4 p-3 h-100 d-flex w-100 overflow-auto scrollableDiv'>
                            {selectedPipeline?.stages?.sort((a, b) => a.index - b.index).map((stage, key) => {
                                const relatedDeals = deals.filter(({ salesPipelineStageId }) => salesPipelineStageId === stage.value);

                                return (
                                    <div key={key} className='h-100 mr-3 px-2' style={{ minWidth: '250px', width: '100%', minHeight: '60vh' }}
                                        onDragOver={(e) => { this.onDragOver(e, (excludedStageId !== stage.value ? stage.value : null)) }}
                                        onDrop={(e) => { if (excludedStageId !== stage.value) this.changeDealStage(e) }}
                                    >
                                        <div className='text-center pipeline'>
                                            <div className='arrow top'></div>
                                            <div className='pipelineName bg-white text-truncate'>
                                                <div className='d-flex align-items-center justify-content-center' style={{ maxWidth: '100%' }}>
                                                    {stage.type === "Lead" ?
                                                        <i title={intl.formatMessage({ id: `SalesProcess.${stage.type}` })} className="fas fa-bullseye mr-2"/>
                                                    : stage.type === "DealProcess" ?
                                                        <i title={intl.formatMessage({ id: `SalesProcess.${stage.type}` })} className="fas fa-briefcase mr-2"/>
                                                    : stage.type === "Effective" ?
                                                        <i title={intl.formatMessage({ id: `SalesProcess.${stage.type}` })} className="fas fa-user-tie mr-2"/>
                                                    :''}
                                                    <div title={stage.label} className='text-truncate' style={{ maxWidth: '100%' }}>
                                                        {stage.label}
                                                    </div>
                                                </div>
                                                <div style={{ fontSize: '0.9em' }} className='text-muted d-flex align-items-center justify-content-center'>
                                                    <div>
                                                        <FormatAmountNumber value={relatedDeals.reduce((a, b) => a + (b.expectedValue || 0), 0)}/>
                                                    </div>
                                                    <div className='mx-2'>-</div>
                                                    <div>
                                                        <span className='mr-1'>{relatedDeals.length}</span>
                                                        <FormattedMessage id="SalesProcess.Deals"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='arrow bottom'></div>
                                        </div>
                                        {showDragArea && dragToStageId === stage.value ?
                                            <div className="mt-3" style={{
                                                height: '15px',
                                                backgroundColor: '#165C7D20',
                                                borderRadius: '10px'
                                            }} />
                                        : ''}
                                        <div className='d-flex flex-column mt-2 h-100'>
                                            {relatedDeals.map((deal, k) =>
                                                <div key={k} className='mt-2 py-2 px-3 w-100 bg-white shadow cursor-pointer'
                                                    style={{
                                                        borderRadius: '5px',
                                                        borderLeft: deal.status === 'WaitingHotel' ? 
                                                            '5px solid #0665ff' 
                                                        : deal.status === 'WaitingClient' ? 
                                                            '5px solid #d3d3d3' 
                                                        : 'none'
                                                    }}
                                                    onClick={_ => selectDealDetail(deal.id, "SalesPipelineStage")}
                                                    draggable={true}
                                                    onDragStart={(event) => this.onDragStart(event, deal.id, stage.value)}
                                                    onDragEnd={this.onDragEnd}
                                                >
                                                    <div>
                                                        <div className='d-flex align-items-start justify-content-between'>
                                                            <div className='text-truncate'>
                                                                <div className='text-truncate'>
                                                                    {deal.name}
                                                                </div>
                                                                {deal.customer.name ?
                                                                    <div style={{ marginTop: '-3px' }} className='text-truncate'>
                                                                        <span style={{ fontSize: '0.8em' }} className='text-muted hover-underline'>
                                                                            <Link to={{ pathname: "/ProfileDetails/" + deal.customer.id }}>
                                                                                {deal.customer.name}
                                                                            </Link>
                                                                        </span>
                                                                    </div>
                                                                :''}
                                                            </div>
                                                            <SalesActionsPopOver
                                                                id={`SalesAction-${key}-${k}`}
                                                                salesUsers={salesUsers}
                                                                placement={'right'}
                                                                deal={deal}
                                                                getDeals={getDeals}
                                                                selectedPipeline={selectedPipeline}
                                                            />
                                                        </div>
                                                        <div className='mt-1 d-flex align-items-center justify-content-between'>
                                                            <div style={{ maxWidth: '80%' }}>
                                                                {deal.hotelIds?.length ?
                                                                    <div style={{ fontSize: '0.8em' }} className='text-truncate text-muted d-flex align-items-center' id={`deal-hotelList-${deal.id}`}>
                                                                        <i className="icon-icon-hotel mr-1"/>
                                                                        {(() => {
                                                                            const hotel = global.hotelList?.find(({value}) => value === deal.hotelIds[0]);
                                                                            return (
                                                                                <div className='text-truncate'>
                                                                                    <div className='text-truncate'>{hotel?.label??''}</div>
                                                                                    <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="right" target={`deal-hotelList-${deal.id}`}>
                                                                                        <PopoverBody>
                                                                                            {deal.hotelIds.map((h, k) => {
                                                                                                const ho = global.hotelList.find(({value}) => value === h);
                                                                                                return(
                                                                                                    <div key={k}>{ho?.label??''}</div>
                                                                                                );
                                                                                            })}
                                                                                        </PopoverBody>
                                                                                    </CustomToolTip>
                                                                                </div>
                                                                            );
                                                                        })()}
                                                                    </div>
                                                                :''}
                                                            </div>
                                                            <div>
                                                                <b><FormatAmountNumber value={deal.expectedValue || 0}/></b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            )}
                        </div>
                    </SalesProcessDefaultPage>
                </BlockUi>
            </div>
        )
    }
}

export default injectIntl(SalesPipelineStage);