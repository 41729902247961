import React, { Component } from 'react';
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import { Button, Card, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { KebabMenu } from '../Base/CommonUIComponents';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import { deleteAPI, getAPI, postAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import SalesProcessUserModal from './SalesProcessUserModal';
import microsoft365 from '../../img/Microsoft365.png';
import SalesProcessDefaultPage from './SalesProcessDefaultPage';

class SalesDealUsers extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            adalUsersOptions: [],
            selectedUser: null,
            deleteModal: false,
            modal: false,
            salesUsers: [],
            blockModal: false,
            errorModal: null,
            blockAdal: false,
            hasOffice365: global?.modules?.some(m => m === 'MicrosoftOffice365')
        };
    }
    
    createUser = (e) => {
        e.preventDefault();
        this.setState({ blockModal: true }, () => {
            const { selectedUser } = this.state;
            postAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ errorModal: errorMessage, blockModal: false });
    
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
    
                    handleNotification(data, <FormattedMessage id="SalesDeal.UserCreated" />, <FormattedMessage id="generic.success" />);
                    this.setState({ modal: false, blockModal: false }, () => this.props.getUsers());
                }
            }, '/api/gms/SalesProcess/v1/salesprocessusers', selectedUser);
        });
    }
    
    logInOutUserExternal = () => {
        this.setState({ blockModal: true }, () => {
            const { selectedUser, hasOffice365 } = this.state;
            if(hasOffice365){
                if(selectedUser.hasExternalAccess)
                    this.logoutOffice(selectedUser.id);
                else
                    this.getOfficeURL(selectedUser.id);
            }
        });
    }

    logoutOffice = (id) => {
        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorModal: errorMessage, blockModal: false });

                return;
            }
            if (data && data.response && data.response[0]) {
                this.setState({ blockModal: false }, () => window.open(data.response[0], '_blank').focus());
            }
        }, `/api/gms/SalesProcess/v1/m365/${id}/logout`);
    }

    getOfficeURL = (id) => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorModal: errorMessage, blockModal: false });

                return;
            }
            if (data && data.response && data.response[0]) {
                this.setState({ blockModal: false }, () => window.open(data.response[0], '_blank').focus());
            }
        }, `/api/gms/SalesProcess/v1/m365/${id}/loginurl`);
    }
    
    deleteUser = (id) => {
        this.setState({ block: true }, () =>
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        this.setState({ deleteModal: false, block: false }, () =>
                            handleNotification(data)
                        );
                        return;
                    }
                    else {
                        this.setState({ deleteModal: false, block: false }, () => {
                            handleNotification(data, <FormattedMessage id="SalesDeal.UserRemoved" />, <FormattedMessage id="generic.success" />);
                            this.props.getUsers();
                        });
                        return;
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/salesprocessusers/${id}`)
        );
    }

    handleUserChange = (e) => {
        const { selectedUser } = this.state;
        const { name, value } = e?.target;

        this.setState({ selectedUser: {...selectedUser, [name]: value} });
    }

    handleComboChange = (combo, name) => {
        const { selectedUser } = this.state;

        if(combo?.value){
            if(!selectedUser.email)
                selectedUser.email = combo.email;
            if(!selectedUser.firstName)
                selectedUser.firstName = combo.firstName;
            if(!selectedUser.lastName)
                selectedUser.lastName = combo.lastName;
        }
        
        this.setState({ selectedUser: {...selectedUser, [name]: combo?.value} });
    }

    getAdalUsers = () => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockAdal: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({
                    adalUsersOptions: data.users?.map(adUser => ({
                        label: (
                            <div>
                                <div>
                                    {`${adUser.name} ${adUser.surname}`}
                                </div>
                                <div className='title-sm'>
                                    {adUser.email}
                                </div>
                            </div>
                        ),
                        value: adUser.id,
                        firstName: adUser.name,
                        lastName: adUser.surname,
                        email: adUser.email
                    })),
                    blockAdal: false
                });
            }
        }, '/api/User/v1/User/getUsers?accessGroup=e02b5dfc-ee17-4dbd-aeba-699ab14ad35c');
    }

    toggleModal = (user) => {
        const { modal, adalUsersOptions } = this.state;
        this.setState({ modal: !modal, selectedUser: user ?? {}, blockAdal: !modal && adalUsersOptions.length === 0 }, () => {
            if(!modal && adalUsersOptions.length === 0)
                this.getAdalUsers();
        });
    }

    toggleDeleteModal = (selectedUser) => {
        this.setState(prevState => ({ deleteModal: !prevState.deleteModal, selectedUser }));
    }

    render() {
        const { block, error, selectedUser, deleteModal, modal, adalUsersOptions, blockModal, blockAdal, errorModal, hasOffice365 } = this.state;
        const { salesUsers, blockUsers } = this.props;

        return (
            <div className="border-0 h-100 pb-2">
                <ErrorAlert error={error}/>
                <BlockUi tag="div" blocking={blockUsers || block}>
                    <div className='mb-4'>
                        <Row>
                            <Col>
                                <h4 className='m-0'>
                                    <i className={`${this.props.icon} mr-2`} />
                                    <FormattedMessage id="SalesDeal.SalesProcessUser" />
                                </h4>
                            </Col>
                            <Col className='col-2 text-right'>
                                <Button className="btn-sm btn-host mr-2" onClick={_ => this.toggleModal()}>
                                    <i className="fas fa-plus" />
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <SalesProcessDefaultPage
                        mainValue={salesUsers?.length}
                        noValueText={"SalesProcess.NoUsers"}
                        icon={this.props.icon}
                    >
                        <Row className='mt-2'>
                            {salesUsers?.map((user, k) =>
                                <Col className='col-4 mb-3' key={k}>
                                    <Card body className='p-3 h-100'>
                                        <Row>
                                            <Col onClick={_ => this.toggleModal(user)} className='cursor-pointer'>
                                                <div className='d-flex align-items-center'>
                                                    <b>{`${user.firstName} ${user.lastName}`}</b>
                                                    {hasOffice365 && user.hasExternalAccess ?
                                                        <div>
                                                            <img alt="" src={microsoft365} height="20px"/>
                                                        </div>
                                                    :''}
                                                </div>
                                                <div className='text-muted title-sm'>
                                                    {user.email}
                                                </div>
                                            </Col>
                                            <Col className='col-1 d-flex align-items-center justify-content-center'>
                                                <KebabMenu
                                                    deleteFunction={_ => this.toggleDeleteModal(user)}
                                                />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            )}
                        </Row>
                    </SalesProcessDefaultPage>

                    {modal ?
                        <SalesProcessUserModal
                            toggleModal={this.toggleModal}
                            salesUsers={salesUsers}
                            block={blockModal}
                            blockAdal={blockAdal}
                            user={selectedUser}
                            handleUserChange={this.handleUserChange}
                            handleComboChange={this.handleComboChange}
                            createUser={this.createUser}
                            adalUsersOptions={adalUsersOptions}
                            errorModal={errorModal}
                            logInOutUserExternal={this.logInOutUserExternal}
                            hasOffice365={hasOffice365}
                        />
                    :''}

                    {deleteModal ?
                        <ConfirmActionModal
                            modal={deleteModal}
                            toggleModal={_ =>this.toggleDeleteModal()}
                            actionFunction={_ => this.deleteUser(selectedUser.id)}
                            text={<FormattedMessage id="SalesDeal.ConfirmDeleteUser" values={{ Name: `${selectedUser.firstName} ${selectedUser.lastName}` }} />}
                            block={block}
                        />
                    :''}
                </BlockUi>
            </div>
        );
    }
}

export default SalesDealUsers;