import React, { Component } from 'react'
import { Button, Card, Input, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment-timezone';
import SalesActionModal from './SalesActionModal';
import SalesDealModal from './SalesDealModal/SalesDealModal';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import { putAPI, postAPI, getAPI, deleteAPI, getAPIBlob } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import SalesDealDetailProposal from './SalesDealDetailProposal';
import SalesDealDetailOverview from './SalesDealDetailOverview';
import SalesDealDetailActions from './SalesDealDetailActions';
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import ImportGroupResModal from './ImportGroupResModal';
import ProposalHistoryModal from './ProposalHistoryModal';
import { SelectCustomType } from './SalesProcessFunctions';
import { ContentState, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import AssociateDeal from './SalesDealModal/AssociateDeal';
import ChangeAssociationTypeModal from './SalesDealModal/ChangeAssociationTypeModal';

class SalesDealDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            error: null,
            deal: null,
            dealModal: false,
            confirmActionModal: false,
            specificEditStep: null,
            actionTypesOptions: [
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Email` }), icon: 'far fa-envelope', value: 'Email' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Call` }), icon: 'fas fa-phone', value: 'Call' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Note` }), icon: 'far fa-sticky-note', value: 'Note' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.File` }), icon: 'fas fa-file', value: 'File' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Meeting` }), icon: 'far fa-building', value: 'Meeting' }
            ],
            actionStatusOptions: [
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Scheduled` }), value: 'Scheduled' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Cancelled` }), value: 'Cancelled' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.OnGoing` }), value: 'OnGoing' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Finished` }), value: 'Finished' }
            ],
            newAction: {
                type: "Note",
                title: this.props.intl.formatMessage({ id: `SalesProcess.NewNote` }),
                summary: this.props.intl.formatMessage({ id: `SalesProcess.NewNoteSummary` }),
                content: this.props.intl.formatMessage({ id: `SalesProcess.NewNoteContent` }),
                status: "Scheduled",
                createdAt: moment().format('YYYY/MM/DD'),
                startDate: moment().format('YYYY/MM/DD')
            },
            proposalDetailsCards: [
                { icon: 'icon-icon-hotel', label: <FormattedMessage id="SalesDeal.Hotels" />, value: 0 },
                { icon: 'fas fa-calendar-day', label: <FormattedMessage id="SalesDeal.Nights" />, value: 0 },
                { icon: 'fas fa-bed', label: <FormattedMessage id="SalesDeal.RoomNights" />, value: 0 },
                { icon: 'fas fa-theater-masks', label: <FormattedMessage id="SalesDeal.Events" />, value: 0 },
                { icon: 'fas fa-male', label: <FormattedMessage id="SalesDeal.TotalPax" />, value: 0 },
                { icon: 'fas fa-store-alt', label: <FormattedMessage id="SalesDeal.Spaces" />, value: 0 },
                { icon: 'fas fa-microphone', label: <FormattedMessage id="SalesDeal.Equipment" />, value: 0 },
                { icon: 'fas fa-dollar-sign', label: <FormattedMessage id="SalesDeal.TotalValue" />, value: 0, isAmount: true }
            ],
            activeActionTab: 'Note',
            actionModal: false,
            dealAssociationModal: false,
            associatedDeals: [],
            deleteDealAssociationModal: false, 
            selectedDealRelationId: null,
            selectedDealRelationTypeId: null,
            changeAssociationTypeModal: false,
            selectedDealId: null,
            statusReason: "",
            activeTab: 'Overview',
            customers: [],
            proposalCollapse: {},
            importGroupResModal: false,
            proposalHistoryModal: false,
            dealProposals: null,
            roles: [],
            isLead: true
        };
    }

    componentDidMount(){
        this.getDealDetail();
    }

    getDealDetail = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false }, () => this.getRoles());
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.getRoles();
                }
                const deal = data.data && data.data[0];

                const blocksFromHtml = htmlToDraft(deal.summary);
                if (blocksFromHtml) {
                    const { contentBlocks, entityMap } = blocksFromHtml;

                    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

                    deal.editorStateHtml = EditorState.createWithContent(contentState);
                }
                this.initialCalc(deal);
                return;
            }
            else this.setState({ error: errorMessage, block: false }, () => this.getRoles());
        }, `/api/gms/SalesProcess/v1/deal/${this.props.selectedDealId}/overview`);
    }

    getDealProposals = () => {
        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    var proposals = [];
                    
                    if (data.data?.length > 0) {
                        data.data.forEach(p => {
                            const json = JSON.parse(p.proposalJSON);

                            if (json.data[0].DetailsRooms?.length > 0) json.data[0].DetailsRooms = json.data[0].DetailsRooms.map(dr => ({ ...dr, isVisible: true }));
                            if (json.data[0].DetailsEvents?.length > 0) json.data[0].DetailsEvents = json.data[0].DetailsEvents.map(dr => ({ ...dr, isVisible: true }));


                            if (json.BlockData) {
                                const jsonBlock = JSON.parse(JSON.stringify(json.BlockData));
                                jsonBlock.details = jsonBlock.details.map(dr => ({ ...dr, isVisible: true }));

                                json.BlockData = jsonBlock;

                                json.groupedCategories = jsonBlock.details.reduce((acc, curr) => {
                                    const group = acc.find((group) => group.categoryId === curr.CategoryId);
                                    const roomNights = curr.CurrentRooms || 0;
                                    const totalValue = roomNights * curr.PriceAfterTaxPerRoom_Total;

                                    if (group) {
                                        group.totalRooms += roomNights;
                                        group.priceTotal += totalValue;
                                    } else {
                                        acc.push({
                                            categoryId: curr.CategoryId,
                                            categoryCode: curr.CategoryCode,
                                            totalRooms: roomNights,
                                            priceTotal: totalValue
                                        });
                                    }
                                    return acc;
                                }, []);
                            }

                            p.isVisible = true;
                            p.proposalJSON = json;
                            
                            proposals.push(p);
                        });
                    }

                    this.setState({ dealProposals: proposals, block: false });
                }
                else this.setState({ dealProposals: [], error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/deal/${this.props.selectedDealId}/proposals`);
        })
    }

    initialCalc = (deal) => {
        const customers = [];
        const associatedDeals = [];

        if(deal.customer){
            customers.push({
                value: deal.customer.id,
                label: deal.customer.name,
                ...deal.customer
            });
        }
        if(deal.mainContact){
            customers.push({
                value: deal.mainContact.id,
                label: deal.mainContact.name,
                ...deal.mainContact
            });
        }
        if(deal.salesDealProfile){
            deal.salesDealProfile.forEach(p => {
                customers.push({
                    value: p.profileId,
                    label: p.name,
                    ...p
                });
            });
        }

        if(deal?.relatedDeals){
            deal.relatedDeals.forEach(d => {
                if(d.relationName && d.relationId !== null && d.relationId !== undefined){
                    const associationExists = associatedDeals.find(ad => ad.relationId === d.relationId)
                    if(!associationExists){
                        associatedDeals.push({
                            relationId: d.relationId,
                            relationName: d.relationName,
                            deals: [d]
                        });
                    }else{
                        associatedDeals.find(ad => ad.relationId === d.relationId).deals.push(d);
                    }
                }
            });
        }

        const isLead = !deal?.salesPipelineStageId;

        this.setState({ customers, isLead, deal, block: false, associatedDeals }, () => this.getRoles());
    }

    changeDealStatus = (e, status) => {
        e.preventDefault();
        if (status) {
            this.setState({ block: true }, () => {
                putAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];
                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if (data.errors && data.errors.length > 0) {
                            handleNotification(data);
                        }
                        if (data.data && data.data.length > 0) {
                            handleNotification(data, <FormattedMessage id="SalesDeal.StatusChanged" />, <FormattedMessage id="generic.success" />);

                            const { deal } = this.state;

                            if (!deal.salesDealActions) deal.salesDealActions = [];

                            deal.salesDealActions.unshift(data.data[0].salesDealActions[0]);
                            deal.statusReason = data.data[0].statusReason;
                            deal.status = status;

                            this.toggleConfirmActionModal();
                            this.setState({ deal, error: errorMessage, block: false });

                            return;
                        }   
                    }
                    this.toggleConfirmActionModal();
                    this.setState({ error: errorMessage, block: false });
                }, `/api/gms/SalesProcess/v1/deals/${this.state.deal.id}/status/${status}`, this.state.statusReason);
            });
        }        
    }

    updateActionStatus = (e, id, status, cb) => {
        this.setState({ block: true }, () => {
            const { deal } = this.state;

            putAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
                    
                    if(cb){
                        cb();
                    }
            
                    const fIndex = deal.salesDealFocusedActions.findIndex(el => el.id === id);

                    if (fIndex !== -1) {
                        if(data.data && data.data.length > 0){
                            if (status === 'OnGoing') {
                                const action = data.data[0];
                                deal.salesDealFocusedActions[fIndex] = action;
                            } else {
                                deal.salesDealFocusedActions.splice(fIndex, 1);
                            }
                        }else{
                            deal.salesDealFocusedActions.splice(fIndex, 1);
                        }
                    }

                    this.setState({
                        block: false,
                        confirmActionModal: false,
                        selectedAction: null,
                        changeToStatus: null,
                        deal
                    }, () => this.getDealDetail());
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/deals/action/${id}/status/${status}`);
        })
    }

    toggleEdit = (specificEditStep) => {
        this.setState({ dealModal: !this.state.dealModal, specificEditStep: isNaN(specificEditStep) ? null : specificEditStep });
    }

    toggleActionModal = (action) => {
        this.setState({
            actionModal: !this.state.actionModal,
            selectedAction: action
        })
    }

    toggleDealAssociationModal = () => {
        this.setState({ dealAssociationModal: !this.state.dealAssociationModal });
    }

    toggleConfirmActionModal = (action, status) => {
        this.setState({
            confirmActionModal: !this.state.confirmActionModal,
            selectedAction: action,
            changeToStatus: status
        })
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'cancelReasonType') {
            this.setState({
                deal: {
                    ...this.state.deal,
                    cancelReason: value ? value.label : null,
                    cancelReasonId: value ? value.value : null
                }
            })
            return;
        }

        this.setState({
            [name]: value
        })
    }

    toggleTab = (tab) => {
        if(tab !== this.state.activeTab)
            this.setState({ activeTab: tab });
    }

    updateDeal = (deal, updateCustomers) => {
        if (updateCustomers) {
            this.getDealDetail();
            return;
        }
        this.setState({ deal });
    }

    downloadAction = (e, action) => {
        if (e) e.preventDefault();

        this.setState({ block: true }, () => {
            getAPIBlob(result => {
                const { data, error, headers } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
                    if (data) {
                        const a = document.createElement("a");
                        //a.href = `${data.response[0].contentType}${data.response[0].fileBase64}`;
                        var blob = new File([data], { type: headers["content-type"] });
                        var url = window.URL.createObjectURL(blob);
                        a.href = url;
                        var f = headers["content-disposition"].match("filename=([^;]*);")
                        a.download = f != null ? f[1] : "file";
                        a.click();

                        this.setState({ block: false });
                        return;
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/deals/action/${action.id}/file`);
        });
    }

    changeActionPin = (e, action) => {
        if(e) e.preventDefault();

        if(action){
            action.pinned = !action.pinned;
            this.setState({ block: true }, () => {
                const { deal } = this.state;

                postAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];

                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if (data && data.errors && data.errors.length > 0) {
                            handleNotification(data);
                            this.setState({ block: false });
                            return;
                        }

                        if (deal) {
                            if (!deal.salesDealActions)
                                deal.salesDealActions = [];
                            
                            if(action.pinned){ // if pinned == true add to focused actions and remove from action list
                                deal.salesDealFocusedActions.unshift(action);
                                
                                const actionIndex = deal.salesDealActions.findIndex(el => el.id === action.id);

                                if (actionIndex !== -1)    
                                    deal.salesDealActions.splice(actionIndex, 1);
                            }
                            else{ // if pinned == false remove from focused actions and update action list

                                const actionIndex = deal.salesDealActions.findIndex(el => el.id === action.id);

                                if (actionIndex !== -1)    
                                    deal.salesDealActions[actionIndex] = action;

                                const fIndex = deal.salesDealFocusedActions.findIndex(el => el.id === action.id);
                                
                                if (fIndex !== -1)
                                    deal.salesDealFocusedActions.splice(fIndex, 1);
                            }

                            this.setState({
                                deal,
                                block: false
                            }, () => handleNotification(data, <FormattedMessage id="SalesDeal.ActionSavedSuccessfully" />, <FormattedMessage id="generic.success" />));
                        }else{
                            this.setState({ block: false });
                        }
                        return;
                    }
                    else this.setState({ error: errorMessage, block: false });
                }, `/api/gms/SalesProcess/v1/deals/action`, { ...action });
            })
        }
    }

    toggleTabandModal = () => {
        this.setState({ activeTab: 'Proposal', importGroupResModal: true });
    }

    toggleImportGroupResModal = () => {
        this.setState({ importGroupResModal: !this.state.importGroupResModal });
    }
    
    importGroupRes = (selectedGroupResId, hotelId, proposalId) => {
        this.setState({ block: true }, () => {
            const { deal } = this.state;

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    handleNotification(data, <FormattedMessage id="SalesDeal.ReservationImported" />, <FormattedMessage id="generic.success" />);

                    this.setState({ block: false }, () => {
                        if (!proposalId) {
                            this.toggleImportGroupResModal();
                        }

                        this.getDealProposals();
                    });

                    return;
                }
                else
                    this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/Proposal/Import?externalId=${selectedGroupResId}&hotelId=${hotelId}&salesDealId=${deal.id}${proposalId ? `&salesDealProposalId=${proposalId}` : ''}`);
        });
    }

    toggleProposalHistoryModal = () => {
        this.setState({ proposalHistoryModal: !this.state.proposalHistoryModal });
    }

    setProposalDetailsCards = (proposalDetailsCards) => {
        this.setState({ proposalDetailsCards });
    }

    getRoles = () => {
        this.setState({ block: true }, () =>
            getAPI((result) => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({
                        message: error.message,
                        stack: error.stack,
                        messageType: "danger",
                    });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.data && data.data.length > 0) {
                        this.setState({ roles: data.data, block: false });
                        return;
                    }else if(data.errors && data.errors.length > 0){
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/customtypes?type=DealProfileRole`)
        );
    }

    associateDeal = (e, dealAId, dealBId, relationId, salesDealRelationId) => {
        if(e) e.preventDefault();
        const body = {
            dealAId,
            dealBId,
            relationId,
            id: salesDealRelationId ?? null
        }

        this.setState({ block: true }, () => {
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    if(data.data && data.data.length > 0){
                        this.setState({ 
                            dealAssociationModal: false, 
                            changeAssociationTypeModal: false, 
                            selectedDealRelationTypeId: null, 
                            selectedDealRelationId: null, 
                            selectedDealId: null 
                        }, () => this.getDealDetail());
                        return;
                    }

                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/dealrelation`, body);
        });
    }

    deleteDealAssociation = (salesDealRelationId) => {
        this.setState({ block: true }, () => {
            const { deal } = this.state;

            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }else{
                        this.setState({ deleteDealAssociationModal: false, selectedDealRelationId: null }, () => this.getDealDetail());
                    }

                    this.setState({ deal, block: false });
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/dealrelation/${salesDealRelationId}`);
        });
    }

    toggleDeleteDealAssociationModal = (salesDealRelationId) => {
        this.setState({ deleteDealAssociationModal: !this.state.deleteDealAssociationModal, selectedDealRelationId: salesDealRelationId });
    }

    toggleChangeAssociationTypeModal = (selectedDealRelationTypeId, selectedDealRelationId, selectedDealId) => {
        this.setState({ changeAssociationTypeModal: !this.state.changeAssociationTypeModal, selectedDealRelationTypeId, selectedDealRelationId, selectedDealId });
    }

    render() {
        const { deal, block, error, selectedAction, confirmActionModal, actionModal, dealModal, changeToStatus, activeTab, customers, specificEditStep,
            importGroupResModal, proposalHistoryModal, roles, associatedDeals, dealAssociationModal, deleteDealAssociationModal, selectedDealRelationId, 
            changeAssociationTypeModal, selectedDealId, selectedDealRelationTypeId, isLead } = this.state;
        const { selectDealDetail, salesSources, salesUsers, salesPipelines, tempDeal, salesSourcesTypes, dealList, getDealList } = this.props;

        const selectedPipeline = salesPipelines?.find(({ stages }) => stages?.some(({ value }) => value === deal?.salesPipelineStage?.id));
        const source = salesSources?.find(s => s.value === deal?.salesSourceId)

        return (
            <>
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <Card className='border-0 shadow p-3 bg-white' style={{ minHeight: '90vh' }}>
                        {salesSources?.length && salesUsers?.length && salesPipelines?.length ?
                            <div>
                                {(() => {
                                    const dealData = deal ?? tempDeal;
                                    return (
                                        dealData ?
                                            <Row className='d-flex align-items-center justify-content-between'>
                                                <Col className={`d-flex align-items-${!isLead ? 'start' : 'center'} justify-content-start col-9`}>
                                                    <div onClick={_ => selectDealDetail()} className='p-3 cursor-pointer'>
                                                        <i className='fas fa-chevron-left'/>
                                                    </div>
                                                    <div className='ml-2'>
                                                        <div>
                                                            <h5 className='m-0'>{dealData.name}</h5>
                                                        </div>
                                                        {!isLead ?
                                                            <div className='mt-1'>
                                                                <span className='text-muted hover-underline'>
                                                                    <Link to={{ pathname: "/ProfileDetails/" + dealData.customer.id }}>
                                                                        {dealData.customer.name}
                                                                    </Link>
                                                                </span>
                                                            </div>
                                                        :''}
                                                    </div>
                                                </Col>
                                                <Col className='d-flex align-items-center'>
                                                    <div className='mr-4'>
                                                        <div className='title-sm mb-0'>
                                                            <FormattedMessage id="SalesDeal.Status"/>
                                                        </div>
                                                        <div>
                                                            {["WaitingClient", "WaitingHotel", "Finished"].includes(dealData.status) ?
                                                                <div>
                                                                    {isLead ?
                                                                        <FormattedMessage id={`SalesProcess.InNegotiation`}/>
                                                                    :
                                                                        <FormattedMessage id={`ReferenceDataFunctions.${dealData.status}`}/>
                                                                    }
                                                                </div>
                                                            :  dealData.status === "Effective" ?
                                                                <div>
                                                                    <FormattedMessage id="SalesProcess.Effective"/>
                                                                </div>
                                                            : dealData.status === "Cancelled" ?
                                                                <div>
                                                                    <FormattedMessage id="SalesProcess.Lost"/>
                                                                </div>
                                                            : '-'
                                                            }
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className='text-right'>
                                                    <div>
                                                        <Button onClick={_ => this.toggleConfirmActionModal(null, 'Finished')} className="btn btn-host btn-sm" style={{ background: '#36ad51', borderColor: '#36ad51', borderRadius: '0.2rem 0 0 0.2rem' }}>
                                                            {isLead ?
                                                                <FormattedMessage id="SalesDeal.FinnishLead"/>
                                                            :
                                                                <FormattedMessage id="SalesDeal.WinDeal"/>
                                                            }
                                                            <i className="fas fa-trophy ml-2"></i>
                                                        </Button>
                                                        <Button onClick={_ => this.setState({ statusOptions: !this.state.statusOptions })} className="btn btn-host btn-sm" id="StatusOptions" style={{ borderRadius: '0 0.2rem 0.2rem 0', background: '#bfbfbf', border: '1px solid #bfbfbf' }}>
                                                            <i className='fas fa-chevron-down'/>
                                                        </Button>
                                                        {this.state.statusOptions ?
                                                            <div style={{ borderRadius: '5px', zIndex: '1050', right: '0', width: 'max-content', fontSize: '0.95em' }} className='shadow bg-white mt-1 position-absolute'>
                                                                <div>
                                                                    {!isLead && dealData.status !== "WaitingHotel" ?
                                                                        <>
                                                                            <div className='changeStatusOption p-2 d-flex align-items-center justify-content-between cursor-pointer' onClick={_ => this.toggleConfirmActionModal(null, 'WaitingHotel')}>
                                                                                <i className="fas fa-users mr-2"/>
                                                                                <FormattedMessage id="ReferenceDataFunctions.WaitingHotel"/>
                                                                            </div>
                                                                        </>
                                                                    :''}
                                                                    {!isLead && dealData.status !== "WaitingClient" ?
                                                                        <>
                                                                            <div className='changeStatusOption p-2 d-flex align-items-center justify-content-between cursor-pointer' onClick={_ => this.toggleConfirmActionModal(null, 'WaitingClient')}>
                                                                                <i className="icon-icon-hotel mr-2"/> 
                                                                                <FormattedMessage id="ReferenceDataFunctions.WaitingClient"/>
                                                                            </div>
                                                                        </>
                                                                    :''}
                                                                    {dealData.status !== "Cancelled" ?
                                                                        <>
                                                                            <hr className='m-0'/>
                                                                            <div className='changeStatusOption p-2 d-flex align-items-center justify-content-between text-danger cursor-pointer' onClick={_ => this.toggleConfirmActionModal(null, 'Cancelled')}>
                                                                                <i className="fas fa-handshake-slash mr-2"/>
                                                                                {isLead ?
                                                                                    <FormattedMessage id="SalesDeal.CancelLead"/>
                                                                                :
                                                                                    <FormattedMessage id="SalesDeal.LoseDeal"/>
                                                                                }
                                                                            </div>
                                                                        </>
                                                                    :''}
                                                                </div>
                                                            </div>
                                                        :''}
                                                    </div>
                                                </Col>
                                            </Row>
                                        :''
                                    );
                                })()}
                                <Nav tabs className={`${isLead ? 'mt-2 mb-3' : 'my-3'}`}>
                                    <NavItem>
                                        <NavLink className={activeTab === 'Overview' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleTab('Overview')} style={{ cursor: "pointer" }}>
                                            <i className="fas fa-briefcase mr-2"/>
                                            <FormattedMessage id="SalesDeal.Overview"/>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={activeTab === 'Actions' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleTab('Actions')} style={{ cursor: "pointer" }}>
                                            <i className="fas fa-play mr-2"/>
                                            <FormattedMessage id="SalesDeal.Actions"/>
                                        </NavLink>
                                    </NavItem>
                                    {!isLead ?
                                        <NavItem>
                                            <NavLink className={activeTab === 'Proposal' ? 'tab-border-host text-host  border-0' : 'border-0'} onClick={() => this.toggleTab('Proposal')} style={{ cursor: "pointer" }}>
                                                <i className="fas fa-receipt mr-2"/>
                                                <FormattedMessage id="SalesDeal.Proposal"/>
                                            </NavLink>
                                        </NavItem>
                                    :''}
                                </Nav>
                                {deal ?
                                    <div>
                                        {activeTab === 'Overview' ?
                                            <SalesDealDetailOverview
                                                isLead={isLead}
                                                source={source}
                                                selectedPipeline={selectedPipeline}
                                                deal={deal}
                                                roles={roles}
                                                associatedDeals={associatedDeals}
                                                selectDealDetail={selectDealDetail}
                                                toggleDeleteDealAssociationModal={this.toggleDeleteDealAssociationModal}
                                                toggleDealAssociationModal={this.toggleDealAssociationModal}
                                                toggleChangeAssociationTypeModal={this.toggleChangeAssociationTypeModal}
                                                toggleActionModal={this.toggleActionModal}
                                                toggleEdit={this.toggleEdit}
                                                toggleConfirmActionModal={this.toggleConfirmActionModal}
                                                changeActionPin={this.changeActionPin}
                                                downloadAction={this.downloadAction}
                                                customers={customers}
                                                salesUsers={salesUsers}
                                                focusActions={deal?.salesDealFocusedActions}
                                                toggleTabandModal={this.toggleTabandModal}
                                            />
                                        : activeTab === 'Actions' ?
                                            <SalesDealDetailActions
                                                focusActions={deal?.salesDealFocusedActions}
                                                getDealActions={this.getDealActions}
                                                toggleActionModal={this.toggleActionModal}
                                                updateActionStatus={this.updateActionStatus}
                                                changeActionPin={this.changeActionPin}
                                                downloadAction={this.downloadAction}
                                                isLead={isLead}
                                                selectedPipeline={selectedPipeline}
                                                source={source}
                                                toggleEdit={this.toggleEdit}
                                                deal={deal}
                                                salesUsers={salesUsers}
                                                customers={customers}
                                            />
                                        : activeTab === 'Proposal' ?
                                            <SalesDealDetailProposal
                                                selectedPipeline={selectedPipeline}
                                                deal={deal}
                                                proposalDetailsCards={this.state.proposalDetailsCards}
                                                setProposalDetailsCards={this.setProposalDetailsCards}
                                                toggleImportGroupResModal={this.toggleImportGroupResModal}
                                                importGroupResModal={importGroupResModal}
                                                toggleProposalHistoryModal={this.toggleProposalHistoryModal}
                                                getDealProposals={this.getDealProposals}
                                                dealProposals={this.state.dealProposals}
                                                importGroupRes={this.importGroupRes}
                                            />
                                        :''}
                                    </div>
                                :''}
                            </div>
                        :''}
                    </Card>
                    
                    <>
                        {proposalHistoryModal ?
                            <ProposalHistoryModal
                                modal={true}
                                toggleModal={this.toggleProposalHistoryModal}
                                salesDealId={deal.id}
                            />
                        :''}

                        {importGroupResModal ?
                            <ImportGroupResModal
                                toggleModal={this.toggleImportGroupResModal}
                                modal={true}
                                salesDealId={deal.id}
                                hotels={global.hotelList.filter(({ value }) => deal?.hotelIds && deal.hotelIds.includes(value))}
                                importGroupRes={this.importGroupRes}
                                getDealProposals={this.getDealProposals}
                            />
                        :''}

                        {dealModal ?
                            <SalesDealModal
                                deal={deal}
                                modal={dealModal}
                                salesUsers={salesUsers}
                                apiRoles={roles}
                                customers={customers}
                                toggleModal={this.toggleEdit}
                                salesStages={selectedPipeline?.stages}
                                isLead={isLead}
                                salesSources={salesSources}
                                updateDeal={this.updateDeal}
                                salesPipelineId={selectedPipeline?.id}
                                salesPipelines={salesPipelines}
                                specificEditStep={specificEditStep}
                                isToEditDeal={true}
                            />
                        :''}

                        {actionModal ?
                            <SalesActionModal
                                customers={customers}
                                toggle={_ => this.toggleActionModal()}
                                salesUsers={salesUsers}
                                salesDealId={deal.id}
                                salesPipelineStageId={deal.salesPipelineStage?.id}
                                selectedAction={selectedAction || null}
                            />
                        :''}

                        {dealAssociationModal ?
                            <AssociateDeal 
                                block={block}
                                error={error}
                                modal={dealAssociationModal}
                                salesUsers={salesUsers}
                                salesPipelines={salesPipelines}
                                salesSources={salesSources}
                                salesSourcesTypes={salesSourcesTypes}
                                dealList={dealList ? dealList.filter(({ id }) => id !== deal.id) : []}
                                getDealList={getDealList}
                                currentDeal={deal}
                                toggleModal={this.toggleDealAssociationModal}
                                associateDeal={this.associateDeal}
                            />
                        :''}

                        {changeAssociationTypeModal ?
                            <ChangeAssociationTypeModal
                                block={block}
                                error={error}
                                modal={changeAssociationTypeModal}
                                dealAId={deal?.id} 
                                dealBId={selectedDealId} 
                                relationId={selectedDealRelationId} 
                                selectedAssociationTypeId={selectedDealRelationTypeId}
                                toggleModal={this.toggleChangeAssociationTypeModal}
                                associateDeal={this.associateDeal} 
                            />
                        :''}

                        {confirmActionModal ?
                            <ConfirmActionModal
                                modal={confirmActionModal}
                                toggleModal={() => this.toggleConfirmActionModal()}
                                actionFunction={(e) => selectedAction ? this.updateActionStatus(e, selectedAction.id, changeToStatus) : this.changeDealStatus(e, changeToStatus)}
                                text={selectedAction ? 
                                    changeToStatus === 'OnGoing' ?
                                        <FormattedMessage id="SalesDeal.ConfirmOnGoingText" values={{ action: selectedAction.title }} />
                                    :
                                    changeToStatus === 'Finished' ?
                                        <FormattedMessage id="SalesDeal.ConfirmFinishedText" values={{ action: selectedAction.title }} />
                                    :
                                        <FormattedMessage id="SalesDeal.ConfirmDeleteText" values={{ action: selectedAction.title }} /> 
                                : 
                                    <FormattedMessage id={`SalesDeal.ConfirmChangeStatusTo${changeToStatus}`} />
                                }
                                block={block}
                            >
                                {changeToStatus === 'Cancelled' ?
                                    <>
                                        <div className="px-2 mb-2">
                                            <SelectCustomType
                                                name="cancelReasonType"
                                                onChangeFunc={(combo) => this.handleChange({ target: { name: 'cancelReasonType', value: combo ?? null } })}
                                                required={true}
                                                value={deal.cancelReasonId}
                                                placeholder=""
                                                isDisabled={false}
                                                isClearable={true}
                                                type={'DealCancelReasonType'}
                                                bigCol={true}
                                            />
                                        </div>
                                        <div className="px-4 mb-4">
                                            <div className="title-sm"> <FormattedMessage id="SalesDeal.CancellationReason" /></div>
                                            <Input
                                                required
                                                type="textarea"
                                                name="statusReason"
                                                placeholder=""
                                                onChange={this.handleChange}
                                                maxLength={100}
                                            />
                                        </div>
                                    </>
                                : ''}
                            </ConfirmActionModal>
                        :''}

                        {deleteDealAssociationModal ?
                            <ConfirmActionModal
                                modal={deleteDealAssociationModal}
                                toggleModal={this.toggleDeleteDealAssociationModal}
                                actionFunction={() => this.deleteDealAssociation(selectedDealRelationId)}
                                text={<FormattedMessage id="SalesProcess.DeleteRelationText" />}
                                block={block}
                            />
                        :''}
                    </>
                </BlockUi>
            </>
        )
    }
}

export default injectIntl(SalesDealDetail);