import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Card, Col, Nav, NavItem, NavLink, Row, UncontrolledPopover, PopoverBody, Label } from 'reactstrap';
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import SalesDealProposalRooms from './SalesDealProposalRooms';
import SalesDealProposalEvents from './SalesDealProposalEvents';
import SalesDealProposalStats from './SalesDealProposalStats';
import SalesDealProposalCalendar from './SalesDealProposalCalendar';
import { deleteAPI, getAPIBlob, getAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import SalesDealProposalResumeBar from './SalesDealProposalResumeBar';
import SalesDealProposalResume from './SalesDealProposalResume';
import moment from 'moment';
import { FormatAmountNumber } from '../Base/CommonUIComponents';
import CustomToolTip from '../Base/CustomToolTip';

import { BlankCard, StyledModal } from '../Base/CommonUIComponents';
import CustomSelect from '../Base/CustomSelect';


class SalesDealDetailProposal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            errorModal: null,
            proposals: this.props.dealProposals || [],
            filters: [],
            activeTab: 'Stats',
            importGroupResModal: false,
            proposalHistoryModal: false,
            downloadProFormaModal: false
        };
    }

    componentDidMount() {
        if (this.props.dealProposals === null) {
            this.props.getDealProposals();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.dealProposals?.length !== this.props.dealProposals?.length) {
            this.calculateProposalsValues(nextProps.dealProposals);
        }
    }

    calculateProposalsValues = (proposals) => {
        const filters = [...this.state.filters];
        const proposalDetailsCards = [...this.props.proposalDetailsCards];
        const hotels = [];
        var nrNights = 0, nrRooms = 0, nrEvents = 0, nrGuests = 0, nrSpaces = 0, nrEquipement = 0, totalValue = 0;

        proposals.forEach(proposal => {
            if (filters.length > 0) {
                if (proposal.isVisible) {
                    var minCheckIn = null;
                    var maxCheckOut = null;
                    
                    if (!hotels.some(el => el === proposal.hotelId)) {
                        hotels.push(proposal.hotelId);
                    }

                    
                    proposal.proposalJSON.BlockData && proposal.proposalJSON.BlockData.details && proposal.proposalJSON.BlockData.details.forEach(blockDetail => {
                        if (blockDetail.isVisible) {
                            nrRooms += blockDetail.CurrentRooms || 0;
                            totalValue += (blockDetail.CurrentRooms * blockDetail.PriceAfterTaxPerRoom_Total) || 0;
                        }
                    })

                    proposal.proposalJSON.data[0].DetailsRooms && proposal.proposalJSON.data[0].DetailsRooms.forEach(room => {
                        if (room.isVisible) {
                            nrGuests += room.Adults + room.Children1 + room.Children2 + room.Children3;

                            if (!minCheckIn || moment(room.Checkin).isBefore(minCheckIn)) minCheckIn = moment(room.Checkin);
                            if (!maxCheckOut || moment(room.Checkout).isAfter(maxCheckOut)) maxCheckOut = moment(room.Checkout);
                        }
                    })

                    proposal.proposalJSON.data[0].DetailsEvents && proposal.proposalJSON.data[0].DetailsEvents.forEach(event => {
                        if (event.isVisible) {
                            nrEvents++;
                            nrSpaces += event.Spaces?.length || 0;
                            nrEquipement += event.PriceDetailsNonSpace?.length || 0;
                            totalValue += event.PriceTotal || 0;
                        }
                    })


                    if (minCheckIn && maxCheckOut) {
                        nrNights += maxCheckOut.diff(minCheckIn, 'days');
                    }
                }
            }
            else {
                nrNights += proposal.numberOfNights || 0;
                nrRooms += proposal.numberOfRooms || 0;
                nrEvents += proposal.numberOfEvents || 0;
                nrGuests += (proposal.adults || 0) + (proposal.children || 0);
                nrSpaces += proposal.numberOfSpaces || 0;
                nrEquipement += proposal.numberOfEquipment || 0;
                totalValue += proposal.totalValue || 0;

                //Hotels
                if (!hotels.some(el => el === proposal.hotelId)) {
                    hotels.push(proposal.hotelId);
                }
            }
        })


        proposalDetailsCards[0].value = hotels.length;
        proposalDetailsCards[1].value = nrNights || 0;
        proposalDetailsCards[2].value = nrRooms || 0; //Room Nights
        proposalDetailsCards[3].value = nrEvents || 0;
        proposalDetailsCards[4].value = nrGuests || 0;
        proposalDetailsCards[5].value = nrSpaces || 0;
        proposalDetailsCards[6].value = nrEquipement || 0;
        proposalDetailsCards[7].value = totalValue || 0;


        this.setState({ proposalDetailsCards, proposals }, () => this.props.setProposalDetailsCards(proposalDetailsCards));
    }


    setFilters = (objectId, objectLabel, objectType, parentId) => {
        const filters = [...this.state.filters];

        const idx = filters.findIndex((f)  => f.objectId === objectId && f.objectType === objectType && f.parentId === parentId);
        
        if(idx >= 0){
            filters.splice(idx, 1);
        }
        else{
            filters.push({
                objectId,
                objectLabel,
                objectType,
                parentId
            });
        }

        this.updateProposals(filters);
    }

    updateProposals = (filters) => {
        const proposals = [...this.state.proposals];
        const hasFilters = filters.length > 0;
        const reservationFilters = filters.filter(({ objectType }) => objectType === 'Reservation') || null;
        const hotelFilters = filters.filter(({ objectType }) => objectType === 'Hotel');
        const roomsFilters = filters.filter(({ objectType }) => objectType === 'Rooms');
        const eventsFilters = filters.filter(({ objectType }) => objectType === 'Events');



        proposals.forEach(proposal => {
            const hasRoomsInFilter = roomsFilters.some(({ parentId }) => parentId === proposal.externalId);
            const hasEventsInFilter = eventsFilters.some(({ parentId }) => parentId === proposal.externalId);

            const showHotel = hotelFilters.some(rf => rf.objectId === proposal.hotelId);
            const showReservation = reservationFilters.some(rf => rf.objectId === proposal.externalId);

            
            proposal.isVisible = hasFilters ? showHotel || showReservation || hasRoomsInFilter || hasEventsInFilter : true;
            
            proposal.proposalJSON.BlockData && proposal.proposalJSON.BlockData.details && proposal.proposalJSON.BlockData.details.forEach(blockDetails => {
                blockDetails.isVisible = hasFilters ? hasRoomsInFilter || hasEventsInFilter ? roomsFilters.some(({ objectId }) => !objectId || objectId === blockDetails.CategoryId) : (showHotel || showReservation) : true;
            })

            proposal.proposalJSON.data[0].DetailsRooms && proposal.proposalJSON.data[0].DetailsRooms.forEach(room => {
                room.isVisible = hasFilters ? hasRoomsInFilter || hasEventsInFilter ? roomsFilters.some(({ objectId }) => !objectId || objectId === room.CategoryId) : (showHotel || showReservation) : true;;
            })
            
            proposal.proposalJSON.data[0].DetailsEvents && proposal.proposalJSON.data[0].DetailsEvents.forEach(event => {
                event.isVisible = hasFilters ? hasEventsInFilter || hasRoomsInFilter ? eventsFilters.some(({ objectId }) => !objectId || objectId === event.EventName) : (showHotel || showReservation) : true;
            })
        })

        this.setState({ proposals, filters }, _ => this.calculateProposalsValues(proposals));
    }

    toggleTab = (tab) => {
        this.setState({ activeTab: tab });
    }

    removeGroupReservation = (proposalId) => {
        if (!proposalId)
            return;

        this.setState({ block: true }, () =>
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    handleNotification(data, <FormattedMessage id="SalesDeal.RemovedGroupReservation" />, <FormattedMessage id="generic.success" />);
                    
                    this.setState({ block: false }, this.props.getDealProposals);
                    return;
                }
                else
                    this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/Proposal/${proposalId}`)
        );
    }

    downloadProForma = (id) => {
        const { deal } = this.props;
        this.setState({ block: true }, () =>
            getAPIBlob(result => {
                const { data, error, headers } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ errorModal: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    const blob = new Blob([data], { type: headers["content-type"] });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    var f = headers["content-disposition"] ? headers["content-disposition"].match("filename=([^;]*);") : [deal.name + '.pdf'];
                    a.download = f[1];
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);

                    this.setState({ block: false });
                    return;
                }
                else
                    this.setState({ errorModal: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/communication/generateproforma?configId=${id}&salesDealId=${deal.id}`)
        );
    }


    hasFilter = (id, type, parent) => {
        return [...this.state.filters].some(({ objectId, objectType, parentId }) => objectId === id && objectType === type && parentId === parent)
    }

    toggleDownloadProFormaModal = () => {
        this.setState({ downloadProFormaModal: !this.state.downloadProFormaModal });
    }

    handleChangeSelect = (combo) => {
        this.setState({
            configId: combo.value
        })
    }

    getConfigList = () => {
        const { deal } = this.props;
        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    const list = data.data;

                    const matchingConfigs = list.filter(config =>
                        config.hotelIds.some(hotelId => deal.hotelIds.includes(hotelId))
                    ).map(x => ({ value: x.id, label: x.name }));

                    if (matchingConfigs.length == 0) {
                        data.errors.push({code: "404", message: "There is no config associated"})
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    this.setState({ configs: matchingConfigs, configId: matchingConfigs[0].value, block: false }, matchingConfigs.length > 1 ? this.toggleDownloadProFormaModal : () => this.downloadProForma(matchingConfigs[0].value));
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/communication/configs`)
        });
    }

    render() {
        const { selectedPipeline, deal, toggleImportGroupResModal, toggleProposalHistoryModal, importGroupRes, proposalDetailsCards } = this.props;
        const { block, error, proposals, filters, activeTab, downloadProFormaModal, configs, configId, errorModal } = this.state;

        return (
            <>
                {downloadProFormaModal ?
                    <StyledModal toggleModal={this.toggleDownloadProFormaModal} modal={downloadProFormaModal} size="md">
                        <BlankCard block={block} error={errorModal}>
                            <Row>
                                <Col className="col-8">
                                    <h5 className='m-0'>
                                        <i className="fas fa-file-download mr-2" />
                                        <FormattedMessage id="SalesProcess.DownloadProposal" />
                                    </h5>
                                </Col>
                                <Col className='text-right'>
                                    <Button id="downloadBtn" className="ml-2 btn btn-host btn-sm" onClick={() => this.downloadProForma(configId)}>
                                        <i className="fas fa-file-download" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col>
                                    <Label> <FormattedMessage id="SalesProcess.AvailableConfigs" /></Label>
                                    <CustomSelect
                                        options={configs}
                                        value={configs.find(el => el.value === configId)}
                                        onChange={(combo) => this.handleChangeSelect(combo)}
                                        required
                                    />
                                </Col>
                            </Row>
                        </BlankCard>
                    </StyledModal>
                : ''}

                {deal ?
                    selectedPipeline?.type === "GroupReservation" ?
                        <Card className='border-0 h-100'>
                            <ErrorAlert error={error} />
                            <BlockUi tag="div" blocking={block} className='h-100'>
                                {proposals && proposals.length ?
                                    <Row className='h-100'>
                                        <Col className='pt-2 col-9'>
                                            <div className='h-100'>
                                                <div>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div>
                                                            <h5 className='m-0'><FormattedMessage id="SalesDeal.ProposalDetails" /></h5>
                                                        </div>
                                                        <div className='d-flex align-items-center justify-content-end'>
                                                            <Button className="btn btn-host btn-sm" onClick={toggleImportGroupResModal}>
                                                                <FormattedMessage id="SalesDeal.AddNewProposal"/>
                                                                <i className="ml-2 fas fa-receipt"/>
                                                            </Button>
                                                            <Button id="downloadBtn" className="ml-2 btn btn-host btn-sm" onClick={this.getConfigList}>
                                                                <i className="fas fa-file-download" />
                                                            </Button>
                                                            <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="bottom" target={`downloadBtn`}>
                                                                <PopoverBody>
                                                                    <FormattedMessage id={"SalesProcess.DownloadProposal"} />
                                                                </PopoverBody>
                                                            </CustomToolTip>
                                                            <Button className="ml-2 btn btn-host btn-sm" onClick={toggleProposalHistoryModal}>
                                                                <i className="fas fa-history"/>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='mt-4'>
                                                    <div>
                                                        <Row>
                                                            {proposalDetailsCards.map((card, key) =>
                                                                <Col className='col-3 mb-3' key={key}>
                                                                    <Card className='shadow bg-white border-0 p-3' style={{ borderRadius: '5px' }}>
                                                                        <div className='d-flex align-items-center justify-content-between'>
                                                                            <div className='text-muted'>
                                                                                <i className={`${card.icon} mr-2`} />
                                                                                {card.label}
                                                                            </div>
                                                                            <div style={{ fontSize: '1.4em' }}>
                                                                                {card.isAmount ?
                                                                                    <FormatAmountNumber value={card.value} />
                                                                                : card.value}
                                                                            </div>
                                                                        </div>
                                                                    </Card>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                        <div className='mt-4 d-flex align-items-center justify-content-between' style={{ borderBottom: "1px solid #dee2e6" }}>
                                                            <Nav tabs className="border-0">
                                                                <NavItem>
                                                                    <NavLink className={activeTab === 'Stats' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleTab('Stats')} style={{ cursor: "pointer" }}>
                                                                        <i className="fas fa-chart-pie mr-2"/>
                                                                        <FormattedMessage id="SalesDeal.Stats"/>
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem>
                                                                    <NavLink className={activeTab === 'Rooms' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleTab('Rooms')} style={{ cursor: "pointer" }}>
                                                                        <i className="fas fa-bed mr-2"/>
                                                                        <FormattedMessage id="SalesDeal.Rooms"/>
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem>
                                                                    <NavLink className={activeTab === 'Events' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleTab('Events')} style={{ cursor: "pointer" }}>
                                                                        <i className="fas fa-theater-masks mr-2"/>
                                                                        <FormattedMessage id="SalesDeal.Events"/>
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem>
                                                                    <NavLink className={activeTab === 'Calendar' ? 'tab-border-host text-host  border-0' : 'border-0'} onClick={() => this.toggleTab('Calendar')} style={{ cursor: "pointer" }}>
                                                                        <i className="far fa-calendar-alt mr-2"/>
                                                                        <FormattedMessage id="SalesDeal.Calendar"/>
                                                                    </NavLink>
                                                                </NavItem>
                                                            </Nav>
                                                            <>
                                                                <Button className="ml-2 btn btn-host btn-sm" id={`AppliedFilters`}>
                                                                    <i className="fas fa-sliders-h"/>
                                                                </Button>
                                                                <UncontrolledPopover target={`AppliedFilters`} placement={"left-start"} placementPrefix='coolPopover bs-popover'>
                                                                    <div className='py-2 px-3' style={{ minWidth: '200px', maxWidth: '250px' }}>
                                                                        <div className='text-muted d-flex align-items-center mb-2' style={{ fontSize: '1.1em' }}>
                                                                            <i className="fas fa-sliders-h mr-2 mt-1"/>
                                                                            <FormattedMessage id="SalesDeal.Filters"/>
                                                                        </div>
                                                                        {filters.length > 0 ?
                                                                            (() => {
                                                                                const treatedFilters = filters
                                                                                    .map(({parentId}) => parentId)
                                                                                    .filter((value, index, array) => array.indexOf(value) === index)
                                                                                    .map(parentId => {
                                                                                        const fs = filters.filter((f) => f.parentId === parentId);
                                                                                        const keys = fs.map(({objectType}) => objectType).filter((value, index, array) => array.indexOf(value) === index);
                                                                                        const returnValue = {
                                                                                            parentId,
                                                                                            appliedFilters: []
                                                                                        };

                                                                                    keys.forEach((k) => {
                                                                                        returnValue.appliedFilters.push({
                                                                                            type: k,
                                                                                            list: fs.filter((f) => f.objectType === k)
                                                                                        });
                                                                                    });

                                                                                    return returnValue
                                                                                }
                                                                            );

                                                                                return (
                                                                                    <div>
                                                                                        {treatedFilters.sort(a => !a.parentId ? -1 : 1).map((parent, key) =>
                                                                                            <div key={key} className='mt-2 pt-2' style={{ borderTop: key ? '1px solid lightgrey' : '' }}>
                                                                                                {parent.parentId ?
                                                                                                    <div>
                                                                                                        <b>{parent.parentId}</b>
                                                                                                    </div>
                                                                                                    :''}
                                                                                                {parent.appliedFilters.map((t, key) =>
                                                                                                    <div className={!parent.parentId ? key ? 'mt-2 pt-2' : '' : 'mt-2 px-2'} key={key} style={{ borderTop: !parent.parentId && key ? '1px solid lightgrey' : '' }}>
                                                                                                        <div className='d-flex align-items-center justify-content-between'>
                                                                                                            <div>
                                                                                                                {parent.parentId ?
                                                                                                                    <div className='text-muted'>
                                                                                                                        <FormattedMessage id={`SalesDeal.${t.type}`}/>
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    <b>
                                                                                                                        <FormattedMessage id={`SalesDeal.${t.type}`}/>
                                                                                                                    </b>
                                                                                                                }
                                                                                                            </div>
                                                                                                            {t.list.some(a => !a.objectLabel) ?
                                                                                                                <div style={{ color: '#A80C19' }} className='cursor-pointer' onClick={_ => this.setFilters(null, null, t.type, parent.parentId)}>
                                                                                                                    <i className="fas fa-times"></i>
                                                                                                                </div>
                                                                                                                :''}
                                                                                                        </div>
                                                                                                        {t.list.filter(a => a.objectLabel).map((filter, key) =>
                                                                                                            <div key={key} className='mt-2 d-flex align-items-start justify-content-between px-2'>
                                                                                                                <div>
                                                                                                                    {filter.objectLabel}
                                                                                                                </div>
                                                                                                                <div style={{ color: '#A80C19' }} className='cursor-pointer' onClick={_ => this.setFilters(filter.objectId, filter.objectLabel, t.type, parent.parentId)}>
                                                                                                                    <i className="fas fa-times"></i>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                );
                                                                            })()
                                                                            :
                                                                            <div className='text-muted mt-2 text-center'>
                                                                                <FormattedMessage id="SalesDeal.NoFilters"/>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </UncontrolledPopover>
                                                            </>
                                                        </div>
                                                    </div>
                                                    <div className='mt-4'>
                                                        <div>
                                                            {activeTab === 'Stats' ?
                                                                <SalesDealProposalStats
                                                                    filtersLength={filters?.length}
                                                                    proposals={proposals}
                                                                />
                                                                : activeTab === 'Rooms' ?
                                                                    <SalesDealProposalRooms
                                                                        filtersLength={filters?.length}
                                                                        proposals={proposals}
                                                                    />
                                                                    : activeTab === 'Events' ?
                                                                        <SalesDealProposalEvents
                                                                            filters={filters}
                                                                            proposals={proposals}
                                                                            filtersLength={filters?.length}
                                                                            dealName={deal.name}
                                                                        />
                                                                        : activeTab === 'Calendar' ?
                                                                            <SalesDealProposalCalendar
                                                                                proposals={proposals}
                                                                                filtersLength={filters?.length}
                                                                            />
                                                                            :''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className='pt-2 col-3 px-0 d-flex justify-content-between flex-column h-100' style={{ textAlign: 'center', top: '0', position: 'sticky' }}>
                                            <div className='h-100'>
                                                {proposals && proposals.length > 0 ?
                                                    <div className='d-flex justify-content-between h-100 flex-column h-100'>
                                                        <SalesDealProposalResume
                                                            salesDealProposals={proposals}
                                                            setFilters={this.setFilters}
                                                            hasFilter={this.hasFilter}
                                                            importGroupRes={importGroupRes}
                                                            removeGroupReservation={this.removeGroupReservation}
                                                        />

                                                        <SalesDealProposalResumeBar
                                                            classString="mx-3"
                                                            salesDealProposals={proposals}
                                                        />
                                                    </div>
                                                    :
                                                    <div className='mt-4 text-center'>
                                                        <div className='text-muted'>
                                                            <FormattedMessage id="SalesDeal.NoProposalsYet" />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                    :
                                    <div className='mt-4 text-center'>
                                        <div className='text-muted'>
                                            <FormattedMessage id="SalesDeal.NoProposalsYet"/>
                                        </div>
                                        <div className='mt-2'>
                                            <Button onClick={toggleImportGroupResModal} className="btn btn-host btn-sm">
                                                <FormattedMessage id="SalesDeal.AddNewProposal"/>
                                                <i className="ml-2 fas fa-receipt"></i>
                                            </Button>
                                        </div>
                                    </div>
                                }
                            </BlockUi>
                        </Card>
                        : ''
                    : ''}
            </>
        );
    }
}

export default SalesDealDetailProposal;

