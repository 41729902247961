/* eslint-disable no-sparse-arrays */
const rules = {

    '2cc9c7fc-8987-4680-b51b-f082d943058f': { //Admin operations
        static: [
            "help&support:view",
            "GmsClient",
            "homepage:view",
            "hotelList:viewAll",
            "hotelDetails:save",
            "hotelList:add",
            "allProperties:changeProperty",
            "hotelGroupDetails:saveModules",
            "hotelGroupDetails:licensedProfiles:edit",
            "integrations:view",
            "customData:config",

            "adminOperation:view",
            "admin:view",
            "dataQualityMenu:view",
            "hotelMenu:view",
            "analiticsMenu:view",
            "perfil:credit",
            "profileMenu:view",
            "configMenu:view",
            "marketingAutomationMenu:view",
            "profileAnalysis:view",
            "profileInteractions:view",
            "formsMenu:view",
            "heyCard:view",

            //Homepage 
            "homePage:guestsList",

            //Analtics Menu
            "profileSummary:view",
            "formsAndReviews:view",
            "qualityMetrics:view",
            "monthlyReportRuns:view",
            "reservationsByProfile:view",
            "campaignResults:view",

            //Data Quality Menu
            "dataQualityTemplateDashboard:add",
            "dataQualityTemplateDashboard:delete",
            "dataQualityTemplateDetail:save",
            "dataQualityTemplateDetail:rules:add",
            "dataQualityTemplateDetail:rules:delete",
            "dataQualityTemplateDetail:rules:run",
            "dataQualityTemplateDetail:schedule",
            "dataQualityIssues:merge",
            "ruleDashboard:add",
            "ruleDashboard:delete",
            "ruleDashboard:edit",
            "ruleDetail:save",
            "ruleDetail:filters:add",
            "ruleDetail:filters:delete",
            "ruleDetail:ruleTransform:add",
            "ruleDetail:ruleTransform:delete",
            "ruleDetail:exceptions:add",
            "ruleDetail:exceptions:delete",
            "taskResultDetail:run:delete",
            "taskResultDetail:delete",
            "dataQualityTemplateDashboard:view",
            "ruleDashboard:view",
            "taskResultDashboard:view",
            "dataQualityIssues:view",
            "dataQualityIssues:status:edit",
            "dataQualityIssues:transformation",
            "dataQualityIssues:rules:check",
            "reportsConfig:view",
            "mostRepeated:view",
            "dailyReport:preview",
            "monthlyReportRuns:resend",

            //Hotel Menu
            "hotelList:add",
            "hotelDetails:editPmsUrl",
            "hotelDetails:save",            
            "currentHotelGroup:save",
            "currentHotelGroup:connection:save",
            "currentHotelGroup:connection:newKey",
            "currentHotelGroup:connection:test",

            //Profile Menu
            "searchProfile:add",
            "associatedProfiles:save",
            "associatedProfiles:add",
            "associatedProfiles:delete",
            "profileCustomData:add",
            "profileAddress:add",
            "profileAddress:delete",
            "profileConsent:add",
            "profileConsent:delete",
            "profileContacts:sendEmail",
            "profileContacts:sendSms",
            "profileContacts:add",
            "profileContacts:delete",
            "profileContacts:socialMedia:add",
            "profileContacts:socialMedia:delete",
            "profileContacts:resubscribe",
            "profileCredit:add",
            "profileCredit:delete",
            "profileCredit:bulk",
            "profileDocuments:add",
            "profileDocuments:delete",
            "profileInformations:add",
            "profileInformations:delete",
            "profileDefault:add",
            "profileDefault:delete", ,
            "profilePreferences:add",
            "profilePreferences:delete",
            "profileMembershipCard:add",
            "profileMembershipCard:edit",
            "profileMembershipCard:loyaltyLevelList:all",
            "profileMembershipCard:loyaltyProgram:view",
            "profileSalesMarketing:add",
            "profileSalesMarketing:delete",
            "profileWarnings:add",
            "profileWarnings:delete",
            "profileInterests:add",
            "profileInterests:delete",
            "profilePersonalData:revalidate",
            "profilePersonalData:rulesApplied:view",
            "profilePersonalData:autoMerge",
            "profilePersonalData:save",
            "profile:delete",
            "profile:rtbf",
            "ProfileMenu:import",
            "mergeProfiles:view",
            "associateTags:view",
            "manualReservation:edit",
            "history:view",
            "searchReservation:view",
            "searchProfile:view",
            "deletedProfiles:view",
            "profileMarketing:checkResults",

            //Config Menu
            "configMenu:entries:view",
            "reviewPro:view",
            "tagMapping:save",
            "reviewProInquiry:map",
            "loyaltyCardLevels:view",
            "configCardPoints:view",
            "loyaltyCardTypes:view",
            "profileDataQuality:view",
            "PNForm:map",
            "retentionPolicyRule:view",
            "loyaltyCardAttribution:view",
            "profileMatchConfig:edit",
            "mappings:view",

            //Marketing Automation
            "campaigns:add",
            "campaigns:edit",
            "campaigns:calculateStats",
            "customCampaigns:add",
            "customCampaigns:edit",
            "marketingAlerts:add",
            "marketingAlerts:edit",
            "campaignList:delete",
            "egoiList:add",
            "egoiList:delete",
            "egoiList:edit",
            "alertList:add",
            "alertList:delete",
            "alertList:edit",
            "sendersList:edit",
            "sendersList:add",
            "sendersList:delete",
            "tagList:add",
            "tagList:edit",
            "importTag:view",
            "formSubmissions:map",
            "automationList:delete",
            "shortenerList:add",
            "transactionalWebhooks:add",
            "transactionalWebhooks:delete",
            "webhookForLists:add",
            "webhookForLists:delete",
            "transactionalTemplateList:delete",
            "marketingConfigEgoi:serviceActivion:view",
            "customCampaign:latLongDistance",
            "stripo:edit",
            "WhatsApp:view",
            "googleBusiness:view",
            "WhatsAppChat:view",
            "TypeForm:view",
            "TypeForm:map",
            "exportProfiles:view",
            "suppressionList:add",
            "suppressionList:delete",
            "transactionalTemplate:spanCheck",
            "transactionalTemplate:importTemplates",
            "salesProcess:view",
            
            //AI
            "GPT:usage",
            "GPT:config",

            //Sales Automation
            "salesAutomation:view",

            //Integrations
            "integrations:ApiLogs",

            //HeyCard            
            "voucherDetails:edit"
        ],
        dynamic: {
            "user:restrictHotelFilter": () => { return false }
        }
    },

    'ded4f8f8-e327-4936-a5be-0e15f520906f': { //Operations
        static: [
            "help&support:view",
            "GmsClient",
            "homepage:view",
            "hotelList:viewAll",
            "hotelDetails:save",
            "hotelList:add",
            "allProperties:changeProperty",
            "integrations:view",
            "customData:config",

            "admin:view",
            "dataQualityMenu:view",
            "hotelMenu:view",
            "analiticsMenu:view",
            "profileMenu:view",
            "configMenu:view",
            "marketingAutomationMenu:view",
            "perfil:credit",
            "profileAnalysis:view",
            "profileInteractions:view",
            "formsMenu:view",
            "heyCard:view",

            //Homepage 
            "homePage:guestsList",

            //Analtics Menu
            "profileSummary:view",
            "formsAndReviews:view",
            "qualityMetrics:view",
            "monthlyReportRuns:view",
            "reservationsByProfile:view",
            "campaignResults:view",

            //Data Quality Menu
            "dataQualityTemplateDashboard:add",
            "dataQualityTemplateDashboard:delete",
            "dataQualityTemplateDetail:save",
            "dataQualityTemplateDetail:rules:add",
            "dataQualityTemplateDetail:rules:delete",
            "dataQualityTemplateDetail:rules:run",
            "dataQualityTemplateDetail:schedule",
            "dataQualityIssues:merge",
            "ruleDashboard:add",
            "ruleDashboard:delete",
            "ruleDashboard:edit",
            "ruleDetail:save",
            "ruleDetail:filters:add",
            "ruleDetail:filters:delete",
            "ruleDetail:ruleTransform:add",
            "ruleDetail:ruleTransform:delete",
            "ruleDetail:exceptions:add",
            "ruleDetail:exceptions:delete",
            "taskResultDetail:run:delete",
            "taskResultDetail:delete",
            "dataQualityTemplateDashboard:view",
            "ruleDashboard:view",
            "taskResultDashboard:view",
            "dataQualityIssues:view",
            "dataQualityIssues:status:edit",
            "dataQualityIssues:transformation",
            "dataQualityIssues:rules:check",
            "reportsConfig:view",
            "mostRepeated:view",
            "dailyReport:preview",
            "monthlyReportRuns:resend",

            //Hotel Menu
            "hotelList:add",
            "hotelDetails:editPmsUrl",
            "hotelDetails:save",
            "currentHotelGroup:save",
            "currentHotelGroup:connection:save",
            "currentHotelGroup:connection:newKey",
            "currentHotelGroup:connection:test",

            //Profile Menu
            "searchProfile:add",
            "associatedProfiles:save",
            "associatedProfiles:add",
            "associatedProfiles:delete",
            "profileCustomData:add",
            "profileAddress:add",
            "profileAddress:delete",
            "profileConsent:add",
            "profileConsent:delete",
            "profileContacts:sendEmail",
            "profileContacts:sendSms",
            "profileContacts:add",
            "profileContacts:delete",
            "profileContacts:socialMedia:add",
            "profileContacts:socialMedia:delete",
            "profileContacts:resubscribe",
            "profileCredit:add",
            "profileCredit:delete",
            "profileCredit:bulk",
            "profileDocuments:add",
            "profileDocuments:delete",
            "profileInformations:add",
            "profileInformations:delete",
            "profileDefault:add",
            "profileDefault:delete", ,
            "profilePreferences:add",
            "profilePreferences:delete",
            "profileMembershipCard:add",
            "profileMembershipCard:edit",
            "profileMembershipCard:loyaltyLevelList:all",
            "profileMembershipCard:loyaltyProgram:view",
            "profileSalesMarketing:add",
            "profileSalesMarketing:delete",
            "profileWarnings:add",
            "profileWarnings:delete",
            "profileInterests:add",
            "profileInterests:delete",
            "profilePersonalData:revalidate",
            "profilePersonalData:rulesApplied:view",
            "profilePersonalData:autoMerge",
            "profilePersonalData:save",
            "profile:delete",
            "profile:rtbf",
            "ProfileMenu:import",
            "mergeProfiles:view",
            "associateTags:view",
            "manualReservation:edit",
            "history:view",
            "searchReservation:view",
            "searchProfile:view",
            "deletedProfiles:view",
            "profileMarketing:checkResults",

            //Config Menu
            "configMenu:entries:view",
            "reviewPro:view",
            "tagMapping:save",
            "reviewProInquiry:map",
            "loyaltyCardLevels:view",
            "configCardPoints:view",
            "loyaltyCardTypes:view",
            "profileDataQuality:view",
            "PNForm:map",
            "retentionPolicyRule:view",
            "loyaltyCardAttribution:view",
            "profileMatchConfig:edit",
            "mappings:view",

            //Marketing Automation
            "campaigns:add",
            "campaigns:edit",
            "campaigns:calculateStats",
            "customCampaigns:add",
            "customCampaigns:edit",
            "marketingAlerts:add",
            "marketingAlerts:edit",
            "campaignList:delete",
            "egoiList:add",
            "egoiList:delete",
            "egoiList:edit",
            "alertList:add",
            "alertList:delete",
            "alertList:edit",
            "sendersList:edit",
            "sendersList:add",
            "sendersList:delete",
            "tagList:add",
            "tagList:edit",
            "importTag:view",
            "formSubmissions:map",
            "automationList:delete",
            "shortenerList:add",
            "transactionalWebhooks:add",
            "transactionalWebhooks:delete",
            "webhookForLists:add",
            "webhookForLists:delete",
            "transactionalTemplateList:delete",
            "marketingConfigEgoi:serviceActivion:view",
            "customCampaign:latLongDistance",
            "stripo:edit",
            "WhatsApp:view",
            "googleBusiness:view",
            "WhatsAppChat:view",
            "TypeForm:view",
            "TypeForm:map",
            "exportProfiles:view",
            "suppressionList:add",
            "suppressionList:delete",
            "transactionalTemplate:spanCheck",
            "transactionalTemplate:importTemplates",
            "salesProcess:view",
            
            //AI
            "GPT:usage",
            "GPT:config",

            //Sales Automation
            "salesAutomation:view",

            //Integrations
            "integrations:ApiLogs",

            //HeyCard            
            "voucherDetails:edit"
        ],
        dynamic: {
            "user:restrictHotelFilter": () => { return false }
        }
    },

    'a4140047-d6fd-4479-979b-80861dc0a954': { //Admin (pode fazer todas as ações)
        static: [
            "GmsClient",
            "homepage:view",
            "dataQualityMenu:view",
            "hotelMenu:view",
            "analiticsMenu:view",
            "perfil:credit",
            "profileMenu:view",
            "configMenu:view",
            "marketingAutomationMenu:view",
            "profileInteractions:view",
            "integrations:view",
            "heyCard:view",
            "customData:config",

            //Homepage 
            "homePage:guestsList",

            //Analytics Menu
            "formsAndReviews:view",
            "qualityMetrics:view",
            "monthlyReportRuns:view",
            "profileSummary:view",
            "reservationsByProfile:view",
            "campaignResults:view",

            //Data Quality Menu
            "dataQualityTemplateDetail:save",
            "dataQualityTemplateDetail:rules:run",
            "dataQualityTemplateDetail:schedule",
            "dataQualityIssues:merge",
            "ruleDetail:save",
            "ruleDetail:filters:add",
            "ruleDetail:filters:delete",
            "ruleDetail:ruleTransform:add",
            "ruleDetail:ruleTransform:delete",
            "ruleDetail:exceptions:add",
            "ruleDetail:exceptions:delete",
            "taskResultDetail:run:delete",
            "taskResultDetail:delete",
            "dataQualityTemplateDashboard:view",
            "ruleDashboard:view",
            "taskResultDashboard:view",
            "dataQualityIssues:view",
            "dataQualityIssues:status:edit",
            "dataQualityIssues:transformation",
            "dataQualityIssues:rules:check",
            "reportsConfig:view",
            "monthlyReportRuns:resend",

            //Hotel Menu
            "hotelList:add",
            "hotelDetails:save",
            "currentHotelGroup:save",
            "currentHotelGroup:connection:save",
            "currentHotelGroup:connection:newKey",
            "currentHotelGroup:connection:test",

            //Profile Menu
            "searchProfile:add",
            "associatedProfiles:save",
            "associatedProfiles:add",
            "associatedProfiles:delete",
            "profileCustomData:add",
            "profileAddress:add",
            "profileAddress:delete",
            "profileConsent:add",
            "profileConsent:delete",
            "profileContacts:sendEmail",
            "profileContacts:sendSms",
            "profileContacts:add",
            "profileContacts:delete",
            "profileContacts:socialMedia:add",
            "profileContacts:socialMedia:delete",
            "profileContacts:resubscribe",
            "profileCredit:add",
            "profileCredit:delete",
            "profileCredit:bulk",
            "profileDocuments:add",
            "profileDocuments:delete",
            "profileInformations:add",
            "profileInformations:delete",
            "profileDefault:add",
            "profileDefault:delete", ,
            "profilePreferences:add",
            "profilePreferences:delete",
            "profileMembershipCard:add",
            "profileMembershipCard:edit",
            "profileMembershipCard:loyaltyLevelList:all",
            "profileMembershipCard:loyaltyProgram:view",
            "profileSalesMarketing:add",
            "profileSalesMarketing:delete",
            "profileWarnings:add",
            "profileWarnings:delete",
            "profileInterests:add",
            "profileInterests:delete",
            "profilePersonalData:revalidate",
            "profilePersonalData:rulesApplied:view",
            "profilePersonalData:autoMerge",
            "profilePersonalData:save",
            "profile:delete",
            "profile:rtbf",
            "ProfileMenu:import",
            "mergeProfiles:view",
            "associateTags:view",
            "manualReservation:edit",
            "WhatsApp:view",
            "googleBusiness:view",
            "WhatsAppChat:view",
            "history:view",
            "searchReservation:view",
            "searchProfile:view",

            //Config Menu
            "configMenu:entries:view",
            "reviewPro:view",
            "tagMapping:save",
            "reviewProInquiry:map",
            "PNForm:map",
            "loyaltyCardAttribution:view",
            "loyaltyCardLevels:view",
            "loyaltyCardTypes:view",
            "configCardPoints:view",

            //Marketing Automation
            "campaigns:add",
            "campaigns:edit",
            "customCampaigns:add",
            "customCampaigns:edit",
            "marketingAlerts:add",
            "marketingAlerts:edit",
            "stripo:edit", 

            //Integrations
            "integrations:ApiLogs",
        ],
        dynamic: {
            "user:restrictHotelFilter": () => { return false }
        }
    },

    '1f8c0154-62b3-404f-8880-63797a0ee01d': { //Manager (mesmas permissões que o Admin mas sem a possibilidade de editar os campos de crédito)
        static: [
            "GmsClient",
            "homepage:view",
            "dataQualityMenu:view",
            "hotelMenu:view",
            "analiticsMenu:view",
            "perfil:credit",
            "reservationsByProfile:view",
            "profileMenu:view",
            "configMenu:view",
            "marketingAutomationMenu:view",
            "profileInteractions:view",
            "integrations:view",
            "heyCard:view",
            "customData:config",

            //Homepage 
            "homePage:guestsList",

            //Analytics Menu
            "formsAndReviews:view",
            "qualityMetrics:view",
            "monthlyReportRuns:view",
            "profileSummary:view",

            //Data Quality Menu
            "dataQualityTemplateDetail:save",
            "dataQualityTemplateDetail:schedule",
            "dataQualityIssues:merge",
            "ruleDetail:save",
            "ruleDetail:filters:add",
            "ruleDetail:filters:delete",
            "ruleDetail:ruleTransform:add",
            "ruleDetail:ruleTransform:delete",
            "ruleDetail:exceptions:add",
            "ruleDetail:exceptions:delete",
            "taskResultDetail:run:delete",
            "taskResultDetail:delete",
            "dataQualityTemplateDashboard:view",
            "ruleDashboard:view",
            "taskResultDashboard:view",
            "dataQualityIssues:view",
            "dataQualityIssues:status:edit",
            "dataQualityIssues:transformation",
            "dataQualityIssues:rules:check",
            "reportsConfig:view",
            "monthlyReportRuns:resend",

            //Hotel Menu
            "hotelList:add",
            "hotelDetails:save",
            "currentHotelGroup:save",
            "currentHotelGroup:connection:save",
            "currentHotelGroup:connection:newKey",
            "currentHotelGroup:connection:test",

            //Profile Menu
            "searchProfile:add",
            "associatedProfiles:save",
            "associatedProfiles:add",
            "associatedProfiles:delete",
            "profileCustomData:add",
            "profileAddress:add",
            "profileAddress:delete",
            "profileConsent:add",
            "profileConsent:delete",
            "profileContacts:sendEmail",
            "profileContacts:sendSms",
            "profileContacts:add",
            "profileContacts:delete",
            "profileContacts:socialMedia:add",
            "profileContacts:socialMedia:delete",
            "profileContacts:resubscribe",
            "profileDocuments:add",
            "profileDocuments:delete",
            "profileInformations:add",
            "profileInformations:delete",
            "profileDefault:add",
            "profileDefault:delete", ,
            "profilePreferences:add",
            "profilePreferences:delete",
            "profileMembershipCard:add",
            "profileMembershipCard:edit",
            "profileMembershipCard:loyaltyLevelList:all",
            "profileMembershipCard:loyaltyProgram:view",
            "profileSalesMarketing:add",
            "profileSalesMarketing:delete",
            "profileWarnings:add",
            "profileWarnings:delete",
            "profileInterests:add",
            "profileInterests:delete",
            "profilePersonalData:revalidate",
            "profilePersonalData:rulesApplied:view",
            "profilePersonalData:autoMerge",
            "profilePersonalData:save",
            "profile:delete",
            "profile:rtbf",
            "ProfileMenu:import",
            "mergeProfiles:view",
            "associateTags:view",
            "manualReservation:edit",
            "searchReservation:view",
            "searchProfile:view",

            //Config Menu
            "configMenu:entries:view",
            "reviewPro:view",
            "tagMapping:save",
            "reviewProInquiry:map",
            "PNForm:map",
            "loyaltyCardAttribution:view",
            "loyaltyCardLevels:view",
            "loyaltyCardTypes:view",
            "configCardPoints:view",

            //Marketing Automation
            "campaigns:add",
            "campaigns:edit",
            "customCampaigns:add",
            "customCampaigns:edit",
            "marketingAlerts:add",
            "marketingAlerts:edit",
            "stripo:edit",

            //Integrations
            "integrations:ApiLogs"
        ],
        dynamic: {
            "user:restrictHotelFilter": () => { return false }
        }
    },

    '5f65f469-6340-4bf4-8c50-1c37158cc41a': { //GMS Reception Power User (acesso ao menu de perfil e à página de visualização de detalhes do perfil (com excepção da informação das reservas manuais), opção de merge e à opção de visuzalição de ocorrencias no menu de qualidade de dados.)
        static: [
            "GmsClient",
            "homepage:view",
            "dataQualityMenu:view",
            "profileMenu:view",
            "profileInteractions:view",
            "heyCard:view",

            //Homepage 
            "homePage:guestsList",

            //Data Quality Menu
            "dataQualityIssues:merge",
            "dataQualityIssues:view",
            "dataQualityIssues:status:edit",
            "dataQualityIssues:transformation",
            "dataQualityIssues:rules:check",

            //Profile Menu
            "searchProfile:add",
            "associatedProfiles:save",
            "associatedProfiles:add",
            "associatedProfiles:delete",
            "profileCustomData:add",
            "profileAddress:add",
            "profileAddress:delete",
            "profileConsent:add",
            "profileConsent:delete",
            "profileContacts:sendEmail",
            "profileContacts:sendSms",
            "profileContacts:add",
            "profileContacts:delete",
            "profileContacts:socialMedia:add",
            "profileContacts:socialMedia:delete",
            "profileContacts:resubscribe",
            "profileDocuments:add",
            "profileDocuments:delete",
            "profileInformations:add",
            "profileInformations:delete",
            "profileDefault:add",
            "profileDefault:delete", ,
            "profilePreferences:add",
            "profilePreferences:delete",
            "profileMembershipCard:add",
            "profileMembershipCard:edit",
            "profileMembershipCard:loyaltyProgram:view",
            "profileSalesMarketing:add",
            "profileSalesMarketing:delete",
            "profileWarnings:add",
            "profileWarnings:delete",
            "profileInterests:add",
            "profileInterests:delete",
            "profilePersonalData:revalidate",
            "profilePersonalData:rulesApplied:view",
            "profilePersonalData:save",
            "profilePersonalData:autoMerge",
            "profile:delete",
            "profile:rtbf",
            "mergeProfiles:view",
            "searchReservation:view",
            "searchProfile:view",
        ],
        dynamic: {
            "user:restrictHotelFilter": () => { if (global.modules?.some(el => el === "RestrictInfoToUserHotel")) return true; else return false; }
        }
    },

    '2b21bd6f-f770-4c19-9f63-6a79cb86a341': { //GMS Reception User (acesso ao menu de perfil e à página de visualização de detalhes do perfil. Poderá alterar todos os campo com excepção das reservas manuais)
        static: [
            "GmsClient",
            "homepage:view",
            "profileMenu:view",
            "profileInteractions:view",
            "heyCard:view",

            //Homepage 
            "homePage:guestsList",

            //Data Quality Menu
            "dataQualityIssues:rules:check",

            //Profile Menu
            "searchProfile:add",
            "associatedProfiles:save",
            "associatedProfiles:add",
            "associatedProfiles:delete",
            "profileCustomData:add",
            "profileAddress:add",
            "profileAddress:delete",
            "profileConsent:add",
            "profileConsent:delete",
            "profileContacts:sendEmail",
            "profileContacts:sendSms",
            "profileContacts:add",
            "profileContacts:delete",
            "profileContacts:socialMedia:add",
            "profileContacts:socialMedia:delete",
            "profileContacts:resubscribe",
            "profileDocuments:add",
            "profileDocuments:delete",
            "profileInformations:add",
            "profileInformations:delete",
            "profileDefault:add",
            "profileDefault:delete", ,
            "profilePreferences:add",
            "profilePreferences:delete",
            "profileMembershipCard:add",
            "profileSalesMarketing:add",
            "profileSalesMarketing:delete",
            "profileWarnings:add",
            "profileWarnings:delete",
            "profileInterests:add",
            "profileInterests:delete",
            "profilePersonalData:revalidate",
            "profilePersonalData:rulesApplied:view",
            "profilePersonalData:save",
            "searchReservation:view",
            "searchProfile:view",
        ],
        dynamic: {
            "user:restrictHotelFilter": () => { if (global.modules?.some(el => el === "RestrictInfoToUserHotel")) return true; else return false; }
        }
    },

    'eb3b46a7-84a5-4021-872e-fc9b7f70d7fa': { //Data Admin (pode alterar dados do perfil, merge e configurar regras) (Não pode alterar configurações do hotel) (não vê o marketing automation)
        static: [
            "GmsClient",
            "homepage:view",
            "dataQualityMenu:view",
            "hotelMenu:view",
            "analiticsMenu:view",
            "profileMenu:view",
            "configMenu:view",
            "profileInteractions:view",
            "heyCard:view",

            //Homepage 
            "homePage:guestsList",

            //Data Quality Menu
            "dataQualityTemplateDetail:save",
            "dataQualityTemplateDetail:schedule",
            "dataQualityIssues:merge",
            "ruleDetail:save",
            "ruleDetail:filters:add",
            "ruleDetail:filters:delete",
            "ruleDetail:ruleTransform:add",
            "ruleDetail:ruleTransform:delete",
            "ruleDetail:exceptions:add",
            "ruleDetail:exceptions:delete",
            "taskResultDetail:run:delete",
            "taskResultDetail:delete",
            "dataQualityTemplateDashboard:view",
            "ruleDashboard:view",
            "taskResultDashboard:view",
            "dataQualityIssues:view",
            "reportsConfig:view",
            "monthlyReportRuns:resend",

            //Profile Menu
            "searchProfile:add",
            "associatedProfiles:save",
            "associatedProfiles:add",
            "associatedProfiles:delete",
            "profileCustomData:add",
            "profileAddress:add",
            "profileAddress:delete",
            "profileConsent:add",
            "profileConsent:delete",
            "profileContacts:sendEmail",
            "profileContacts:sendSms",
            "profileContacts:add",
            "profileContacts:delete",
            "profileContacts:socialMedia:add",
            "profileContacts:socialMedia:delete",
            "profileContacts:resubscribe",
            "profileCredit:add",
            "profileCredit:delete",
            "profileDocuments:add",
            "profileDocuments:delete",
            "profileInformations:add",
            "profileInformations:delete",
            "profileDefault:add",
            "profileDefault:delete", ,
            "profilePreferences:add",
            "profilePreferences:delete",
            "profileSalesMarketing:add",
            "profileSalesMarketing:delete",
            "profileWarnings:add",
            "profileWarnings:delete",
            "profileInterests:add",
            "profileInterests:delete",
            "profilePersonalData:revalidate",
            "profilePersonalData:rulesApplied:view",
            "profilePersonalData:save",
            "profile:delete",
            "profile:rtbf",
            "ProfileMenu:import",
            "mergeProfiles:view",
            "associateTags:view",
            "searchReservation:view",
            "searchProfile:view",

            //Config Menu
            "configMenu:entries:view",
            "reviewPro:view"
        ],
        dynamic: {
            "user:restrictHotelFilter": () => { if (global.modules?.some(el => el === "RestrictInfoToUserHotel")) return true; else return false; }
        }
    },

    '7d681aa4-fc3c-49eb-8cd6-8dd895512049': { //Data Updater (pode alterar dados do perfil) (não pode alterar dados dentro do config) (não vê o profile analitics e o marketing automation)
        static: [
            "GmsClient",
            "homepage:view",
            "dataQualityMenu:view",
            "hotelMenu:view",
            "analiticsMenu:view",
            "profileMenu:view",
            "configMenu:view",
            "profileInteractions:view",
            "heyCard:view",

            //Homepage 
            "homePage:guestsList",

            //Data Quality Menu
            "dataQualityTemplateDetail:save",
            "dataQualityTemplateDetail:schedule",
            "dataQualityIssues:merge",
            "ruleDetail:save",
            "ruleDetail:filters:add",
            "ruleDetail:filters:delete",
            "ruleDetail:ruleTransform:add",
            "ruleDetail:ruleTransform:delete",
            "ruleDetail:exceptions:add",
            "ruleDetail:exceptions:delete",
            "taskResultDetail:run:delete",
            "taskResultDetail:delete",
            "dataQualityTemplateDashboard:view",
            "ruleDashboard:view",
            "taskResultDashboard:view",
            "dataQualityIssues:view",
            "reportsConfig:view",
            "monthlyReportRuns:resend",

            //Profile Menu
            "searchProfile:add",
            "associatedProfiles:save",
            "associatedProfiles:add",
            "associatedProfiles:delete",
            "profileCustomData:add",
            "profileAddress:add",
            "profileAddress:delete",
            "profileConsent:add",
            "profileConsent:delete",
            "profileContacts:sendEmail",
            "profileContacts:sendSms",
            "profileContacts:add",
            "profileContacts:delete",
            "profileContacts:socialMedia:add",
            "profileContacts:socialMedia:delete",
            "profileContacts:resubscribe",
            "profileCredit:add",
            "profileCredit:delete",
            "profileDocuments:add",
            "profileDocuments:delete",
            "profileInformations:add",
            "profileInformations:delete",
            "profileDefault:add",
            "profileDefault:delete", ,
            "profilePreferences:add",
            "profilePreferences:delete",
            "profileSalesMarketing:add",
            "profileSalesMarketing:delete",
            "profileWarnings:add",
            "profileWarnings:delete",
            "profileInterests:add",
            "profileInterests:delete",
            "profilePersonalData:revalidate",
            "profilePersonalData:rulesApplied:view",
            "profilePersonalData:save",
            "profile:delete",
            "profile:rtbf",
            "ProfileMenu:import",
            "mergeProfiles:view",
            "associateTags:view",
            "searchReservation:view",
            "searchProfile:view",
        ],
        dynamic: {
            "user:restrictHotelFilter": () => { if (global.modules?.some(el => el === "RestrictInfoToUserHotel")) return true; else return false; }
        }
    },

    '2663d8ef-d855-4b65-a263-3390fbce0e35': { //Marketing Automation Management (Apenas pode ver o menu de marketing automation, reviewPro e as respectivas ações)
        static: [
            "GmsClient",
            "homepage:view",

            "marketingAutomationMenu:view",
            "configMenu:view",
            "analiticsMenu:view",
            "profileMenu:view",

            //Analytics Menu
            "formsAndReviews:view",

            //Marketing Automation
            "campaigns:add",
            "campaigns:edit",
            "customCampaigns:add",
            "customCampaigns:edit",
            "marketingAlerts:add",
            "marketingAlerts:edit",
            "marketingConfigEgoi:serviceActivion:view",
            "stripo:edit",

            //Config Menu
            "reviewPro:view",
            "tagMapping:save",
            "reviewProInquiry:map",
            "PNForm:map",

            //Profile Menu
        ],
        dynamic: {
            "user:restrictHotelFilter": () => { if (global.modules?.some(el => el === "RestrictInfoToUserHotel")) return true; else return false; }
        }
    },

    'da02ccea-a45a-4803-b607-fa04418656d0': { //View Only (não pode efetuar alterações) (não vê data quality, profile analitics, config e marketing automation)
        static: [
            "GmsClient",
            "homepage:view",
            "profileMenu:view",
            "profileInteractions:view",

            //Homepage 
            "homePage:guestsList",

            //Profile Menu
            "searchProfile:view"
        ],
        dynamic: {
            "user:restrictHotelFilter": () => { if (global.modules?.some(el => el === "RestrictInfoToUserHotel")) return true; else return false; }
        }
    },

    'e02b5dfc-ee17-4dbd-aeba-699ab14ad35c': {  // Sales Process - Tem acesso ao sales process e perfis
        static: [
            "GmsClient",
            "homepage:view",
            "profileMenu:view",
            "profileInteractions:view",
            "configMenu:view",

            //Homepage 
            "homePage:guestsList",

            //Profile Menu
            "searchProfile:view",

            //Config Menu
            "salesProcess:view"
        ]
    },


    'da9f33db-f99c-4f3f-8817-ccc263972d5a': {  // Voucher Admin
        static: [
            //HeyCard    
            "voucherDetails:edit"
        ]
    }
};

export default rules;