import React, { Component } from 'react';
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import moment from 'moment-timezone';
import { getAPI } from '../Base/API';
import SalesProcessExecutiveReportMonthly from './SalesProcessExecutiveReportMonthly';
import SalesProcessExecutiveReportYearly from './SalesProcessExecutiveReportYearly';
import SalesDealList from './SalesDealList';
import SalesProcessResources from './SalesProcessResources';

class SalesProcessExecutiveReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            block: true,
            year: moment().format('YYYY'),
            hotelOptions: [],
            yearOptions: [
                { label:'2023', value:'2023' },
                { label:'2024', value:'2024' },
                { label:'2025', value:'2025' },
                { label:'2026', value:'2026' },
                { label:'2027', value:'2027' },
                { label:'2028', value:'2028' },
                { label:'2029', value:'2029' }
            ],
            colors: ["#0867fa","#59B2C2","#48d7ea","#8B75EA","#CF53FA","#FA339A","#F16D84","#FFBE2D","#FF8A00","#62a140","#66E27F","#8EE3C8","#D7EAFF","#405FC1"],
            yearlyReport: null,
            selectedHotel: null,
            activeTab: "SalesProcessExecutiveReportYearly",
            selectedStageTypeId: null,
            selectedMonth: null,
            isFromMonthly: false,
            isSpaces: false
        };
    }

    componentDidMount(){
        this.getHotelList();
    }

    getHotelList = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                const hotelOptions = [];

                data.response.map((item) => {
                    const obj = { value: item.hotelId, label: item.name2 };
                    const groupIndex = hotelOptions.findIndex(c => c.groupId === item.hotelGroupId);
        
                    if (groupIndex !== -1) {
                        hotelOptions[groupIndex].options.push(obj)
                    }
                    else {
                        hotelOptions.push({ label: item.hotelGroupName, groupId: item.hotelGroupId, options: [obj] });
                    }
                });

                this.setState({ hotelOptions, block: false });

                return;
            }
            this.setState({ block: false });
        }, '/api/gms/Hotel/v1/hotel/list?onlyFromHotelGroup=true');
    }

    updateYearlyReport = (yearlyReport) => {
        this.setState({ yearlyReport });
    }

    toggleTab = (tab, selectedHotel, selectedStageTypeId, selectedMonth, isFromMonthly, isSpaces, salesStatus, isQuarter) => {
        const hotelList = selectedHotel ? Array.isArray(selectedHotel) ? selectedHotel : [selectedHotel] : null;
        const statusList = salesStatus ? Array.isArray(salesStatus) ? salesStatus : [salesStatus] : null;
        if(tab !== this.state.activeTab)
            this.setState({ activeTab: tab, selectedHotel: hotelList, selectedStageTypeId, selectedMonth, isFromMonthly, isSpaces, salesStatus: statusList, isQuarter });
    }

    handleChangeYear = (combo, cb) => {
        this.setState({ year: combo?.value }, () => {
            if(cb){
                cb();
            }
        });
    }

    render() {
        const { error, block, activeTab, year, hotelOptions, yearOptions, colors, yearlyReport, selectedHotel, selectedStageTypeId, selectedMonth, isFromMonthly, isSpaces, salesStatus, isQuarter } = this.state;
        const { icon, selectDealDetail, salesUsers, resourcesIcon, dealListIcon, salesPipelines, salesSources, dealList, getDealList, salesSourcesTypes } = this.props;
        
        return (
            <div className="border-0 h-100 pb-2">
                <ErrorAlert error={error}/>
                <BlockUi tag="div" blocking={block}>
                    {activeTab === "SalesProcessExecutiveReportYearly" ?
                        <SalesProcessExecutiveReportYearly
                            year={year}
                            hotelOptions={hotelOptions}
                            handleChangeYear={this.handleChangeYear}
                            updateYearlyReport={this.updateYearlyReport}
                            yearOptions={yearOptions}
                            colors={colors}
                            yearlyReport={yearlyReport}
                            icon={icon}
                            selectedHotel={selectedHotel}
                            toggleTab={this.toggleTab}
                        />
                    : activeTab === "SalesProcessExecutiveReportMonthly" ?
                        <SalesProcessExecutiveReportMonthly
                            handleChangeYear={this.handleChangeYear}
                            year={year}
                            hotelOptions={hotelOptions}
                            yearOptions={yearOptions}
                            colors={colors}
                            yearlyReport={yearlyReport}
                            selectedHotel={selectedHotel}
                            toggleTab={this.toggleTab}
                        />
                    : activeTab === "SalesDealList" ?
                        <SalesDealList
                            selectDealDetail={selectDealDetail}
                            icon={dealListIcon}
                            salesUsers={salesUsers}
                            salesPipelines={salesPipelines}
                            salesSources={salesSources}
                            dealList={dealList}
                            getDealList={getDealList}
                            salesSourcesTypes={salesSourcesTypes}
                            isChild={true}
                            selectedHotel={selectedHotel}
                            selectedStageTypeId={selectedStageTypeId}
                            year={year}
                            toggleTab={this.toggleTab}
                            salesStatus={salesStatus}
                            selectedMonth={selectedMonth}
                            isFromMonthly={isFromMonthly}
                            isQuarter={isQuarter}
                        />
                    : activeTab === "SalesProcessResources" ?
                        <SalesProcessResources
                            toggleTab={this.toggleTab}
                            salesUsers={salesUsers}
                            selectDealDetail={selectDealDetail}
                            isSpaces={isSpaces}
                            icon={resourcesIcon}
                            isChild={true}
                            selectedHotel={selectedHotel}
                            selectedStageTypeId={selectedStageTypeId}
                            year={year}
                            selectedMonth={selectedMonth}
                            isFromMonthly={isFromMonthly}
                        />
                    :''}
                </BlockUi>
            </div>
        );
    }
}

export default SalesProcessExecutiveReport;