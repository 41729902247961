import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Col, Row, BreadcrumbItem, Breadcrumb } from 'reactstrap';
import moment from 'moment';
import { getMarketingType, getFrequency, getSenderChannel } from '../../Base/ReferenceDataFunctions';
import { handleNotification } from "../../Base/Notification";
import { BlankCard } from '../../Base/CommonUIComponents';
import { postAPI, getAPI } from "../../Base/API";
import CampaignStep1 from './CampaignStep1';
import CampaignStep2 from './CampaignStep2';
import CampaignStep3 from './CampaignStep3';
import { Link } from "react-router-dom";
import CampaignStep3Linked from './CampaignStep3Linked';

class CampaignDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            hasEgoi: false,
            hasSendGrid: false,
            hasTwillio: false,
            campaign: { consentType: "Mailings", weekdays: [] },
            steps: [
                { step: 1, active: true },
                { step: 2, active: false },
                { step: 3, active: false }
            ],
            filters: {},
            alreadySentAndExists: false,
            collectMetricsForGoogleAnalytics: false,
            invalidCampaignName: false
        };
    }

    componentDidMount() {
        window.setTimeout(
            () => {
                this.setState({
                    hasEgoi: global.modules && global.modules.some(m => m === 'Egoi'),
                    hasSendGrid: global.modules && global.modules.some(m => m === 'SendGrid'),
                    hasTwillio: global.modules && global.modules.some(m => m === 'TwilioMessaging')
                });
            },
            global.modules ? 0 : 2500
        );

        if (this.props.match.params.id) {
            this.getCampaign();
        }
    }

    getCampaign = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response) {
                const customCampaign = Object.assign(this.state.filters, data.response[0].customCampaign);
                const campaign = data.response[0];

                if (!campaign.weekdays) {
                    campaign.weekdays = [];
                }
                if (!customCampaign.tags) {
                    customCampaign.tags = [];
                }
                if (!customCampaign.excludedTags) {
                    customCampaign.excludedTags = [];
                }
                if (!customCampaign.loyaltyCards) {
                    customCampaign.loyaltyCards = [];
                }
                if (!customCampaign.occupancies) {
                    customCampaign.occupancies = [];
                }
                if (!customCampaign.profileType) {
                    customCampaign.profileType = [];
                }
                if (!customCampaign.vipCodes) {
                    customCampaign.vipCodes = [];
                }

                const collectMetricsForGoogleAnalytics = campaign.campaignName !== null && campaign.campaignName !== undefined;
                const alreadySentAndExists = moment().isAfter(moment(campaign.endDate)) && collectMetricsForGoogleAnalytics;

                this.setState({
                    campaign: campaign,
                    filters: customCampaign,
                    error: errorMessage, block: false, alreadySentAndExists, collectMetricsForGoogleAnalytics
                });
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/marketingsend/${this.props.match.params.id}`);
    }

    saveCampaign = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var campaign = { ...this.state.campaign };
        campaign.customCampaign = this.state.filters || null;

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="CampaignDetails.CampaignSaved" />, <FormattedMessage id="generic.success" />);
                    this.props.history.push({ pathname: `/CampaignDetails/${data.response[0].id}` });
                    const customCampaign = Object.assign(this.state.filters, data.response[0].customCampaign);
                    const campaign = data.response[0];

                    this.setState({ campaign: campaign, filters: customCampaign, error: errorMessage, block: false });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Marketing/v1/marketingsend`, JSON.stringify({ request: campaign }));
    }

    nextStep = (val) => {
        var stateCopy = Object.assign({}, this.state);
        stateCopy.steps = stateCopy.steps.slice();
        for (var i = 0; i < stateCopy.steps.length; i++) {
            if (stateCopy.steps[i].step === val) {
                stateCopy.steps[i] = Object.assign({}, stateCopy.steps[i]);
                stateCopy.steps[i].active = true;
            }
            else {
                stateCopy.steps[i] = Object.assign({}, stateCopy.steps[i]);
                stateCopy.steps[i].active = false;
            }
        }
        this.setState(stateCopy);
    }

    movePos = (val) => {
        this.nextStep(this.state.steps.find(el => el.active === true).step + val);
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            campaign: {
                ...prevState.campaign,
                [name]: value
            }
        }))
    }

    handleChangeWithCallback = (e, cb) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            campaign: {
                ...prevState.campaign,
                [name]: value
            }
        }), cb);
    }

    handleSwitch = (name, combo) => {
        this.setState(prevState => ({
            [name]: combo && combo.value,
            invalidCampaignName: false,
            campaign: {
                ...prevState.campaign,
                campaignName: null
            }
        }));
    }

    handleWeekDays = (weekDay) => {
        const { weekdays } = this.state.campaign;

        if (weekdays.find(weekday => weekday === weekDay)) {
            const elementsToRemove = 1;
            weekdays.splice(weekdays.indexOf(weekdays.find(item => item === weekDay)), elementsToRemove);
        }
        else {
            weekdays.push(weekDay);
        }

        this.setState(prevState => ({
            campaign: {
                ...prevState.campaign,
                weekdays: weekdays
            }
        }));
    }

    handleLoyaltyCard = (e) => {
        const { campaign, filters } = this.state;
        const { value } = e.target;

        campaign.hasLoyaltyCard = value;
        filters.loyaltyCards = [];

        this.setState({ campaign, filters });
    }

    handleVipCodes = (e) => {
        const { campaign, filters } = this.state;
        const { value } = e.target;

        filters.hasVipCodes = value;
        filters.vipCodes = [];

        this.setState({ campaign, filters });
    }
    
    handleMarketingType = (marketingType) => {
        this.setState(prevState => ({
            campaign: {
                ...prevState.campaign,
                marketingType,
                frequency: (marketingType === 'Birthday' || marketingType === 'CreationDay' ? 'None' : null),
                frequencyAdjust: (marketingType === 'LinkedCampaign' ? 1 : 0),
                surveyProvider: null,
                surveyId: null,
                propertyId: null,
                bookingsFromDate: null,
                bookingsToDate: null,
                staysFromDate: null,
                staysToDate: null,
                hasLoyaltyCard: null,
                nationalities: null,
                cancelReason: null,
                rate: null,
                roomCategory: null,
                channel: null,
                hasOtaEmail: null,
                hasVipCodes: null
            },
            filters: {
                loyaltyCards: [],
                occupancies: [],
                tags: [],
                excludedTags: [],
                profileType: [],
                vipCodes: []
            }
        }))
    }

    selectType = (fieldValue, field) => {
        const { filters } = this.state;

        const filter = filters[field].find(ftr => ftr === fieldValue.value);
        if (filter) {
            const idx = filters[field].indexOf(filter);
            filters[field].splice(idx, 1);
        }
        else {
            filters[field].push(fieldValue.value);
        }
        this.setState({ filters });
    }

    handleSelect = (name, combo) => {
        this.setState(prevState => ({
            campaign: {
                ...prevState.campaign,
                [name]: combo ? combo.value : null
            }
        }));
    }

    handleSelectCampaignFrequency = (name, cb, combo) => {
        this.setState(prevState => ({
            campaign: {
                ...prevState.campaign,
                [name]: combo ? combo.value : null,
                frequencyAdjust: 0
            },
            filters: {
                ...prevState.filters,
                frequencyAdjustFinal: 1
            }
        }), cb);
    }

    handleSurveySelect = (name, combo) => {
        this.setState(prevState => ({
            campaign: {
                ...prevState.campaign,
                [name]: combo ? combo.value : null,
                surveyId: null,
                propertyId: null
            }
        }));
    }

    handleChangeMultiSelect = (name, combo) => {
        var targetValue = null;

        if (Array.isArray(combo)) {
            targetValue = combo.map(el => el.value);
        }

        this.setState({
            campaign: {
                ...this.state.campaign,
                [name]: targetValue
            }
        });
    };

    handleChangeMultiSelectFilters = (name, combo) => {
        var targetValue = null;

        if (Array.isArray(combo)) {
            targetValue = combo.map(el => el.value);
        }

        this.setState({
            filters: {
                ...this.state.filters,
                [name]: targetValue
            }
        });
    };

    handleArrays = (name, value, isToDelete) => {
        let array = this.state.campaign[name] ? this.state.campaign[name] : [];

        if (isToDelete) {
            array = array.filter(a => a !== value);
        }
        else {
            array.push(value);
        }

        this.setState(prevState => ({
            campaign: {
                ...prevState.campaign,
                [name]: array
            }
        }))
    }

    isDisabled = () => {
        let isDisabled = true;
        const { campaign, invalidCampaignName } = this.state;

        if (this.state.steps[0].active === true && campaign.name && campaign.marketingType && campaign.senderChannel &&
            campaign.senderId && campaign.startDate && campaign.endDate && moment(campaign.endDate).isAfter(campaign.startDate) && !invalidCampaignName) {
            isDisabled = false;
        }

        if (this.state.steps[1].active === true && campaign.templates && campaign.templates.some(t => t.isDefault === true)) {
            if (campaign.frequency !== null && campaign.frequency !== undefined) {
                if (campaign.marketingType === 'CampaignInquiry') {
                    if ((campaign.surveyProvider === "ReviewPro" && campaign.surveyId && campaign.propertyId) || campaign.surveyId) {
                        isDisabled = false;
                    }
                }
                else if (this.state.campaign.marketingType !== 'Birthday' && this.state.campaign.frequencyAdjust) {
                    isDisabled = false;
                }
                else {
                    isDisabled = false;
                }
            }
            else if (this.state.campaign.marketingType === 'LinkedCampaign' && this.state.campaign.frequencyAdjust) {
                isDisabled = false;
            }
        }

        if (this.state.steps[2].active === true) {
            if (campaign.bookingsFromDate || campaign.bookingsToDate || campaign.staysFromDate || campaign.staysToDate) {
                if (campaign.inclusiveDates !== null && campaign.inclusiveDates !== undefined) isDisabled = false;
            }
            else {
                isDisabled = false;
            }
        }

        return isDisabled
    }

    handleStatusSelect = (name, combo) => {
        this.setState(prevState => ({
            campaign: {
                ...prevState.campaign,
                [name]: combo && combo.value
            }
        }))
    }

    handleDateChange = (name, date) => {
        this.setState(prevState => ({
            campaign: {
                ...prevState.campaign,
                [name]: date ? moment(date).format("YYYY-MM-DD") : null
            }
        }));
    }

    handleMultiDates = (date, name1, name2) => {
        const date2 = this.state.campaign[name2] ? moment(this.state.campaign[name2]).format("YYYY-MM-DD") : null;

        this.setState(prevState => ({
            campaign: {
                ...prevState.campaign,
                [name1]: date ? moment(date).format("YYYY-MM-DD") : null,
                [name2]: date && date2 && moment(date.format("YYYY-MM-DD")).isBefore(date2) ? date2 : null
            }
        }));
    }

    //#region linked campaigns config functions

    handleLinkCampCriteria = (combo) => {
        this.setState({
            filters: {
                ...this.state.filters,
                linkCampaignCriteria: combo ? combo.value : null,
                formId: null,
                listId: null
            }
        });
    }

    cleanFormData = () => {
        this.setState({
            filters: {
                ...this.state.filters,
                formId: null,
                listId: null
            }
        });
    }

    handleLinkMarketing = (campaignId) => {
        this.setState({
            filters: {
                ...this.state.filters,
                linkMarketingSendId: campaignId,
                linkCampaignCriteria: null,
                linkCampaignUrls: null,
                formId: null,
                listId: null
            },
            bookingsFromDate: null,
            bookingsToDate: null,
            staysFromDate: null,
            staysToDate: null
        });
    }

    handleSelectFilters = (name, combo) => {
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: combo ? combo.value : null
            }
        });
    };

    //#endregion

    handleChangeFiltersArray = (name, combo) => {
        const { filters } = this.state;
        filters[name] = combo ? combo.map(cmb => cmb.value) : [];
        this.setState({ filters });
    }

    isDisabledSelectHotelIds = () => {
        const { campaign } = this.state;

        return campaign.bookingsFromDate == null && campaign.bookingsToDate == null && campaign.staysFromDate == null && campaign.staysToDate == null;
    }

    handleTag = (tag) => {
        let { tags } = this.state.filters;
        const index = tags && tags.indexOf(tags.find(el => el.id === tag.id));

        if (index === -1) {
            tags.push({ id: tag.id, min: 1 });
        }
        else {
            tags = tags.filter(el => el.id !== tag.id);
        }
        this.setState({ filters: { ...this.state.filters, tags } });
    }

    handleExcludedTag = (tag) => {
        let { excludedTags } = this.state.filters;
        const index = excludedTags && excludedTags.indexOf(excludedTags.find(el => el.id === tag.id));

        if (index === -1) {
            excludedTags.push({ id: tag.id });
        }
        else {
            excludedTags = excludedTags.filter(el => el.id !== tag.id);
        }
        this.setState({ filters: { ...this.state.filters, excludedTags } });
    }

    changeTagMin = (e, tag) => {
        if (e.target && parseInt(e.target.value) > -1) {
            let { tags } = this.state.filters;
            const index = tags && tags.findIndex(el => el.id === tag.id);

            if (index > -1) {
                tags[index].min = e.target.value;
            }

            this.setState({ filters: { ...this.state.filters, tags } });
        }
    }

    handleOtaEmail = (hasOtaEmail) => {
        const { filters } = this.state;
        filters.hasOtaEmail = hasOtaEmail;
        this.setState({ filters });
    }

    handleCustomInput = (evt) => {
        if (evt && evt.target) {
            const { filters } = this.state;
            const { name, checked } = evt.target;
            filters[name] = checked;

            this.setState({ filters });
        }
    }

    handleChangeOnInputFilter = (e) => {
        if (e) {
            const { name, value } = e.target;

            this.setState(prevState => ({
                filters: {
                    ...prevState.filters,
                    [name]: value
                }
            }));
        }
    }

    handleChangeOnInputFilterWithCallback = (e, cb) => {
        if (e) {
            const { name, value } = e.target;

            this.setState(prevState => ({
                filters: {
                    ...prevState.filters,
                    [name]: value
                }
            }), cb);
        }
    }

    handleVoucherChange = (voucher) => {
        if (voucher) {
            this.setState(prevState => ({
                filters: {
                    ...prevState.filters,
                    voucherTypeId: voucher.value,
                    voucherEndDate: voucher.voucherEndDate,
                    voucherAmount: voucher.voucherAmount,
                    voucherIssueCode: null
                }
            }));
        }
        else {
            this.setState(prevState => ({
                filters: {
                    ...prevState.filters,
                    voucherTypeId: null,
                    voucherEndDate: null,
                    voucherAmount: null,
                    voucherIssueCode: null
                }
            }));
        }
    }

    handleVoucherEndDate = (endDate) => {
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                voucherEndDate: endDate
            }
        }));
    }

    validateCampaignName = (evt) => {
        evt.preventDefault();
        const { campaign } = this.state;
        const regex = /^[A-Za-z\d]{1,}$/i;

        if (campaign.campaignName && (!regex.test(campaign.campaignName))) {
            this.setState({ invalidCampaignName: true });
        }
        else {
            this.setState({ invalidCampaignName: false });
        }
    }

    render() {
        const { block, error, campaign, filters, steps, hasEgoi, hasSendGrid, hasTwillio, alreadySentAndExists, collectMetricsForGoogleAnalytics, invalidCampaignName } = this.state;

        const marketingType = getMarketingType();
        const frequency = getFrequency().filter(el => el.value !== 'Custom');

        return (
            <BlankCard block={block} error={error}>
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/Campaigns"><FormattedMessage id="NavMenu.campaigns"/></Link></BreadcrumbItem>
                        <BreadcrumbItem active><FormattedMessage id="CampaignDetails.CampaignDetails" /></BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Row className="mb-2">
                    <Col className="col-1 text-left d-flex align-items-center">
                        {steps[0].active === false ?
                            <Button className="btn- btn-host btn-small mr-4" style={{ 'flex': '1' }} onClick={this.movePos.bind(this, -1)}>
                                <i className="fas fa-angle-left" />
                            </Button>
                        : ''}
                    </Col>

                    <Col className="py-1 mb-4">
                        <ul className="steps">
                            <li className={'step ' + (steps[0].active === true ? ' step-active' : '')} style={{ cursor: 'pointer' }}>
                                <div className="step-content">
                                    <span className="step-text small">
                                        <FormattedMessage id="CampaignDetails.Step1" />
                                    </span>
                                    <span className="step-circle" onClick={() => this.nextStep(1)} />
                                </div>
                            </li>
                            <li className={'step ' + (steps[1].active === true ? ' step-active' : '')} style={{ cursor: 'pointer' }}>
                                <div className="step-content">
                                    <span className="step-text small">
                                        <FormattedMessage id="CampaignDetails.Step2" />
                                    </span>
                                    <span className="step-circle" onClick={() => this.nextStep(2)} />
                                </div>
                            </li>
                            <li className={'step ' + (steps[2].active === true ? ' step-active' : '')} style={{ cursor: 'pointer' }}>
                                <div className="step-content">
                                    <span className="step-text small">
                                        <FormattedMessage id="CampaignDetails.Step3" />
                                    </span>
                                    <span className="step-circle" onClick={() => this.nextStep(3)} />
                                </div>
                            </li>
                        </ul>
                    </Col>

                    <Col className="col-1 text-right d-flex align-items-center">
                        {steps[2].active === true ?
                            <Button className="btn- btn-host btn-small ml-4" style={{ 'flex': '1' }} onClick={(e) => this.saveCampaign(e)} disabled={this.isDisabled()}>
                                <i className="fas fa-save" />
                            </Button>
                            :
                            <Button className="btn- btn-host btn-small ml-4" style={{ 'flex': '1' }} onClick={this.movePos.bind(this, 1)} disabled={this.isDisabled()}>
                                <i className="fas fa-angle-right" />
                            </Button>
                        }
                    </Col>
                </Row>

                {steps[0].active === true ?
                    <CampaignStep1
                        marketingType={marketingType}
                        handleChange={this.handleChange}
                        handleStatusSelect={this.handleStatusSelect}
                        handleDateChange={this.handleDateChange}
                        handleMarketingType={this.handleMarketingType}
                        campaign={campaign}
                        hasEgoi={hasEgoi}
                        hasSendGrid={hasSendGrid}
                        hasTwillio={hasTwillio}
                        filters={filters}
                        handleVoucherChange={this.handleVoucherChange}
                        handleChangeOnInputFilter={this.handleChangeOnInputFilter}
                        handleVoucherEndDate={this.handleVoucherEndDate}
                        alreadySentAndExists={alreadySentAndExists}
                        collectMetricsForGoogleAnalytics={collectMetricsForGoogleAnalytics}
                        invalidCampaignName={invalidCampaignName}
                        handleSwitch={this.handleSwitch}
                        validateCampaignName={this.validateCampaignName}
                        handleWeekDays={this.handleWeekDays}
                    />
                    : ''}

                {steps[1].active === true ?
                    <CampaignStep2
                        frequency={frequency}
                        handleSelect={this.handleSelect}
                        handleSelectCampaignFrequency={this.handleSelectCampaignFrequency}
                        handleSurveySelect={this.handleSurveySelect}
                        handleChange={this.handleChange}
                        handleChangeWithCallback={this.handleChangeWithCallback}
                        campaign={campaign}
                        hasReviewProModule={global.modules.some(mdl => "ReviewPro" === mdl)}
                        hasProfileNowModule={global.modules.some(mdl => "ProfileNowForms" === mdl)}
                        hasTypeFormModule={global.modules.some(mdl => "Typeform" === mdl)}
                        filters={filters}
                        handleCustomInput={this.handleCustomInput}
                        handleChangeOnInputFilter={this.handleChangeOnInputFilter}
                        handleChangeOnInputFilterWithCallback={this.handleChangeOnInputFilterWithCallback}
                        hasEgoi={hasEgoi}
                        hasSendGrid={hasSendGrid}
                        hasTwillio={hasTwillio}
                    />
                    : ''}

                {steps[2].active === true ?
                    campaign.marketingType === 'LinkedCampaign' ?
                        <CampaignStep3Linked
                            campaign={campaign}
                            filters={filters}
                            handleLinkCampCriteria={this.handleLinkCampCriteria}
                            cleanFormData={this.cleanFormData}
                            handleChange={this.handleChange}
                            handleMultiDates={this.handleMultiDates}
                            handleDateChange={this.handleDateChange}
                            handleLinkMarketing={this.handleLinkMarketing}
                            handleSelectFilters={this.handleSelectFilters}
                            handleMultiSelect={this.handleChangeMultiSelectFilters}
                        />
                    :
                        <CampaignStep3
                            handleArrays={this.handleArrays}
                            campaign={campaign}
                            filters={filters}
                            handleChange={this.handleChange}
                            handleLoyaltyCard={this.handleLoyaltyCard}
                            handleDateChange={this.handleDateChange}
                            handleMultiDates={this.handleMultiDates}
                            handleChangeMultiSelect={this.handleChangeMultiSelect}
                            handleChangeFiltersArray={this.handleChangeFiltersArray}
                            isDisabledSelectHotelIds={this.isDisabledSelectHotelIds}
                            handleTag={this.handleTag}
                            handleExcludedTag={this.handleExcludedTag}
                            handleOtaEmail={this.handleOtaEmail}
                            selectType={this.selectType}
                            changeTagMin={this.changeTagMin}
                            handleCustomInput={this.handleCustomInput}
                            handleVipCodes={this.handleVipCodes}
                        />
                : ''}
            </BlankCard>
        );
    }
}
export default injectIntl(CampaignDetails)