import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormatAmountNumber, KebabMenu } from '../Base/CommonUIComponents';
import { Row, Col, PopoverBody } from 'reactstrap';
import CustomToolTip from '../Base/CustomToolTip';
import moment from 'moment';


class SalesDealProposalResume extends Component {

    render() {
        const { salesDealProposals } = this.props;

        return (
            <>
                <div className='d-flex align-items-center justify-content-between px-3'>
                    <h5 className='m-0'><FormattedMessage id="SalesDeal.ProposalResume" /></h5>
                </div>
                <div className='pt-4 px-3'>
                    {salesDealProposals.map((proposal, key) => {
                        const hotel = global.hotelList?.find(({ value }) => value === proposal.hotelId);
                        const rooms = proposal.proposalJSON?.groupedCategories;
                        const events = proposal.proposalJSON?.data && proposal.proposalJSON.data[0]?.DetailsEvents;

                        return (
                            <div key={key} className='shadow w-100 mb-3 bg-white p-3'>
                                <div className='d-flex justify-content-between'>
                                    <div style={{ maxWidth: '80%' }}>
                                        <div className='cursor-pointer text-left' style={{ width: 'fit-content', position: 'relative' }} onClick={_ => this.props.setFilters(proposal.externalId, proposal.externalId, "Reservation")}>
                                            <b>{proposal.externalId}</b>
                                            {this.props.hasFilter(proposal.externalId, "Reservation") ?
                                                <FilterDot />
                                                : ''}
                                        </div>
                                        <div className='cursor-pointer' style={{ width: 'fit-content', position: 'relative', fontSize: '0.8em' }} onClick={_ => this.props.setFilters(proposal.hotelId, hotel?.label, "Hotel")}>
                                            <div className='text-truncate text-muted'>
                                                {hotel?.label}
                                            </div>
                                            {this.props.hasFilter(proposal.hotelId, "Hotel") ?
                                                <FilterDot />
                                                : ''}
                                        </div>
                                    </div>
                                    <KebabMenu
                                        direction={'left'}
                                        extraFields={[{
                                            function: _ => this.props.importGroupRes(proposal.externalId, proposal.hotelId, proposal.id),
                                            icon: 'fas fa-sync-alt mr-2',
                                            text: 'SalesDeal.UpdateReservation'
                                        }]}
                                        deleteFunction={_ => this.props.removeGroupReservation(proposal.id)}
                                        deleteText={"SalesDeal.Remove"}
                                    />
                                </div>
                                <Row className='text-truncate title-sm mt-2 mx-1'>
                                    <Col className="text-left pl-0">
                                        <span id={`check-in-${key}`} >
                                            <i className="far fa-calendar-alt color-green mr-1" /> {proposal.proposalJSON.data[0]?.CheckIn ? moment(proposal.proposalJSON.data[0].CheckIn).format("YYYY-MM-DD") : '-'}
                                        </span>
                                        <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="right" target={`check-in-${key}`}>
                                            <PopoverBody> Check-in </PopoverBody>
                                        </CustomToolTip>
                                    </Col>
                                    <Col className="text-right pr-0">
                                        <span id={`check-out-${key}`} >
                                            <i className="far fa-calendar-alt color-red mr-1" />{proposal.proposalJSON.data[0]?.CheckOut ? moment(proposal.proposalJSON.data[0].CheckOut).format("YYYY-MM-DD") : '-'}
                                        </span>
                                        <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="left" target={`check-out-${key}`}>
                                            <PopoverBody> Check-out </PopoverBody>
                                        </CustomToolTip>
                                    </Col>
                                </Row>
                                <div className='px-2 mt-3'>
                                    {proposal.numberOfRooms ?
                                        <div className='pb-2'>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div className='cursor-pointer' style={{ width: 'fit-content', position: 'relative' }} onClick={_ => this.props.setFilters(null, null, "Rooms", proposal.externalId)}>
                                                    <FormattedMessage id="SalesDeal.Rooms" />
                                                    {this.props.hasFilter(null, "Rooms", proposal.externalId) ?
                                                        <FilterDot />
                                                        : ''}
                                                </div>
                                                <div>
                                                    <FormatAmountNumber currency={global.hotelCurrency} value={proposal.roomsValue ?? 0} />
                                                </div>
                                            </div>
                                            <div className='px-2 mt-2' style={{ borderTop: '1px solid lightgrey', fontSize: '0.9em' }}>
                                                {rooms?.map((room, key) =>
                                                    <React.Fragment key={key}>
                                                        {key ?
                                                            <hr className='my-1' />
                                                            : ''}
                                                        <div className='d-flex align-items-center justify-content-between mt-2'>
                                                            <div className='d-flex cursor-pointer' style={{ width: 'fit-content', position: 'relative', maxWidth: '80%' }} onClick={_ => this.props.setFilters(room.categoryId, room.categoryCode, "Rooms", proposal.externalId)}>
                                                                {room.categoryCode}
                                                                <div style={{ fontSize: '0.9em' }} className='ml-1 text-muted'>
                                                                    {room.totalRooms}
                                                                </div>
                                                                {this.props.hasFilter(room.categoryId, "Rooms", proposal.externalId) ?
                                                                    <FilterDot />
                                                                    : ''}
                                                            </div>
                                                            <div>
                                                                <FormatAmountNumber currency={global.hotelCurrency} value={room.priceTotal ?? 0} />
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                        : ''}
                                    {events?.length > 0 ? //proposal.numberOfSpaces ?
                                        <div className='pb-2 mt-3'>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div className='cursor-pointer' style={{ width: 'fit-content', position: 'relative' }} onClick={_ => this.props.setFilters(null, null, "Events", proposal.externalId)}>
                                                    <FormattedMessage id="SalesDeal.Events" />
                                                    {this.props.hasFilter(null, "Events", proposal.externalId) ?
                                                        <FilterDot />
                                                        : ''}
                                                </div>
                                                <div>
                                                    <FormatAmountNumber currency={global.hotelCurrency} value={proposal.PriceTotalEvents ?? 0} />
                                                </div>
                                            </div>
                                            <div className='px-2' style={{ borderTop: '1px solid lightgrey', fontSize: '0.9em' }}>
                                                {events.map((event, key) =>
                                                    <React.Fragment key={key}>
                                                        {key ?
                                                            <hr className='my-1' />
                                                            : ''}
                                                        <div className='d-flex align-items-center justify-content-between mt-2'>
                                                            <div className='d-flex cursor-pointer' style={{ width: 'fit-content', position: 'relative', maxWidth: '80%' }} onClick={_ => this.props.setFilters(event.EventName, event.EventName, "Events", proposal.externalId)}>
                                                                <div className='text-truncate'>{event.EventName}</div>
                                                                <div style={{ fontSize: '0.9em' }} className='ml-1 text-muted'>
                                                                    {event.Spaces.length}
                                                                </div>
                                                                {this.props.hasFilter(event.EventName, "Events", proposal.externalId) ?
                                                                    <FilterDot />
                                                                    : ''}
                                                            </div>
                                                            <div>
                                                                <FormatAmountNumber currency={global.hotelCurrency} value={event.PriceTotal ?? 0} />
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                        : ''}

                                    {salesDealProposals.length > 1 ?
                                        <div className='d-flex align-items-center justify-content-between mt-2 pt-2 pb-2' style={{ borderTop: '1px solid lightgrey' }}>
                                            <div>
                                                <FormattedMessage id="SalesDeal.Total" />
                                            </div>
                                            <div>
                                                <FormatAmountNumber
                                                    currency={global.hotelCurrency}
                                                    value={proposal.totalValue ?? 0}
                                                />
                                            </div>
                                        </div>
                                        : ''}
                                </div>
                            </div>
                        )
                    }
                    )}
                </div>
            </>
        );
    }
}

export default SalesDealProposalResume;



const FilterDot = () => {
    return <div style={{
        width: '5px',
        height: '5px',
        background: '#0665ff',
        borderRadius: '100%',
        position: 'absolute',
        top: '0',
        right: '-7px'
    }} />
}