import React, { Component } from 'react';
import { FormGroup, Label, Input, Col, Card, CardBody, Table, Row, CardHeader, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { getAPI, postAPI, deleteAPI } from "../Base/API";
import { FormattedMessage, injectIntl } from 'react-intl';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../Base/ErrorAlert";
import { CustomTable } from '../Base/CustomTable';
import { handleNotification } from "../Base/Notification";
import { ActiveInactiveStatusBadge, CommonHelper, StyledCard } from "../Base/CommonUIComponents";


class ManageUser extends Component {

    constructor(props) {
        super(props);

        this.form = React.createRef();
        this.toggleModal = this.toggleModal.bind(this);

        this.state = {
            hotelId: this.props.hotelId,
            block: true,
            blockUserDetails: false,
            blockProperty: false,
            modal: false,
            ComplexData: null,
            user: {},
            selectedUser: {},
            userDetails: {}
        };
    }

    componentDidMount() {
        this.searchUser();
        this.setState({ blockProperty: true });
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ user: data, property: data.defaultProperty, blockProperty: false });
            }
        }, '/api/User/v1/User');
    }

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    searchUser() {
        this.setState({ block: true })

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ listUsers: data.users, property: data.defaultProperty, userDetails: {}, block: false });
            }
        }, '/api/User/v1/User/getUsers');
    }

    getAccessGroups() {
        return [
            { value: 'a4140047-d6fd-4479-979b-80861dc0a954', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.a4140047-d6fd-4479-979b-80861dc0a954' }) }, //Administrator
            { value: '1f8c0154-62b3-404f-8880-63797a0ee01d', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.1f8c0154-62b3-404f-8880-63797a0ee01d' }) }, //Manager
            { value: '5f65f469-6340-4bf4-8c50-1c37158cc41a', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.5f65f469-6340-4bf4-8c50-1c37158cc41a' }) }, //GMS Reception Power User
            { value: '2b21bd6f-f770-4c19-9f63-6a79cb86a341', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.2b21bd6f-f770-4c19-9f63-6a79cb86a341' }) }, //GMS Reception User
            { value: '2663d8ef-d855-4b65-a263-3390fbce0e35', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.2663d8ef-d855-4b65-a263-3390fbce0e35' }) }, //Marketing Automation Management
            { value: 'e02b5dfc-ee17-4dbd-aeba-699ab14ad35c', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.e02b5dfc-ee17-4dbd-aeba-699ab14ad35c' }) }, //Sales Process   
            { value: 'da02ccea-a45a-4803-b607-fa04418656d0', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.da02ccea-a45a-4803-b607-fa04418656d0' }) }, //View Only             
        ]
    }

    getUserDetails() {
        this.setState({ blockUserDetails: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ userDetails: data.user ? data.user : {}, blockUserDetails: false });
            }
        }, '/api/User/v1/User/getUser/' + this.state.selectedUser.adId);
    }

    reinviteUser() {

        let body = {
            "adId": this.state.selectedUser.adId,
            "name": this.state.selectedUser.name,
            "surname": this.state.selectedUser.surname,
            "mobile": this.state.selectedUser.mobile,
            "email": this.state.selectedUser.email
        }

        this.setState({ blockUserDetails: true });
        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, 'Notification Sent', 'Success');
                }
                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/reInviteUser?company=GMS', body);

    }

    disableUser() {

        this.setState({ blockUserDetails: true });
        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, 'User Disabled', 'Success');
                }
                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/disableUser/' + this.state.selectedUser.adId);

    }

    enableUser() {
        this.setState({ blockUserDetails: true });

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, 'User Enabled', 'Success');
                }
                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/enableUser/' + this.state.selectedUser.adId);

    }

    deleteUser() {
        this.setState({ blockUserDetails: true });

        deleteAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, 'User deleted', 'Success');
                }
                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/deleteUser/' + this.state.selectedUser.adId);

    }

    createUser(event) {
        event.preventDefault();
        if (!this.form.current.reportValidity()) {
            return;
        }

        this.setState({ blockUserDetails: true });

        let req = this.state.userDetails;

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, 'User Created', 'Success');
                }
                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/addUser?company=GMS', req);
    }

    saveUser(event) {
        event.preventDefault();
        if (!this.form.current.reportValidity()) {
            return;
        }

        this.setState({ blockUserDetails: true });

        let req = this.state.userDetails;

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, 'User Updated', 'Success');
                }
                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/updateUser', req);
    }

    updateProperties() {
        this.setState({ blockUserDetails: true });

        let req = this.state.userDetails.allowedProperties
        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, 'properties updated', 'Success');
                }
                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/' + this.state.selectedUser.adId + '/updateAllowedProperties', req);

    }

    updateGroups() {
        this.setState({ blockUserDetails: true });

        let req = this.state.userDetails.groups
        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, 'properties updated', 'Success');
                }
                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/' + this.state.selectedUser.adId + '/updateGroups', req);

    }

    manageList(el, type, event) {
        if (this.state.userDetails[type] === undefined)
            return;

        let newgroup = this.state.userDetails[type].indexOf(el) >= 0 ? this.state.userDetails[type].filter(ar => ar !== el) : [...this.state.userDetails[type], el]

        this.setState(prevState => ({
            ...prevState,
            userDetails: {
                ...prevState.userDetails,
                [type]: newgroup
            }
        }));

    }

    changeUserState(event) {

        let name = event.target.name;
        let value = event.target.value;

        this.setState(prevState => ({
            ...prevState,
            userDetails: {
                ...prevState.userDetails,
                [name]: value
            }
        }));
    }

    manageGroups = (el) => {
        if (this.state.userDetails.groups === undefined)
            return;

        let newgroup = this.state.userDetails.groups.filter(g => !this.getAccessGroups().some(ag => ag.value === g));

        newgroup.push(el);

        this.setState(prevState => ({
            ...prevState,
            userDetails: {
                ...prevState.userDetails,
                groups: newgroup
            }
        }));
    }

    render() {
        const columns = [
            {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "ManageUser.name" }),
                sort: true
            },
            {
                dataField: 'surname',
                text: this.props.intl.formatMessage({ id: "ManageUser.surname" }),
                sort: true
            },
            {
                dataField: 'email',
                text: this.props.intl.formatMessage({ id: "ManageUser.email" }),
                sort: true
            },
            {
                dataField: 'mobile',
                text: this.props.intl.formatMessage({ id: "ManageUser.mobile" }),
                sort: true
            },
            {
                dataField: 'accountEnabled',
                text: this.props.intl.formatMessage({ id: "ManageUser.accountStatus" }),
                sort: true,
                formatter: (cell, row) => <ActiveInactiveStatusBadge status={cell} />
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({ selectedUser: row, blockUserDetails: true }, () => this.getUserDetails())
            }
        };

        return (
            <StyledCard block={this.state.block} error={this.state.error} >
                <Row>
                    <Col>
                        <h5> <i className="fas fa-user mr-2 ml-3 mb-3" /><FormattedMessage id="ManageUser.Title" /> </h5>
                    </Col>
                </Row>

                <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-lg " >
                    <ModalHeader toggle={this.toggleModal}  > <FormattedMessage id="ConfigChannelAutoActions.Channel" />
                        Rule For channel code {this.state.modalData ? this.state.modalData.channelConfigId : ''}
                    </ModalHeader>
                    <ModalBody className="modal-lg px-0 pb-0">
                    </ModalBody>
                </Modal>

                <form ref={this.form}>
                    <div>
                        <Row>
                            <Col className="Col-6">
                                <BlockUi tag="div" blocking={this.state.block}>
                                    <Card className="mb-2 border-0 shadow">
                                        <CardHeader className="border-bottom bg-white">
                                            <Row className="align-items-center">
                                                <Col>
                                                    <h6 className="m-0">
                                                        <span className="fas fa-users mr-2" />
                                                        <FormattedMessage id="ManageUser.users" />
                                                    </h6>
                                                </Col>
                                                <Col>
                                                    <Button className="btn btn-host btn-sm float-right mx-1" onClick={this.searchUser.bind(this)}>
                                                        <i className="fas fa-sync-alt" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <CustomTable
                                                data={this.state.listUsers ? this.state.listUsers : []}
                                                columns={columns}
                                                page={this.state.currentPage}
                                                totalSize={this.state.listUsers && this.state.listUsers.length}
                                                onTableChange={this.handleTableChange}
                                                shadow={false}
                                                exportCSV={false}
                                                selectRow={selectRow}
                                                search={true}
                                                remote={false}
                                            />
                                        </CardBody>
                                    </Card>
                                </BlockUi>
                                <BlockUi tag="div" blocking={this.state.blockUserDetails}>
                                    <Card className="mb-2 border-0 shadow small">
                                        <CardHeader className="border-bottom bg-white">
                                            <Row className="align-items-center">
                                                <Col>
                                                    <h6 className="m-0">
                                                        <span className="fas fa-id-card mr-2" />
                                                        <FormattedMessage id="ManageUser.selectedUser" />
                                                    </h6>
                                                </Col>
                                                <Col>
                                                    <CommonHelper help={<FormattedMessage id="ManageUser.Help" />} id={'manageruserhelp'} />

                                                    {
                                                        this.state.userDetails.id === undefined ?
                                                            <Button className="btn btn-host btn-sm float-right" disabled={this.state.userDetails.id === null} onClick={this.createUser.bind(this)} title={this.props.intl.formatMessage({ id: "ManageUser.CreateUser" })}>
                                                                <i className="fas fa-plus"></i>
                                                            </Button>
                                                            :
                                                            <div>
                                                                <Button className="btn btn-host btn-sm float-right mx-1" disabled={this.state.userDetails.id === undefined} onClick={this.deleteUser.bind(this)} title={this.props.intl.formatMessage({ id: "ManageUser.DeleteUser" })}>
                                                                    <i className="fas fa-user-times" />
                                                                </Button>
                                                                {
                                                                    this.state.userDetails.accountEnabled ?
                                                                        <Button className="btn btn-host btn-sm float-right mx-1" disabled={Object.keys(this.state.userDetails).length === 0} onClick={this.disableUser.bind(this)} title={this.props.intl.formatMessage({ id: "ManageUser.DisableUser" })} >
                                                                            <i className="fas fa-user-slash"></i>
                                                                        </Button>
                                                                        :
                                                                        <Button className="btn btn-host btn-sm float-right mx-1" disabled={Object.keys(this.state.userDetails).length === 0} onClick={this.enableUser.bind(this)} title={this.props.intl.formatMessage({ id: "ManageUser.EnableUser" })}>
                                                                            <i className="fas fa-user-check" />
                                                                        </Button>
                                                                }
                                                                <Button className="btn btn-host btn-sm float-right mx-1" disabled={Object.keys(this.state.userDetails).length === 0} onClick={this.saveUser.bind(this)} title={this.props.intl.formatMessage({ id: "ManageUser.Save" })}>
                                                                    <i className="fas fa-save" />
                                                                </Button>
                                                            </div>
                                                    }
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col className="col-6">
                                                    <FormGroup row size="sm small">
                                                        <Label for="User" className="font-weight-bold" sm={3}> <FormattedMessage id="ManageUser.user" /> </Label>
                                                        <Col sm={9}>
                                                            <Input type="text" bsSize="sm" readOnly name="userPrinciplaName" value={this.state.userDetails.userPrinciplaName ? this.state.userDetails.userPrinciplaName : ''} id="userPrinciplaName" />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col className="col-6">
                                                    <FormGroup row size="sm">
                                                        <Label for="Email" className="font-weight-bold" sm={3}> <FormattedMessage id="ManageUser.email" /> </Label>
                                                        <Col sm={9}>
                                                            <Input type="email" required email="true" bsSize="sm" name="email" value={this.state.userDetails.email ? this.state.userDetails.email : ''} onChange={this.changeUserState.bind(this)} id="email" />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-6">
                                                    <FormGroup row size="sm">
                                                        <Label for="FirstName" className="font-weight-bold" sm={3}> <FormattedMessage id="ManageUser.name" /> </Label>
                                                        <Col sm={9}>
                                                            <Input type="text" bsSize="sm" required name="name" value={this.state.userDetails.name ? this.state.userDetails.name : ''} onChange={this.changeUserState.bind(this)} id="name" />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col className="col-6">
                                                    <FormGroup row size="sm">
                                                        <Label for="FamilyName" className="font-weight-bold" sm={3}> <FormattedMessage id="ManageUser.surname" /> </Label>
                                                        <Col sm={9}>
                                                            <Input type="text" bsSize="sm" required name="surname" value={this.state.userDetails.surname ? this.state.userDetails.surname : ''} onChange={this.changeUserState.bind(this)} id="surname" />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FormGroup row size="sm">
                                                        <Label for="mobile" className="font-weight-bold" sm={3}> <FormattedMessage id="ManageUser.mobile" /> </Label>
                                                        <Col sm={9}>
                                                            <Input type="text" bsSize="sm" required name="mobile" value={this.state.userDetails.mobile ? this.state.userDetails.mobile : ''} onChange={this.changeUserState.bind(this)} id="mobile" />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>

                                                <Col>
                                                    <FormGroup row size="sm">
                                                        <Label for="stat" className="font-weight-bold" sm={3}> Inv. Status </Label>
                                                        <Col sm={this.state.userDetails.status && this.state.userDetails.status === 'PendingAcceptance' ? 7 : 9}>

                                                            <Input type="text" bsSize="sm" name="stat" defaultValue={this.state.userDetails.status ? this.state.userDetails.status : ''} disabled id="mobile" />
                                                        </Col>
                                                        {
                                                            this.state.userDetails.status && this.state.userDetails.status === 'PendingAcceptance' ?
                                                                <Col>
                                                                    <Button className="btn btn-sm btn-host float-right" onClick={this.reinviteUser.bind(this)}>
                                                                        <i className="far fa-share-square" />
                                                                    </Button>
                                                                </Col>
                                                                :
                                                                ''
                                                        }
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </BlockUi>
                            </Col>
                            <Col>
                                <Row>
                                    <Col className="pr-1">
                                        <BlockUi tag="div" blocking={this.state.blockUserDetails || this.state.blockProperty}>
                                            <Card className="mb-1 h-100  border-0 shadow">
                                                <CardHeader className="border-bottom bg-white">
                                                    <Row className="align-items-center">
                                                        <Col className="align-items-center"> 
                                                            <h6 className="m-0">
                                                                <span className="icon-icon-hotel mr-2"></span> <FormattedMessage id="Profile.Property" />
                                                            </h6>
                                                        </Col>
                                                        <Col>
                                                            <Button className="btn btn-host btn-sm float-right mx-1" disabled={this.state.userDetails.id === undefined} onClick={this.updateProperties.bind(this)}>
                                                                <i className="fas fa-save" />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </CardHeader>
                                                <CardBody>
                                                    <Table className={"table-sm table-borderless" + (this.state.userDetails.id === undefined ? '  text-secondary' : '')}>
                                                        <tbody>
                                                            {
                                                                this.state.user.userAllowedProperties && this.state.user.userAllowedProperties.map((item, key) =>
                                                                    <tr key={key} className={(this.state.userDetails && this.state.userDetails.allowedProperties && this.state.userDetails.allowedProperties.filter(el => el === item.property).length > 0 ? 'bg-secondary text-white' : '') + 'cursor-pointer'} >
                                                                        <th scope="row"> {key}</th>
                                                                        <td onClick={this.manageList.bind(this, item.property, 'allowedProperties')}>{item.propertyName}</td>
                                                                    </tr>
                                                                )}
                                                        </tbody>
                                                    </Table>
                                                </CardBody>
                                            </Card>
                                        </BlockUi>
                                    </Col>
                                    <Col className="pl-1">
                                        <BlockUi tag="div" blocking={this.state.blockUserDetails}>
                                            <Card className="mb-1 h-100  bg-white border-bottom shadow">
                                                <CardHeader className="border-bottom bg-white">
                                                    <Row className="align-items-center">
                                                        <Col>
                                                            <h6 className="m-0">
                                                                <span className="fas fa-users mr-2" />
                                                                <FormattedMessage id="Profile.AccessGroups" />
                                                            </h6>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <Button className="btn btn-host btn-sm float-right mx-1" disabled={this.state.userDetails.id === undefined} onClick={this.updateGroups.bind(this)} >
                                                                <i className="fas fa-save" />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </CardHeader>
                                                <CardBody>
                                                    <Table className={"table-sm table-borderless" + (this.state.userDetails.id === undefined ? '  text-secondary' : '')}>
                                                        <tbody>
                                                            {
                                                                this.getAccessGroups().map((item, key) =>
                                                                    <tr key={key} className="cursor-pointer">
                                                                        <th scope="row"> {key}</th>
                                                                        <td className={this.state.userDetails && this.state.userDetails.groups && this.state.userDetails.groups.filter(el => el === item.value).length > 0 ? 'bg-secondary text-white' : ''} onClick={() => this.manageGroups(item.value)} >
                                                                            {item.label}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </CardBody>
                                            </Card>
                                        </BlockUi>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </form>
            </StyledCard>
        );
    }
};

export default injectIntl(ManageUser)