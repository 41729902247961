import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, Col, Row } from 'reactstrap';
import CustomSelect from '../Base/CustomSelect';
import moment from 'moment-timezone';
import { FormatAmountNumber } from '../Base/CommonUIComponents';
import { getAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import { ErrorAlert } from '../Base/ErrorAlert';

class SalesProcessExecutiveReportMonthly extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            block: true,
            monthlyStats: null,
            hotelReport: null,
            metrics: [
                {
                    name: "numberOfSpaces",
                    type: "number"
                },
                {
                    name: "numberOfLodging",
                    type: "number"
                },
                {
                    name: "adr",
                    type: "value"
                },
                {
                    name: "lodging",
                    type: "value"
                },
                {
                    name: "fb",
                    type: "value"
                },
                {
                    name: "spaceRental",
                    type: "value"
                },
                {
                    name: "misc",
                    type: "value"
                },
                {
                    name: "totalValue",
                    type: "value"
                }
            ]
        };
    }

    componentDidMount(){
        this.getHotelMonthlyMetrics();
    }

    getHotelMonthlyMetrics = () => {
        const { selectedHotel, year } = this.props;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if(data.data?.length){
                    const monthlyStats = data.data.map((m) => {
                        const lodging = m.hotels[0]?.stagesTypes.reduce((c, d) => c + d.sourceTypes.reduce((e, f) => e + f.revenueMetrics.lodging, 0), 0)??0;
                        const numberOfLodging = m.hotels[0]?.stagesTypes.reduce((c, d) => c + d.sourceTypes.reduce((e, f) => e + f.revenueMetrics.numberOfLodging, 0), 0)??0;

                        const adr = lodging && numberOfLodging ? (Math.round((lodging / numberOfLodging) * 100) / 100) : null;
                        return ({
                            ...m,
                            ...m.deals,
                            adr,
                            lodging,
                            numberOfLodging,
                            fb: m.hotels[0]?.stagesTypes.reduce((c, d) => c + d.sourceTypes.reduce((e, f) => e + f.revenueMetrics.fb, 0), 0)??0,
                            misc: m.hotels[0]?.stagesTypes.reduce((c, d) => c + d.sourceTypes.reduce((e, f) => e + f.revenueMetrics.misc, 0), 0)??0,
                            spaceRental: m.hotels[0]?.stagesTypes.reduce((c, d) => c + d.sourceTypes.reduce((e, f) => e + f.revenueMetrics.spaceRental, 0), 0)??0,
                            totalValue: m.hotels[0]?.stagesTypes.reduce((c, d) => c + d.sourceTypes.reduce((e, f) => e + f.revenueMetrics.totalValue, 0), 0)??0,
                            numberOfSpaces: m.hotels[0]?.stagesTypes.reduce((c, d) => c + d.sourceTypes.reduce((e, f) => e + f.revenueMetrics.numberOfSpaces, 0), 0)??0
                        })
                    });

                    const hotelName = global.hotelList.find(({value}) => value === selectedHotel)?.label;

                    const qData = {
                        q1Months: monthlyStats
                            .filter(m => 
                                parseInt(moment(m.startDate).format('MM')) <= 3
                            ),
                        q2Months: monthlyStats
                            .filter(m =>
                                (parseInt(moment(m.startDate).format('MM')) > 3) && (parseInt(moment(m.startDate).format('MM')) <= 6)
                            ),
                        q3Months: monthlyStats
                            .filter(m =>
                                (parseInt(moment(m.startDate).format('MM')) > 6) && (parseInt(moment(m.startDate).format('MM')) <= 9)
                            ),
                        q4Months: monthlyStats
                            .filter(m =>
                                parseInt(moment(m.startDate).format('MM')) > 9
                            ),
                    };

                    const quarterData = Object.keys(qData).map((k, idx) => {
                        const quarter = qData[k];

                        const numberOfLodging = quarter.reduce((a, b) => a + b.numberOfLodging, 0);
                        const lodging = quarter.reduce((a, b) => a + b.lodging, 0);
                        const adr = lodging && numberOfLodging ? (Math.round((lodging / numberOfLodging) * 100) / 100) : null;
                        const fb = quarter.reduce((a, b) => a + b.fb, 0);
                        const spaceRental = quarter.reduce((a, b) => a + b.spaceRental, 0);
                        const misc = quarter.reduce((a, b) => a + b.misc, 0);
                        const numberOfSpaces = quarter.reduce((a, b) => a + b.numberOfSpaces, 0);
                        const totalValue = quarter.reduce((a, b) => a + b.totalValue, 0);
                        
                        return(
                            {
                                idx: idx + 1,
                                monthlyData: quarter,
                                quarterData: {
                                    numberOfLodging,
                                    lodging,
                                    adr,
                                    fb,
                                    spaceRental,
                                    misc,
                                    numberOfSpaces,
                                    totalValue
                                }
                            }
                        );
                    });

                    const lodging = monthlyStats.reduce((a, b) =>  a + b.lodging, 0);
                    const numberOfLodging = monthlyStats.reduce((a, b) =>  a + b.numberOfLodging, 0);
                    const adr = lodging && numberOfLodging ? (Math.round((lodging / numberOfLodging) * 100) / 100) : null;
                    const hotelReport = {
                        adr,
                        lodging,
                        numberOfLodging,
                        numberOfDeals: monthlyStats.reduce((a, b) =>  a + b.deals.numberOfDeals, 0),
                        numberOfWonDeals: monthlyStats.reduce((a, b) =>  a + b.deals.numberOfWonDeals, 0),
                        numberOfLostDeals: monthlyStats.reduce((a, b) =>  a + b.deals.numberOfLostDeals, 0),
                        proposalValueTotal: monthlyStats.reduce((a, b) =>  a + b.deals.proposalValueTotal, 0),
                        proposalValueWon: monthlyStats.reduce((a, b) =>  a + b.deals.proposalValueWon, 0),
                        proposalValueLost: monthlyStats.reduce((a, b) =>  a + b.deals.proposalValueLost, 0),
                        fb: monthlyStats.reduce((a, b) =>  a + b.fb, 0),
                        misc: monthlyStats.reduce((a, b) =>  a + b.misc, 0),
                        spaceRental: monthlyStats.reduce((a, b) =>  a + b.spaceRental, 0),
                        totalValue: monthlyStats.reduce((a, b) =>  a + b.totalValue, 0),
                        numberOfSpaces: monthlyStats.reduce((a, b) =>  a + b.numberOfSpaces, 0),
                        
                    };

                    this.setState({ block: false, quarterData, hotelName, hotelReport });
                    return;
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/executivereport/monthly?year=${year}&hotelId=${selectedHotel}`);
    }

    handleYearChange = (combo) => {
        this.props.handleChangeYear(combo, () => {this.setState({ block: true }, () => this.getHotelMonthlyMetrics())});
    }

    downloadData = () => {

    }

    render() {
        const { error, block, quarterData, hotelName, hotelReport, metrics } = this.state;
        const { toggleTab, intl, year, yearOptions, selectedHotel } = this.props;

        return (
            <div className="border-0 h-100 pb-2">
                <Card className='border-0 shadow p-3 bg-white' style={{ minHeight: '90vh' }}>
                    <ErrorAlert error={error}/>
                    <BlockUi tag="div" blocking={block}>
                        <div>
                            <Row className='d-flex align-items-center justify-content-between'>
                                <Col className='d-flex align-items-center justify-content-start col-9'>
                                    <div onClick={_ => toggleTab("SalesProcessExecutiveReportYearly")} className='p-3 cursor-pointer'>
                                        <i className='fas fa-chevron-left'/>
                                    </div>
                                    <div className='ml-2'>
                                        <div>
                                            <h5 className='m-0'>
                                                <b className='mr-2'>{hotelName}</b>
                                                <FormattedMessage id="SalesProcess.Report"/>
                                            </h5>
                                        </div>
                                    </div>
                                </Col>
                                <Col className='col-3 d-flex align-items-center justify-content-end'>
                                    <div style={{ minWidth: '150px' }}>
                                        <CustomSelect
                                            options={yearOptions}
                                            value={yearOptions.find(({value}) => value === year)}
                                            isSearchable={true}
                                            onChange={this.handleYearChange}
                                            placeholder={intl.formatMessage({ id: "SalesProcess.Year" })}
                                        />
                                    </div>
                                    <div className='ml-4'>
                                        <Button className="btn btn-host btn-sm" onClick={this.downloadData}>
                                            <i className="fas fa-file-download"/>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <hr className='mt-2 mb-4'/>
                            <div>
                                <div className='pt-1'>
                                    <h5 className='m-0'>
                                        <i className="mr-2 fas fa-calendar-alt"/>
                                        <FormattedMessage id="SalesProcess.YearlyStats"/>
                                    </h5>
                                </div>
                                <Row className='mt-4'>
                                    <Col className='col-12'>
                                        <Row style={{ fontSize: '0.9em' }}>
                                            <Col className='col-3'>
                                                <Card className='shadow bg-white border-0 p-3 pointer' style={{ borderRadius: '5px' }} onClick={_ => toggleTab("SalesDealList", selectedHotel, null, null, true)}>
                                                    <div className='text-center text-muted' style={{ fontSize: '1.2em' }}>
                                                        <i className={`fas fa-briefcase mr-2`}/>
                                                        <FormattedMessage id='SalesProcess.TotalDeals'/>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-between mt-2' style={{ fontSize: '1.4em' }}>
                                                        <div>
                                                            {hotelReport?.numberOfDeals??'0'}
                                                        </div>
                                                        <div>
                                                            <FormatAmountNumber
                                                                value={hotelReport?.proposalValueTotal??'0'}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col className='col-3'>
                                                <Card className='shadow bg-white border-0 p-3 pointer' style={{ borderRadius: '5px', color: '#0665ff' }} onClick={_ => toggleTab("SalesDealList", selectedHotel, null, null, true, null, ["WaitingHotel", "WaitingClient"])}>
                                                    <div className='text-center text-muted' style={{ fontSize: '1.2em' }}>
                                                        <i className={`fas fa-people-arrows mr-2`}/>
                                                        <FormattedMessage id='SalesProcess.NegotiationDeals'/>
                                                    </div>
                                                    {(() => {
                                                        const number = (hotelReport?.numberOfDeals??0) - ((hotelReport?.numberOfLostDeals??0) + (hotelReport?.numberOfWonDeals??0));

                                                        const lost = hotelReport?.proposalValueLost??0;
                                                        const won = hotelReport?.proposalValueWon??0;
                                                        const total = hotelReport?.proposalValueTotal??0;
                                                        const wonAndLost = won + lost;
                                                        const value = total - wonAndLost;

                                                        return (
                                                            <div className='d-flex align-items-center justify-content-between mt-2' style={{ fontSize: '1.4em' }}>
                                                                <div>
                                                                    {number}
                                                                </div>
                                                                <div>
                                                                    <FormatAmountNumber
                                                                        value={value}
                                                                    />
                                                                </div>
                                                            </div>
                                                        );
                                                    })()}
                                                </Card>
                                            </Col>
                                            <Col className='col-3'>
                                                <Card className='shadow bg-white border-0 p-3 pointer' style={{ borderRadius: '5px', color: '#F38375' }} onClick={_ => toggleTab("SalesDealList", selectedHotel, null, null, true, null, "Cancelled")}>
                                                    <div className='text-center text-muted' style={{ fontSize: '1.2em' }}>
                                                        <i className={`far fa-thumbs-down mr-2`}/>
                                                        <FormattedMessage id='SalesProcess.LostDeals'/>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-between mt-2' style={{ fontSize: '1.4em' }}>
                                                        <div>
                                                            {hotelReport?.numberOfLostDeals??'0'}
                                                        </div>
                                                        <div>
                                                            <FormatAmountNumber
                                                                value={hotelReport?.proposalValueLost??'0'}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col className='col-3'>
                                                <Card className='shadow bg-white border-0 p-3 pointer' style={{ borderRadius: '5px', color: '#36ad51' }} onClick={_ => toggleTab("SalesDealList", selectedHotel, null, null, true, null, "Finished")}>
                                                    <div className='text-center text-muted' style={{ fontSize: '1.2em' }}>
                                                        <i className={`fas fa-trophy mr-2`}/>
                                                        <FormattedMessage id='SalesProcess.WonDeals'/>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-between mt-2' style={{ fontSize: '1.4em' }}>
                                                        <div>
                                                            {hotelReport?.numberOfWonDeals??'0'}
                                                        </div>
                                                        <div>
                                                            <FormatAmountNumber
                                                                value={hotelReport?.proposalValueWon??'0'}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {hotelReport ?
                                        <Col className='col-12 mt-3' style={{ fontSize: '0.9em' }}>
                                            <Card className='shadow bg-white border-0 p-3 h-100' style={{ borderRadius: '5px' }}>
                                                <div className='overflow-auto scrollableDiv'>
                                                    <div className='d-flex align-items-center justify-content-between px-3 py-1' style={{ borderRadius: '4px' }}>
                                                        <div style={{ minWidth: '150px' }}>
                                                            <b>
                                                                <FormattedMessage id="SalesProcess.Metrics"/>
                                                            </b>
                                                        </div>
                                                        <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                            <div className='text-truncate'>
                                                                <b><FormattedMessage id="SalesProcess.PreviousYear"/></b>
                                                            </div>
                                                        </div>
                                                        <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                            <div className='text-truncate'>
                                                                <b><FormattedMessage id="SalesProcess.Budget"/></b>
                                                            </div>
                                                        </div>
                                                        <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                            <div className='text-truncate'>
                                                                <b><FormattedMessage id="SalesProcess.Actual"/></b>
                                                            </div>
                                                        </div>
                                                        <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                            <div className='text-truncate'>
                                                                <b><FormattedMessage id="SalesProcess.Diff"/></b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='mt-2'>
                                                        {metrics.map((metric, k) =>
                                                            <div key={k} style={{ borderTop: k ? '1px solid lightgrey' : '', background: (k + 1) === metrics.length ? '#e9e9e9' : k === 2 ? 'rgb(248, 248, 248)' :  '' }} className='py-2 px-3 d-flex align-items-center justify-content-between'>
                                                                <div className='text-truncate d-flex align-items-center' style={{ minWidth: '150px' }}>
                                                                    <div className='text-truncate text-center'>
                                                                        <FormattedMessage id={`SalesProcess.${metric.name}`}/>
                                                                    </div>
                                                                </div>
                                                                <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                                    <div className='text-truncate'>
                                                                    {metric.type === "value" ?
                                                                        '-'
                                                                    : metric.type === "percentage" ?
                                                                        '-'
                                                                    :
                                                                        '-'
                                                                    }
                                                                    </div>
                                                                </div>
                                                                <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                                    <div className='text-truncate'>
                                                                        {metric.type === "value" ?
                                                                            '-'
                                                                        : metric.type === "percentage" ?
                                                                            '-'
                                                                        :
                                                                            '-'
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                                    <div className='text-truncate'>
                                                                        {metric.name === "totalValue" ?
                                                                            <span className='cursor-pointer hrefStyle' onClick={_ => toggleTab("SalesDealList", selectedHotel, null, null, true)}>
                                                                                {metric.type === "value" ?
                                                                                    <FormatAmountNumber value={hotelReport[metric.name]} />
                                                                                    : metric.type === "percentage" ?
                                                                                        `${hotelReport[metric.name]}%`
                                                                                        :
                                                                                        hotelReport[metric.name]
                                                                                }
                                                                            </span>
                                                                        :
                                                                            metric.type === "value" ?
                                                                                <FormatAmountNumber value={hotelReport[metric.name]}/>
                                                                            : metric.type === "percentage" ?
                                                                                `${hotelReport[metric.name]}%`
                                                                            :
                                                                                hotelReport[metric.name]
                                                                            }
                                                                    </div>
                                                                </div>
                                                                <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                                    <div className='text-truncate'>
                                                                        {metric.type === "value" ?
                                                                            '-'
                                                                        : metric.type === "percentage" ?
                                                                            '-'
                                                                        :
                                                                            '-'
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                    :''}
                                </Row>
                            </div>
                            <div className='mt-4'>
                                <div className='pt-1'>
                                    <h5 className='m-0'>
                                        <i className="mr-2 fas fa-calendar-week"/>
                                        <FormattedMessage id="SalesProcess.QuarterlyStats"/>
                                    </h5>
                                </div>
                                <Row className='mt-2'>
                                    {quarterData ?
                                        quarterData.map((q, k) =>
                                            <Col key={k} className='col-12 mt-3' style={{ fontSize: '0.9em' }}>
                                                <Card className='shadow bg-white border-0 p-3 h-100' style={{ borderRadius: '5px' }}>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div className='text-muted'>
                                                            <FormattedMessage id="SalesProcess.Quarter"/>
                                                            <span className='ml-1'>{q.idx}</span>
                                                        </div>
                                                    </div>
                                                    <div className='overflow-auto mt-2 scrollableDiv'>
                                                        <div className='d-flex align-items-center justify-content-between px-3 py-1' style={{ borderRadius: '4px' }}>
                                                            <div style={{ minWidth: '150px' }}>
                                                                <b>
                                                                    <FormattedMessage id="SalesProcess.Metrics"/>
                                                                </b>
                                                            </div>
                                                            {q.monthlyData.map((m, k) =>
                                                                <div style={{ borderLeft: '1px solid #a5a5a5' }} key={k} className='w-100 text-center'>
                                                                    <div className='w-100'>
                                                                        <b>{moment(m.startDate).format('MMMM')}</b>
                                                                    </div>
                                                                    <div className='mt-1 w-100 d-flex align-items-center justify-content-between text-muted' style={{ gap: '5%' }}>
                                                                        <div className='w-100 text-center'>
                                                                            <div className='text-truncate'>
                                                                                <FormattedMessage id="SalesProcess.PreviousYear"/>
                                                                            </div>
                                                                        </div>
                                                                        <div className='w-100 text-center'>
                                                                            <div className='text-truncate'>
                                                                                <FormattedMessage id="SalesProcess.Budget"/>
                                                                            </div>
                                                                        </div>
                                                                        <div className='w-100 text-center'>
                                                                            <div className='text-truncate'>
                                                                                <FormattedMessage id="SalesProcess.Actual"/>
                                                                            </div>
                                                                        </div>
                                                                        <div className='w-100 text-center'>
                                                                            <div className='text-truncate'>
                                                                                <FormattedMessage id="SalesProcess.Diff"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                                <div className='w-100'>
                                                                    <b><FormattedMessage id="SalesProcess.Total"/></b>
                                                                </div>
                                                                <div className='mt-1 w-100 d-flex align-items-center justify-content-between text-muted' style={{ gap: '5%' }}>
                                                                    <div className='w-100'>
                                                                        <div className='text-truncate'>
                                                                            <FormattedMessage id="SalesProcess.PreviousYear"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className='w-100'>
                                                                        <div className='text-truncate'>
                                                                            <FormattedMessage id="SalesProcess.Budget"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className='w-100'>
                                                                        <div className='text-truncate'>
                                                                            <FormattedMessage id="SalesProcess.Actual"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className='w-100'>
                                                                        <div className='text-truncate'>
                                                                            <FormattedMessage id="SalesProcess.Diff"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='mt-2'>
                                                            {metrics.map((metric, k) =>
                                                                <div key={k} style={{ borderTop: k ? '1px solid lightgrey' : '', background: (k + 1) === metrics.length ? '#e9e9e9' : k === 2 ? 'rgb(248, 248, 248)' : '' }} className='py-2 px-3 d-flex align-items-center justify-content-between'>
                                                                    <div className='text-truncate d-flex align-items-center' style={{ minWidth: '150px' }}>
                                                                        <div className='text-truncate text-center'>
                                                                            <FormattedMessage id={`SalesProcess.${metric.name}`}/>
                                                                        </div>
                                                                    </div>
                                                                    {q.monthlyData.map((m, k) =>
                                                                        <div key={k} className='w-100 d-flex align-items-center justify-content-between' style={{ gap: '5%', borderLeft: '1px solid #a5a5a5' }}>
                                                                            <div className='w-100 text-center'>
                                                                                <div className='text-truncate'>
                                                                                    -
                                                                                </div>
                                                                            </div>
                                                                            <div className='w-100 text-center'>
                                                                                <div className='text-truncate'>
                                                                                    -
                                                                                </div>
                                                                            </div>
                                                                            <div className='w-100 text-center'>
                                                                                <div className='text-truncate'>
                                                                                    {metric.name === "numberOfLodging" || metric.name === "numberOfSpaces" ?
                                                                                        <span className='cursor-pointer hrefStyle' onClick={_ => toggleTab("SalesProcessResources", selectedHotel,  null, moment(m.startDate).format('MM'), true, metric.name === "numberOfSpaces")}>
                                                                                            {metric.type === "value" ?
                                                                                                <FormatAmountNumber value={m[metric.name]??0}/>
                                                                                            : metric.type === "percentage" ?
                                                                                                `${m[metric.name]??0}%`
                                                                                            :
                                                                                                m[metric.name]??0
                                                                                            }
                                                                                        </span>
                                                                                    : metric.name === "totalValue" ?
                                                                                            <span className='cursor-pointer hrefStyle' onClick={_ => toggleTab("SalesDealList", selectedHotel, null, moment(m.startDate).format('MM'), true)}>
                                                                                                {metric.type === "value" ?
                                                                                                    <FormatAmountNumber value={m[metric.name] ?? 0} />
                                                                                                    : metric.type === "percentage" ?
                                                                                                        `${m[metric.name] ?? 0}%`
                                                                                                        :
                                                                                                        m[metric.name] ?? 0
                                                                                                }
                                                                                            </span>
                                                                                    :
                                                                                        metric.type === "value" ?
                                                                                            <FormatAmountNumber value={m[metric.name]??0}/>
                                                                                        : metric.type === "percentage" ?
                                                                                            `${m[metric.name]??0}%`
                                                                                        :
                                                                                            m[metric.name]??0
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className='w-100 text-center'>
                                                                                <div className='text-truncate'>
                                                                                    -
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    <div key={k} className='w-100 d-flex align-items-center justify-content-between' style={{ gap: '5%', borderLeft: '1px solid #a5a5a5' }}>
                                                                        <div className='w-100 text-center'>
                                                                            <div className='text-truncate'>
                                                                                -
                                                                            </div>
                                                                        </div>
                                                                        <div className='w-100 text-center'>
                                                                            <div className='text-truncate'>
                                                                                -
                                                                            </div>
                                                                        </div>
                                                                        <div className='w-100 text-center'>
                                                                            <div className='text-truncate'>
                                                                                {metric.name === "totalValue" ?
                                                                                    <span className='cursor-pointer hrefStyle' onClick={_ => toggleTab("SalesDealList", selectedHotel, null, moment(q.monthlyData[0].startDate).format('MM'), true, null, null, true)}>
                                                                                        {metric.type === "value" ?
                                                                                        <FormatAmountNumber value={q.quarterData[metric.name] ?? 0} />
                                                                                        : metric.type === "percentage" ?
                                                                                        `${q.quarterData[metric.name] ?? 0}%`
                                                                                        :
                                                                                        q.quarterData[metric.name] ?? 0
                                                                                        }
                                                                                    </span>
                                                                                    : metric.type === "value" ?
                                                                                        <FormatAmountNumber value={q.quarterData[metric.name] ?? 0} />
                                                                                        : metric.type === "percentage" ?
                                                                                            `${q.quarterData[metric.name] ?? 0}%`
                                                                                            :
                                                                                            q.quarterData[metric.name] ?? 0
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className='w-100 text-center'>
                                                                            <div className='text-truncate'>
                                                                                -
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>
                                        )
                                    :''}
                                </Row>
                            </div>
                        </div>
                    </BlockUi>
                </Card>
            </div>
        );
    }
}

export default injectIntl(SalesProcessExecutiveReportMonthly);
